@import  '../css/mixin';
@import "../css/variables";
@import "./reset";


.site-footer{
    padding:0  0 20px;
    text-align: center;
    .copyright-text {
        @include font(14);
        font-weight: 400;
        color:#33364D;
        margin-bottom: 0;
    }
}
@media screen and (max-width:1199px) {
    .site-footer {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}