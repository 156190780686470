// Varaibles
$baseColor: #C0321F;
$black: #3E3E3E;
$white: #ffffff;
$green: #1CB79E;
$border:#ddd;
$blue:#2177D6;
$lightgray:#E9E9E9;
$gray:#C7C7C7;
$blackColor:#000;
$lightBlue:#00B9FF;
$bgLightGray : #F5F5F5;
$yellow:#FDB944;
$orange:#FF6624;
$blackBg:#191919;
$border:#C7C7C7;
$text:#3E3E3E;

// Easing
$ease-time: 200ms;
$easing: ease-in-out;