// Varaibles
$baseColor: #c0321f;
$black: #3e3e3e;
$white: #ffffff;
$green: #1cb79e;
$border: #ddd;
$violet: #766cc1;
$lightGray: #ccc;
$blackColor: #000;
$redColor: #c0321f;
$gray: #ccc;
$lightBlue: #00b9ff;
$bgLightGray: #f5f5f5;
$yellow: #fdb944;

// Varaibles

$blue: #2177d6;
$lightgray: #e9e9e9;

$blackColor: #000;

$blackBg: #191919;
$border: #cccccc;
$text: #3e3e3e;
$green: #1CB79E;
$border:#ddd;
$violet :#766CC1;
$lightGray : #ccc;
$blackColor:#000;
$redColor:#C0321F;
$gray:#ccc;
$lightBlue:#00B9FF;
$bgLightGray : #F5F5F5;
$yellow:#FDB944;
$blue:#2177D6;
$lightgray:#E9E9E9;
$gray:#ccc;
$blackBg:#191919;
$border:#CCCCCC;
$text:#3E3E3E;
$orange:#FF6624;