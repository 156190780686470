// @import "../../../assets/css/mixin";
@import "./mixin";
@import "./reset";
@import "./variables";

footer {
    .contactSection {
        background-color: #343434;
        padding: 15px 0;

        .logoSection {
            text-align: center;
            padding-bottom: 30px;
            padding-top: 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }

        .flexBox {
            @include flex();
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .social,
            .contact {
                ul {
                    margin: 0;
                    padding: 0;
                    @include flex();
                    align-items: center;

                    li {
                        list-style: none;
                        margin-right: 15px;
                        color: rgba(255, 255, 255, 0.5);
                        @include font(14);
                        margin-top: 15px;
                        margin-bottom: 15px;

                        span {
                            display: inline-block;
                        }

                        a {
                            display: inline-flex;
                            text-decoration: none;
                            cursor: pointer;

                            &:hover {
                                svg {
                                    fill: rgba(255, 255, 255, 1);

                                    .a {
                                        fill: rgba(255, 255, 255, 1);
                                    }
                                }
                            }

                            svg {
                                fill: rgba(255, 255, 255, 0.5);

                                .a {
                                    fill: rgba(255, 255, 255, 0.5);
                                }
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        &.phone {
                            span {
                                @include font(30);
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }

    .comingSoonSection {
        background: $bgLightGray;
        padding-top: 25px;

        .flexBox {
            @include flex();
            align-items: flex-end;
            flex-wrap: wrap;

            .infoFlexBox {
                @include flex();
                align-items: flex-end;
                justify-content: space-between;
                width: calc(100% - 275px);
                margin-left: auto;
                padding-left: 30px;

                .info {
                    padding-bottom: 20px;

                    h4 {
                        @include font(23);
                        color: $black;
                        font-weight: bold;
                        margin: 0 0 10px 0;
                    }

                    h5 {
                        @include font(18);
                        color: $black;
                        font-weight: 500;
                        margin: 0 0 10px 0;
                    }

                    p {
                        @include font(14);
                        color: $black;
                        font-weight: normal;
                        margin: 0;
                    }
                }

                .action {
                    margin-left: auto;

                    a {
                        display: inline-block;
                        margin-right: 15px;
                        text-decoration: none;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .footerMenuSection {
        background: #343434;
        padding-top: 40px;

        .flexBox {
            @include flex();
            flex-wrap: wrap;
            padding: 0 15px;
            margin: 0 -15px;

            .menu {
                width: calc(25% - 10px);
                padding: 30px 15px;

                .heading {
                    @include font(16);
                    color: $lightBlue;
                    font-weight: normal;
                    margin: 0 0 10px 0;
                }

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        list-style: none;
                        margin: 0 0 10px 0;

                        a {
                            display: inline-block;
                            text-decoration: none;
                            position: relative;
                            padding: 10px 0;
                            @include font(14);
                            color: $white;
                            transition: all 0.5s ease-in-out;

                            &::after {
                                content: "";
                                display: inline-block;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 1px;
                                height: 1px;
                                background-color: $white;
                                transition: all 0.5s ease-in-out;
                                opacity: 0;
                            }

                            &:hover {
                                &::after {
                                    width: 100%;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footerBottom {
        padding: 25px 0;
        background: $bgLightGray;

        .heading {
            @include font(16);
            color: $black;
            font-weight: normal;
            text-align: center;
            margin: 0 0 20px 0;
        }

        .flexBox {
            border-bottom: 1px solid $gray;

            ul {
                @include flex();
                flex-wrap: wrap;
                justify-content: center;

                li {
                    list-style: none;
                    margin-right: 30px;
                    margin-bottom: 20px;

                    span {
                        display: inline-block;

                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }

                    svg {

                        .a,
                        .b,
                        .c {
                            fill: #345475;
                        }

                        .c {
                            font-family: Poppins-Bold, Poppins;
                            @include font(18);
                            font-weight: 700;
                            letter-spacing: 0.015em;
                        }

                        .d,
                        .e {
                            stroke: none;
                        }

                        .e {
                            fill: $bgLightGray;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .contactEmailFlexBox {
            margin: 20px 0 10px 0;

            ul {
                @include flex();
                justify-content: center;
                flex-wrap: wrap;
                padding: 0;
                margin: 0;

                li {
                    list-style: none;
                    @include flex();
                    margin-bottom: 10px;

                    span {
                        display: inline-block;
                        @include font(14);
                        color: $black;

                        &.label {
                            white-space: nowrap;
                            margin-right: 5px;
                        }

                        a {
                            display: inline-block;
                            text-decoration: none;
                            color: $black;

                            &:hover {
                                color: $baseColor;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .rightsTerms {
            ul {
                @include flex();
                justify-content: center;
                flex-wrap: wrap;
                padding: 0;
                margin: 0;

                li {
                    list-style: none;
                    @include font(14);
                    color: $black;
                    border-right: 1px solid $black;
                    padding-right: 15px;
                    padding-left: 15px;
                    margin-bottom: 15px;

                    a {
                        display: inline-block;
                        text-decoration: none;
                        color: $black;

                        &:hover {
                            color: $baseColor;
                            text-decoration: underline;
                        }
                    }

                    &:nth-child(1) {
                        padding-left: 0;
                    }
                }
            }
        }

        .phoneBlock {
            text-align: center;

            span {
                display: inline-block;
                @include font(16);
                color: $black;
                line-height: 22px;

                .phone {
                    display: inline-block;
                    color: $baseColor;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
        }
    }

    .chatIcon {
        position: fixed;
        //left: 10px;
        bottom: 10px;
        z-index: 2;

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            @include radius(50%);
            background-color: $lightBlue;

            svg {
                width: 30px;
                height: auto;
                fill: $white;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    footer {
        .comingSoonSection {
            .flexBox {
                .infoFlexBox {
                    flex-wrap: wrap;

                    .action {
                        margin-left: 0;
                    }
                }
            }
        }

        .footerMenuSection {
            .flexBox {
                .menu {
                    width: calc(33.333% - 10px);

                    .heading {
                        @include font(15);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    footer {
        .contactSection {
            .flexBox {
                justify-content: center;
            }
        }

        .comingSoonSection {
            .flexBox {
                justify-content: center;

                .phoneImg {
                    order: 2;
                }

                .infoFlexBox {
                    width: 100%;
                    padding-left: 0;
                    margin-bottom: 20px;
                    order: 1;

                    .info {
                        width: 100%;
                        text-align: center;
                    }

                    .action {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

        .footerMenuSection {
            .flexBox {
                .menu {
                    width: calc(50% - 10px);
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    footer {
        .footerMenuSection {
            padding-top: 15px;
        }

        .contactSection {
            .flexBox {
                .contact {
                    ul {
                        li {
                            &.phone {
                                span {
                                    @include font(20);
                                }
                            }
                        }
                    }
                }
            }
        }

        .footerBottom {
            .contactEmailFlexBox {
                ul {
                    li {
                        span {
                            @include font(13);
                            line-height: 1.1;
                        }
                    }
                }
            }

            .rightsTerms {
                ul {
                    li {
                        @include font(13);
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 479px) {
    footer {
        .footerMenuSection {
            .flexBox {
                .menu {
                    width: 100%;
                    padding-top: 25px;
                    padding-left: 0;
                    padding-right: 0;
                    margin-right: 0;
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

                    ul {
                        li {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }

        .contactSection {
            .flexBox {
                .social {
                    ul {
                        flex-wrap: wrap;
                        justify-content: center;

                        li {
                            margin-top: 10px;
                            margin-bottom: 10px;

                            &:nth-child(1) {
                                width: 100%;
                                margin-right: 0;
                                text-align: center;
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .contact {
                    ul {
                        flex-wrap: wrap;
                        justify-content: center;

                        li {
                            margin-top: 10px;
                            margin-bottom: 10px;
                            text-align: center;

                            &:nth-child(1) {
                                width: 100%;
                                margin-right: 0;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .footerBottom {
            .flexBox {
                ul {
                    li {
                        margin-right: 15px;

                        span {
                            max-width: 130px;
                        }
                    }
                }
            }

            .phoneBlock {
                span {
                    max-width: 160px;
                }
            }
        }
    }
}