// Font Imported
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

// Variable
@import "./variables";

// Mixin
@import "./mixin";

// Reset
@import "./reset";

// Global themeing
html {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 16px;
}

body {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-family: 'Poppins', sans-serif;

  &.advisor-notification-page {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}

.transition {
  transition: (all 0.2s ease-in-out);
}

.flex {
  @include flex();
}

// Window Scrolbar
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: rgba($blue, 0.2%);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 10px;
  background: rgba($blue, 0.4%);
  cursor: pointer;
}

//   Button
.btn {
  @include font(16);
  background: $baseColor;
  color: $white;
  border: 0px;
  text-align: center;
  padding: 12px 20px;
  line-height: 1.5;
  @include radius(5px);
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  @include transition();

  &.medium {
    @include font(14);
    padding: .5em 1em;
  }

  &.blue {
    background: $blue;
    color: $white;
  }

  &.blue-light {
    background: rgba($lightBlue, .3%);
    color: $white;
  }

  &.green {
    background: $green;
    color: $white;
  }

  &.outlined {
    background: none;
    border: 1px solid transparent;
    color: $black;

    &.green {
      border-color: $green;
      color: $green;

      &:hover {
        background: $green;
        color: $white;
      }
    }

    &.blue {
      border-color: $blue;
      color: $blue;

      &:hover {
        background: $blue;
        color: $white;
      }
    }

    &.red {
      border-color: $baseColor;
      color: $baseColor;

      &:hover {
        background: $baseColor;
        color: $white;
      }
    }
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &:before {
    content: '';
    width: 200%;
    height: 200%;
    position: absolute;
    background: rgba($white, .3%);
    left: -50%;
    top: -50%;
    @include scaleX(0);
    @include transition();
  }

  &:focus,
  &:hover {
    outline: 0px !important;
    color: $white;
    box-shadow: none !important;

    &:before {
      animation: hoverEffect .75s ease-in-out forwards;
    }
  }
}

// FX Reveal Animation
[class*="fx-reveal"],
[class^="fx-reveal"] {
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 1.5s;
  transition-duration: 1.5s;
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;

  &.up {
    @include translateY(40px);
  }

  &.down {
    @include translateY(-40px);
  }

  &.animate {
    @include transform(translate(0px, 0px) scale(1));
    opacity: 1;
  }
}

.main {
  display: flex;
  height: 100vh;
  justify-content: flex-end;
  overflow: hidden;

  .pagetitle {
    #sidebarCollapse {
      background: #040404 !important;
      position: absolute;
      background: none;
      border-radius: 100%;
      cursor: pointer;
      z-index: 10;
      border: 0;
      padding: 0;
      margin: 0;
      left: 0;
      top: 50%;
      display: flex;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 20px rgba(#000, 0%);
      outline: none;

      &:hover {
        transform: translate(-50%, -50%) scaleX(1) scale(1.1);
        box-shadow: 0px 0px 20px rgba(#000, 5%);
      }
    }
  }

  .content {
    flex-grow: 1;
    padding-left: 300px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    width: 100%;
  }
}

.sidebar-hidden {
  .sidebar-admin {
    transform: translateX(calc(-100% + 30px));
  }

  .content {
    padding-left: 29px;
  }

  .pagetitle {
    #sidebarCollapse {
      transform: translate(-50%, -50%) scaleX(-1) scaleY(1);

      &:hover {
        transform: translate(-50%, -50%) scaleX(-1.1) scaleY(1.1);
      }
    }
  }
}

.modalBackdrop{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    z-index: 1000;

    &.backdropActive{
        display: block;
    }
}

.modal {
  .modal-dialog {
    width: 500px;
    &.modal-lg {
      width: 800px;
    }
  }
  .modal-content {
    border: none;
    box-shadow: 0px 3px 20px #00000080;
    @include radius(8px);

    .modal-header {
      padding: 10px 40px 10px 20px;
      border-bottom: 1px solid rgba($border, .4%);
      position: relative;

      &.border-none {
        border: none;
      }

      .modal-title {
        @include font(24);
        font-weight: 600;
        color: $blackColor;

        .name {
          display: block;
          @include font(16);
          font-weight: 500;
          color: $lightBlue;
        }

        &.claim-title {
          @include font(18);
          font-weight: 500;
        }
      }

      .close {
        font-weight: 300;
        color: $black;
        margin: 0;
        outline: none;
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    .modal-body {
      padding: 10px 20px;

      .form-group {
        margin-bottom: 15px;

        &.flexbox {
          @include flex();
          align-items: baseline;
          justify-content: space-between;

          .half {
            width: calc(50% - 10px);
            .form-group {
              margin-bottom: 0;
            }
          }
        }

        label {
          display: block;
          @include font(15);
          font-weight: 400;
          color: $black;
          margin-bottom: 10px;
          position: relative;

          &.name {
            @include font(16);
            font-weight: 500;
            color: $lightBlue;

            a {
              display: inline-block;
              vertical-align: middle;
              color: $lightBlue;
              text-decoration: underline
            }
          }

          sup {
            position: absolute;
            top: 8px;
            font-weight: 600;
            color: $baseColor;
            margin-left: 3px;
          }
        }

        input {
          @include font(14);
          font-weight: 400;
          color: $black;
          width: 100%;
          border: 1px solid $border;
          @include radius(4px);
          padding: 0 15px;
          height: 45px;
          box-shadow: none;

          +span {
            display: block;
            margin-top: 6px;
          }
        }

        select {
          padding: 13px 30px 13px 10px;
          background-image: url(../img/arrowdown.svg);
          background-repeat: no-repeat;
          background-position: bottom 17px right 12px;
          background-size: 11px;
          @include font(14);
          color: $black;
          width: 100%;
          height: 45px;
          border: 1px solid #E8E8E8;
          -webkit-appearance: none;
          appearance: none;
          @include radius(4px);

          +span {
            display: block;
            margin-top: 6px;
          }
        }

        .checkbox-group {
          padding-top: 15px;

          li {
            margin-bottom: 18px;
          }

          .checkbox {
            display: inline-block;
            vertical-align: middle;
            position: relative;

            input[type="checkbox"] {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              opacity: 0;
              z-index: 1;
              @include radius(4px);
              cursor: pointer;

              &:checked {
                +label {
                  &::before {
                    border-color: $lightBlue;
                  }

                  &::after {
                    content: "";
                    display: inline-block;
                    background: url(../../Img/checkBlue.png) no-repeat;
                    background-size: cover;
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    left: 3px;
                    top: 5px;
                  }
                }
              }
            }

            label {
              padding-left: 30px;
              position: relative;
              @include font(15);
              font-weight: 300;
              color: $black;
              line-height: 18px;
              cursor: pointer;
              margin-bottom: 0;

              &::before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 18px;
                height: 18px;
                left: 0;
                top: 0;
                border: 1px solid $border;
                @include radius(2px);
              }
            }
          }
        }

        .radioflexBox {
          @include flex();
          align-items: center;
          min-height: 45px;

          .option {
            @include flex();
            align-items: center;
            position: relative;
            margin-right: 20px;

            &:last-child {
              margin-right: 0;
            }

            input {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              opacity: 0;
              z-index: 1;
              cursor: pointer;

              &:checked {
                +label {
                  &::before {
                    border-color: $lightBlue;
                  }

                  &::after {
                    content: "";
                    background-color: $lightBlue;
                    display: inline-block;
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    left: 5px;
                    top: 5px;
                    @include radius(50%);
                  }
                }
              }
            }

            label {
              position: relative;
              @include font(14);
              color: $black;
              font-weight: normal;
              padding-left: 30px;
              cursor: pointer;
              margin-bottom: 0;

              &::before {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                left: 0;
                top: 0;
                border: 1px solid $black;
                @include radius(50%);
              }
            }
          }
        }

        .linkbox {
          position: relative;

          input {
            padding-right: 105px;
          }

          .copyBtn {
            background: $lightBlue;
            padding: 16px 10px;
            @include font(13);
            font-weight: 400;
            color: $white;
            min-width: 90px;
            border: none;
            @include radius(0 4px 4px 0);
            position: absolute;
            right: 0;
            top: 0;
            border: none;
            box-shadow: none;
            z-index: 1;
            cursor: pointer;
          }
        }

        p {
          &.text {
            @include font(14);
            font-weight: 400;
            color: $black;
            line-height: 26px;
          }
        }
        p {
          line-height:1.3;
        }
        .countryPhoneFlexBox {

          input,
          select {
            height: 30px !important;
          }

          .textBox {
            input {
              border: none
            }
          }
        }
      }

      .two-col-grid {
        @include flex();
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
        margin: 0 -10px;

        li {
          width: 50%;
          padding: 0 10px;
          @include font(15);
          font-weight: 400;
          color: $black;
          margin-top: 20px;

          h4 {
            color: rgba($black, .5);
            margin-bottom: 8px;
          }

          p {
            font-weight: 500;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .modal-footer {
    padding: 0 20px 20px;
    justify-content: center;

    button {
      min-width: 100px;
      @include font(15);
      font-weight: 300;
      margin: .25rem 0;
      border: 1px solid transparent;

      &.blue {
        background: $lightBlue;
      }

      &.cancel {
        margin-left: 15px;
      }

      &.save-invitebtn {
        background: $white;
        color: $black;
        border-color: $black;
        margin-left: 15px;
      }

    }
  }
  &.verifymodal {
    overflow-y: auto;
    .modal-footer {
      justify-content: left;
      border-top: none;
    }
  }

  &.inviteAthleteInfoPopup {
    .modal-content {
      .modal-header {
        justify-content: center;

        .modal-title {
          text-align: center;
        }
      }

      .modal-body {
        .form-group {
          line-height: 1.5;
        }
      }
    }
  }

  &.inviteAthleteInfoPopup {
    .modal-footer {
      border-top: none;
    }
  }
  &.unsaved-modal {
    .modal-dialog {
      max-width: 500px;
    }
    .modal-body {
      text-align: center;
    }
    .icon-box {
      margin-bottom: 15px;
      svg {
        display: inline-block;
        vertical-align: middle;
        opacity:.6;
      }
    }
    .content-box {
      @include font(16);
      font-weight: 400;
      color: $black;
      line-height: 30px;
      .title {
        @include font(16);
        font-weight: 500;
        color: $blackColor;
        letter-spacing: 0.24px;
        line-height: 24px;
        margin-bottom: 0;
        margin-top: 15px;
        + p {
          margin-bottom: 0;
        }
      }
    }
    .modal-footer {
      border-top: none;
      justify-content: center;
      padding: 0 20px 25px;
      .btn {
        @include font(14);
        font-weight: 400;
        padding: 10px 15px;
        min-width: 100px;
        letter-spacing: 0.2px;
        margin: 0 0.5rem;
        &.lightblue {
          background: $lightBlue;
          color:$white;
        }
        &.lightgray {
          background:rgba(#CCCCCC, .5);
          color:$blackColor;
        }
      }
    }
  }
  &.addplan-modal {
    .modal-content {
      .modal-body {
        .titlebox {
          @include flex();
          justify-content: space-between;
          align-items: center;
          .title {
            @include font(15);
            font-weight:500;
            color:$black;
            margin-bottom: 0;
          }
          .linkbtn {
            padding: 0;
            background:transparent;
            @include font(13);
            font-weight:400;
            color:$lightBlue;
            border: none;
            outline: none;
            text-decoration: underline;
            margin:0;
          }
        }
        .form-group {
          margin-bottom:0;
          .checkbox-group {
            margin: 0 -10px;
            .checkbox {
              width: calc(33.33% - 10px);
              padding-left: 10px;
              padding-right: 10px;
              margin-bottom:15px;
            }
          }
        }
      }
    }
  }
  &.verifiedModalCustomBackdrop{
    .modalBackdrop{
        background-color: transparent;
    }
    .modal-dialog{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1050;

        @media screen and (max-width: 650px){
            margin: 0 !important;
        }
    }
  }
}
.video-modal {
  .MuiDialog-paperWidthSm {
    width: 500px !important;
  }

  .modal-body {
    padding: 15px;

    &.youtube_embed_frame {

      iframe,
      video {
        display: block;
        max-width: 100% !important;
        width: 100% !important;
        height: 255px !important;
        object-fit: contain;
        overflow: hidden;
      }
    }
  }
}
.MuiDialog-paper {
  .modal {
    .modal-content {
      box-shadow: none !important;
    }
  }
}
@media screen and (max-width:1199px) {
  .main {
    .content {
      padding-left: 29px;
    }

    .pagetitle {
      #sidebarCollapse {
        transform: translate(-50%, -50%) scaleX(-1.1) scaleY(1.1);
        box-shadow: none !important;

        img {
          width: 26px;
        }
      }
    }

    &.sidebar-hidden {
      .pagetitle {
        #sidebarCollapse {
          transform: translate(-50%, -50%) scaleX(1) scaleY(1);
        }
      }
    }
  }

  .sidebar-admin {
    transform: translateX(calc(-100% + 30px));
  }

  .sidebar-hidden {
    .sidebar-admin {
      transform: none;
    }

    .pagetitle {
      #sidebarCollapse {
        left: 270px;
      }
    }
  }
}
@media screen and (max-width:991px) {
  .modal {
    .modal-dialog {
      &.modal-lg {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width:639px) {
  .modal {
    .modal-dialog {
      max-width: 100% !important;
      width: 100%;
      .modal-lg {
        width: 100%;
      }
    }
    .modal-content {
      .modal-header {
        .modal-title {
          @include font(18);
        }
      }

      .modal-body {
        .form-group {
          &.flexbox {
            flex-direction: column;
            .half {
              width: 100%;
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .radioflexBox {
            .option {
              margin-right: 10px;

              label {
                padding-left: 25px;
              }
            }
          }
        }

        .two-col-grid {
          margin: 0 -8px;
          li {
            padding: 0 8px;
          }
        }
      }
    }
  }
  .MuiDialog-paper {
    margin-left: 15px !important;
    margin-right: 15px !important;
    width: 100%!important;
  }
  .video-modal {
    .modal-body {
      &.youtube_embed_frame {
        iframe,
        video {
          height: auto !important;
        }
      }
    }
  }
}

#AdvisorNewAccountModal{
    position: unset;
    .modal-dialog{
        margin: 0 auto;
    }
}