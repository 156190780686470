@import "../../../../assets/css/mixin";
@import "../../../../assets/css/reset";
@import "../../../../assets/css/variables";
.updateEvaluationPara{
    margin-bottom: 25px;
    .detail-box {
        @include font(15);
        font-weight: 400;
        color: $black;
        margin-bottom: 15px;
        p {
            line-height:1.5;
            margin-bottom: 0;
        }
    }
    .btn {
        background: $lightBlue;
        @include font(15);
        padding: 12px 15px;
        min-width: 160px;
        margin-right: 15px;
    }
    .draftText {
        @include font(15);
        color:$baseColor;
        cursor: pointer;
    }
}
.swal-footer{
    text-align: center !important;
}
.common-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: none;
    .modal-dialog {
        max-width: 480px;
    }
    .modal-content {
        border: none;
    }
    .modal-header {
        padding: 10px 25px;
        border-bottom: 1px solid rgba($border, .4%);
        .modal-title {
            @include font(18);
            font-weight: 600;
            color: $black;
          }
          .close {
            font-weight: 300;
            color: $black;
            outline: none;
        }
    }
    .modal-body {
        padding: 20px 25px 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid rgba($border, .4%);
        &.border-none {
            margin-bottom: 0;
            border-bottom: none;
        }
        .short-text {
            @include font(14);
            font-weight: 400;
            color: $black;
            margin-bottom: 25px;
            text-align: center;
            p {
                line-height: 1.6;
            }
        }
        .form-group {
            margin-bottom: 15px;
            label {
              display: block;
              @include font(14);
              font-weight: 400;
              color: $black;
              margin-bottom: 10px;
            }
            input {
              display: block;
              padding: 0 1em;
              width: 100%;
              height: 45px;
              @include font(14);
              font-weight: 400;
              color: $black;
              border: 1px solid $border;
              @include radius(4px);
              box-shadow: none;
            }
        }
        .draft-table-wrap {
            .head-box {
                @include flex();
                justify-content: space-between;
                @include font(15);
                font-weight: 600;
                color:$black;
                margin-bottom: 15px;
                .item {
                    .trash-btn {
                        background: none;
                        @include flex();
                        align-items: center;
                        outline: none;
                        border: none;
                        padding: 0;
                        .icon {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                    }
                }
            }
            .table-scrollbar {
                height: 250px;
                overflow-y: scroll;
                // Window Scrolbar
                &::-webkit-scrollbar {
                    border-radius: 10px;
                    width: 8px;
                    background: rgba($gray, 0.3%);
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background: rgba($gray, 0.8%);
                    cursor: pointer;
                }
            }
            .table {
                @include font(14);
                font-weight: 400;
                color:$black;
                margin-bottom: 0;
                
                tr {
                    &:first-child {
                        border-top: 1px solid $gray;
                    }
                    td {
                        border-top: none;
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                        }
                        .trash-btn {
                            background: none;
                            display: inline-block;
                            vertical-align: middle;
                            outline: none;
                            border: none;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
    .modal-footer {
        padding: 0 25px 25px;
        justify-content: center;
        border-top: 0;
        .btn {
            padding: 10px 20px;
            min-width: 105px;
            @include font(16);
            font-weight: 300;
            margin: 0 0.35rem;
            &.lightblue {
                background: $lightBlue;
            }
            &.gray {
                background: rgba($gray, .5);
                color: $blackColor;
            }
        }
    }
    &.publish-modal {
        .modal-body {
            padding: 30px 20px 25px;
            position: relative;
            text-align: center;
            border-bottom: none;
            margin-bottom: 0;
            .icon-box {
                display: block;
                text-align: center;
                margin-bottom: 15px;
                img {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .content-box {
                max-width: 380px;
                margin: 10px auto 0;
                @include font(16);
                color: $black;
                font-weight: 400;
                h4 {
                    line-height: 1.4;
                    margin-bottom: 10px;
                }
                p {
                    line-height: 1.6;
                    margin-bottom: 0;
                }
            }
        }
        .modal-footer {
            padding: 0 20px 30px;
        }
    }
    &.trash-modal {
        .modal-body {
            padding: 20px 25px 25px;
            text-align: center;
            .icon-box-first {
                margin-bottom: 10px;
            }
            .content-box {
                h4 {
                    line-height: 1.5;
                }
            }
        }
    }
}
.publish-modal {
    .swal-footer {
        padding: 0 25px 25px;
        justify-content: center;
        border-top: 0;
        .swal-button-container {
            .swal-button {
                background: $lightBlue!important;
                padding: 10px 20px;
                min-width: 105px;
                @include font(16);
                font-weight: 300;
                margin: 0 0.35rem;
               
            }
            .swal-button--danger {
                background: $baseColor!important;
            }
            &:first-child {
                .swal-button {
                    background:$green;
                }
            }
        }
        
    }
}
.modal {
    &.openModal {
        display: block;
    }
}
.error{
    color: red;
    margin-top: 3px;
    font-size: 14px;
}
.evaluation-sec {
    // padding: 45px 0;
    .report-data {
        background: $white;
        max-width: 900px;
        margin:  0 auto; 
        padding: 20px;
        border: 1px solid $gray;
        @include radius(4px);
        .sec-title {
            @include font(20);
            font-weight: 500;
            color: $baseColor;
            margin-bottom: 20px;
        }
        .field-column-wrap {
            width: 40%;
        }
        .field-box-item {
            margin-bottom: 25px;
            h5 {
                @include font(16);
                font-weight: 500;
                color: $blackColor; 
                margin-bottom: 15px;
                sup {
                    display: inline-block;
                    vertical-align: -8px;
                    color: $baseColor;
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }
            label {
                display: block;
                @include font(14);
                font-weight: 400;
                color: $black; 
                line-height: 1.5;
                margin-bottom: 18px;
            }
            .MuiInputLabel-formControl {
                background: $white;
                sup {
                    color: $baseColor;
                }
            }
            .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
                padding-right: 0!important;
            }
            .MuiAutocomplete-clearIndicator {
                margin-right: 15px!important;
            }
            input {
                @include font(14);
                font-weight: 400;
                color: $black;
                width: 100%;
                border: 1px solid $border;
                @include radius(4px);
                padding: 0 15px!important;
                height: 50px;
                box-shadow: none;
                &.search-input {
                    @include font(14);
                    font-weight: 300;
                    color: $black;
                    width: 100%;
                    border: 1px solid $border;
                    @include radius(4px);
                    padding: 0 1em;
                    height: 50px;
                    box-shadow: none;
                    padding-right: 40px;
                    background: url(../../../../assets/img/search-icon.svg) no-repeat;
                    background-position: bottom 17px right 12px;
                }
                &::placeholder {
                  color: rgba($black, .5)
                }
            }
            textarea {
                padding: 15px;
                width: 100%;
                min-height: 150px;
                @include font(16);
                font-weight: 500;
                color: $black;
                line-height: 1.5;
                border: 1px solid $gray;
                outline: none;
                box-shadow: none;
                @include radius(4px);
                &::placeholder {
                  color: rgba($black, .5)
                }
            }
            select {
                line-height: 22px;
                padding: 10px 40px 10px 15px;
                background-image: url(../../../../assets/img/arrowdown.png);
                background-repeat: no-repeat;
                background-position: 96.5% center;
                background-size: 16px;
                @include font(14);
                font-weight: 400;
                color: $black;
                width: 100%;
                height: 50px;
                border: 1px solid $border;
                @include radius(4px);
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
            .checkbox-group {
                li {
                    margin-bottom: 18px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .checkbox {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    input[type="checkbox"] {
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      top: 0;
                      opacity: 0;
                      z-index: 1;
                      @include radius(4px);
                      cursor: pointer;
                      &:checked {
                        + label {
                          &::before {
                            border-color: $lightBlue;
                          }
                          &::after {
                            content: "";
                            display: inline-block;
                            background: url(../../../../assets/img/checkBlue.png) no-repeat;
                            background-size: cover;
                            position: absolute;
                            width: 12px;
                            height: 12px;
                            left: 3px;
                            top: 5px;
                          }
                        }
                      }
                    }
                    label {
                      padding-left: 30px;
                      position: relative;
                      @include font(15);
                      font-weight: 300;
                      color: $black;
                      line-height: 18px;
                      cursor: pointer;
                      margin-bottom: 0;
                      &::before {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        width: 18px;
                        height: 18px;
                        left: 0;
                        top: 0;
                        border: 1px solid $border;
                        @include radius(2px);
                      }
                    }
                }
            }
        }
        .report-table-wrap {
            .top-block {
                padding-right: 120px;
                margin-bottom: 25px;
                position: relative;
                h5 {
                    @include flex();
                    align-items: center;
                    @include font(16);
                    font-weight: 500;
                    color: $blackColor; 
                    margin-bottom: 10px;
                    .icon {
                        margin-left: 8px;
                    }
                }
                .info-box {
                    @include flex();
                    align-items: center;
                    margin-left: 15px;
                    @include font(14);
                    font-weight: 400;
                    color: $blackColor;
                    .item {
                        margin-right: 10px;
                        .icon {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 8px;
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    
                }
                p {
                    @include font(14);
                    font-weight: 400;
                    margin-bottom: 0;
                }
                .btn-wrap {
                    position: absolute;
                    right:0;
                    top: 0;
                    z-index: 1;
                    .btn {
                        background: $green;
                        padding: 8px 15px;
                    }
                }
            }
            .report-table {
                width: 100%;
                border: 1px solid rgba($gray, .5);
                @include radius(4px);
                overflow: hidden;
                margin-bottom: 20px;
                table {
                    width: 100%;
                }
                thead {
                    tr {
                        &:first-child {
                            td {
                                font-weight: bolder !important;
                            }
                        }
                    }
                }
                tr {
                    border-bottom: 1px solid rgba($gray, .5);
                    td {
                        background: $white;
                        padding: 15px;
                        @include font(15);
                        font-weight: 400;
                        color: $black;
                        text-align: center;
                        &:first-child {
                            text-align: left;
                        }
                        span{
                            svg{
                                cursor: pointer;
                            }
                        }
                    }
                    &:nth-child(odd) {
                        td {
                            background: $bgLightGray;
                        }
                    }
                    &:first-child {
                        td {
                            font-weight: 500;
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
        .btn-wrap {
            .btn {
                display: inline-block;
                vertical-align: middle;
                padding: 10px 15px;
                min-width: 100px;
                margin:0 8px 5px;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                &.gray {
                    background: rgba($gray, .5);
                    color: $blackColor;
                }
                &.blue {
                    background: $lightBlue;
                }
                &.yellow {
                    background: $yellow;
                }
            }
        }
        .blue-box-wrap {
            background: rgba($lightBlue, 0.2);
            padding: 10px 20px 10px 40px;
            @include font(14);
            font-weight: 400;
            color: $blackColor;
            @include radius(5px);
            margin-top: 20px;
            position: relative;
            &::after {
                content:"";
                background: url(../img/lock-icon.svg) no-repeat;
                background-position: center center;
                position: absolute;
                width: 12px;
                height: 14px;
                left: 15px;
                top: 14px;
                z-index:1;
            }
            p {
                line-height: 1.6;
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width:991px) {
    .evaluation-sec {
        .report-data {
            .field-column-wrap {
                width: 100%;
            }
        }
    }
}
@media screen and (max-width:639px) {
    .common-modal {
        .modal-dialog {
            max-width: 100%;
        }
    }
    .updateEvaluationPara{
        display: block;
        margin-bottom: 20px;
        .detail-box {
            width: 100%;
            margin-bottom: 15px;
            p {
                line-height:1.5;
                margin-bottom: 0;
            }
        }
        .btn {
            display: block;
            margin-left: 0;
            margin-bottom: 10px;
        }
    }
    .evaluation-sec {
        .report-data {
            .report-table-wrap {
                .top-block {
                    padding-right: 0;
                    .btn-wrap {
                        position: static;
                        padding-top: 15px;
                    }
                }
                .report-table {
                    overflow-x: scroll;
                    &::-webkit-scrollbar {
                        border-radius: 10px;
                        height: 12px;
                        width: 8px;
                        background: rgba($gray, 0.3%);
                      }
                      &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background: rgba($gray, 0.8%);
                        cursor: pointer;
                      }
                    table {
                        width: 600px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:567px) {
    .evaluation-sec {
        .report-data {
            .report-table-wrap {
                .top-block {
                    h5 {
                        display: block;
                    }
                    .info-box {
                        margin-left: 0;
                        padding-top: 10px;
                    }
                    p {
                        line-height: 1.5;
                    }
                }
            }
        }
    }
}
