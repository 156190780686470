@import  '../css/mixin';
@import "../css/variables";
@import "./reset";


.navbar {
    background: #040404;
    height: 65px;
    @include flex();
    align-items: center;
    padding: 0 20px;
    position: relative;
    box-shadow: 0px 3px 6px #B2B2B229;
    .pagetitle {
        @include font(20);
        font-weight: 600;
        color:$white;
        h1 {
            margin-bottom: 0;
            .count {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              background:$lightBlue;
              width: 25px;
              height: 25px;
              @include font(15);
              font-weight: 500;
              color:$white;
              text-align: center;
              @include radius(50%);
              margin-left: 8px;
            }
        }
    }
    .navbar-menu {
        @include flex();
        align-items: center;
        li {
            &.searchbox {
                position: relative;
                margin-right: 15px;
                input {
                    display: block;
                    background:$white url(../img/search.svg) 12px center no-repeat;
                    padding: 10px 15px 10px 30px;
                    width: 100%;
                    height: 44px;
                    @include font(14);
                    font-weight: 400;
                    color:$black;
                    border: 1px solid #E8E8E8;
                    @include radius(4px);
                    &::placeholder {
                        color:#E8E8E8;
                    }
                }
                .dropdown-menu {
                    background: $white;
                    width: 280px;
                    padding: 15px 12px;
                    box-shadow: 0px 5px 15px #0000003B;
                    @include radius(4px);
                    position: absolute!important;
                    left: 0!important;
                    right: auto!important;
                    top: 100%!important;
                    min-width: 100%;
                    transform: none!important;
                    min-height: 1px;
                    margin-right: 0;
                    margin-top: 0;
                    z-index: 11;
                    .title {
                        @include font(15);
                        font-weight: 400;
                        color: $blackColor;
                        padding-bottom: 12px;
                    }
                    .search-list {
                        max-height: 520px;
                        overflow-y: scroll;
                        padding-right: 12px;
                        &::-webkit-scrollbar {
                            border-radius: 10px;
                            width: 8px;
                            background: rgba($gray, 0.3%);
                        }
                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background: rgba($gray, 0.8%);
                            cursor: pointer;
                        }
                        li {
                            @include flex();
                            align-items: center;
                            padding: 15px 10px;
                            border-bottom: 1px solid $gray;
                            @include radius(5px 5px 0 0);
                            margin-right: 0;
                            &:last-child {
                                margin-bottom: 0;
                                border-bottom: none
                            }
                            a {
                                @include flex();
                                align-items: center;
                                cursor: pointer;
                            }
                            .pic {
                                @include flex();
                                justify-content: center;
                                align-items: center;
                                text-transform: uppercase;
                                color: $black;
                                overflow: hidden;
                                border: 1px solid grey;
                                flex: 0 0 55px;
                                width: 55px;
                                height: 55px;
                                @include radius(50px);
                                margin-right: 10px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .desgination {
                                @include font(14);
                                font-weight: 300;
                                color: $black;
                                line-height: 1.4;
                                strong {
                                    display: block;
                                    font-weight: 600;
                                    color: $blackColor;
                                }
                            }
                            &:hover {
                                background: rgba($lightBlue, .2);
                            }
                        }
                    }
                }
            }
            &.notifyLink {
                a {
                    display: flex;
                    align-items: center;
                    padding: 10px 0.25em;
                    height: 65px;
                }
                .count {
                    position: absolute;
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    width: 15px;
                    height: 15px;
                    background:$baseColor;
                    @include radius(2px);
                    text-align: center;
                    @include font(12);
                    font-weight: 700;
                    color: $white;
                    right: 10px;
                    top: 10px;
                    z-index: 1;
                }
                &.active {
                    a {
                        background:$baseColor;
                        .count {
                            background:$white;
                            color:$baseColor;
                        }
                    }
                }
            }
            &.emailLink {
                a {
                    display: flex;
                    align-items: center;
                    padding: 10px 0.25em;
                    height: 65px;
                }
                .count {
                    position: absolute;
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    width: 15px;
                    height: 15px;
                    background:$baseColor;
                    @include radius(2px);
                    text-align: center;
                    @include font(12);
                    font-weight: 700;
                    color: $white;
                    right: 10px;
                    top: 10px;
                    z-index: 1;
                }
                &.active {
                    a {
                        background:$baseColor;
                        .count {
                            background:$white;
                            color:$baseColor;
                        }
                    }
                }
            }
            &.mobileSearchIcon {
                display: none;
                margin-right: 10px;
                .searchBtn {
                  border: none;
                  outline: none;
                  background: transparent;
                  padding: 5px;
                  align-items: center;
                  justify-content: center;
                  display: inline-flex;
                  @include radius(0px);
                  &::before {
                    display: none;
                  }
                }
            }
            &.profileLink {
                margin: 10px 0 10px 10px;
                .avtaar {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    background:$white;
                    width: 40px;
                    height: 40px;
                    @include font(16);
                    font-weight: 600;
                    color: $baseColor;
                    text-transform: uppercase;
                    border: 1px solid $white;
                    @include radius(50px);
                    cursor: pointer;
                    @include transition();
                    position: relative;
                    .profile-dropdwon {
                        display: none;
                        background:$white;
                        padding: 15px;
                        @include font(15);
                        font-weight: 500;
                        color: $black;
                        text-transform: capitalize;
                        position: absolute;
                        min-width:190px;
                        top: 100%;
                        right: -5px;
                        z-index: 11;
                        box-shadow: 0px 3px 6px #B2B2B229;
                        cursor: pointer;
                        @include transition();
                        li {
                          display: block;
                          @include font(14);
                          font-weight: 300;
                          color: $black;
                          letter-spacing: 0.21px;
                          line-height: 24px;
                          @include transition();
                          margin-bottom: 10px;
                          svg {
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 0;
                            margin-right:10px;
                          }
                          &:last-child {
                            margin-bottom: 0;
                          }
                        }
                    }
                    &:hover {
                        background: $baseColor;
                        border-color: $baseColor;
                        color: $white;
                        .profile-dropdwon {
                            display: block;
                        }
                    }
                    
                }
            }
            a {
                display: block;
                text-decoration: none;
                @include font(14);
                font-weight: 400;
                color: $black;
                position: relative;
                svg {
                    height: 20px;
                    display: block;
                    margin: 0 10px;
                }
            }
        }
    }
}
.modal {
    &.switch-user-modal {
      .modal-content {
        border: none;
      }
      .modal-header {
        padding: 15px;
        border-bottom: 1px solid rgba($gray, 0.4);
        .modal-title {
          @include font(16);
          font-weight: 500;
          color: $blackColor;
        }
        .close {
          font-weight: 400;
          color: $blackColor;
          outline: none;
        }
      }
      .modal-body {
        padding: 20px;
        margin-bottom: 25px;
        border-bottom: 1px solid rgba($gray, 0.4);
        .switch-AccountUser-list {
          list-style: none;
          padding-left: none;
          overflow: hidden;
          overflow-y: scroll;
          height: auto;
          max-height: 200px;
          // Window Scrolbar
          &::-webkit-scrollbar {
            border-radius: 10px;
            width: 8px;
            background: rgba($gray, 0.3%);
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba($gray, 0.8%);
            cursor: pointer;
          }
          .item {
            float: left;
            width: 30.333%;
            margin-right: 3%;
            padding: 10px;
            position: relative;
            &:nth-child(3n + 3) {
              margin-right: 0;
            }
            &:nth-child(3n + 4) {
              clear: both;
            }
            input {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0px;
              top: 0px;
              opacity: 0;
              z-index: 1;
              cursor: pointer;
              &:checked + label {
                color: $lightBlue;
                svg {
                  path {
                    fill: $lightBlue;
                  }
                }
                &:after {
                  border: 1px solid $lightBlue;
                }
                &:before {
                  content: "";
                  display: inline-block;
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  background-color: $lightBlue;
                  right: 16px;
                  top: 16px;
                }
              }
            }
            label {
              display: block;
              background: $white;
              padding: 40px 10px 20px;
              @include font(14);
              color: rgba($black, 0.8);
              line-height: 1.3;
              @include radius(6px);
              box-shadow: 0 0 18px #0000001a;
              text-align: center;
              cursor: pointer;
              position: relative;
              &:after {
                content: "";
                width: 18px;
                height: 18px;
                border: 1px solid $gray;
                @include radius(50%);
                position: absolute;
                right: 12px;
                top: 12px;
              }
              span {
                display: block;
                &:first-child {
                  margin: 0 auto 15px;
                }
                &:last-child {
                  min-height: 35px;
                }
              }
            }
          }
        }
      }
      .modal-footer {
        justify-content: center;
        padding: 0 15px 25px;
        border-top: none;
        button {
          background: $lightBlue;
          padding: 10px 15px;
          @include font(14);
          font-weight: 300;
          min-width: 100px;
          margin: 0 0.35rem;
        }
      }
    }
}
.searchModal {
  padding-right: 0 !important;
  bottom: auto !important;
  overflow: inherit!important;
  &.show {
    display: block;
  }
  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
  .modal-content {
    @include radius(0);
    background: $blackBg;
    .modal-body {
      @include flex();
      padding: 0.517em 1em;
      width: 100%;
      max-width: 100%;
      .close {
        float: none;
        display: block;
        padding: 10px;
        margin-right: 10px;
        svg {
          height: 18px;
          width: auto;
        }
      }
      input[type="search"] {
        border: 0;
        flex-grow: 1;
        padding: 0 1em;
        @include radius(30px);
        background: rgba($white, 0.9%);
        width: 100%;
        max-width: 100%;
        @include transition();
        &:focus {
          background: $white;
        }
      }
      .dropdown-menu {
        background: $white;
        width: 280px;
        padding: 15px 12px;
        box-shadow: 0px 5px 15px #0000003b;
        @include radius(4px);
        position: absolute !important;
        left: 0 !important;
        right: auto !important;
        top: 100% !important;
        min-width: 100%;
        transform: none !important;
        min-height: 1px;
        margin-right: 0;
        margin-top: 0;
        &.dropdownopen {
          display: block;
        }
        .title {
          @include font(15);
          font-weight: 400;
          color: $blackColor;
          padding-bottom: 12px;
        }
        .search-list {
          max-height: 520px;
          overflow-y: scroll;
          padding-right: 12px;
          &::-webkit-scrollbar {
            border-radius: 10px;
            width: 8px;
            background: rgba($gray, 0.3%);
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba($gray, 0.8%);
            cursor: pointer;
          }
          a {
            text-decoration: none;
            cursor: pointer;
          }
          li {
            @include flex();
            align-items: center;
            padding: 15px 10px;
            border-bottom: 1px solid $gray;
            @include radius(5px 5px 0 0);
            &:last-child {
              margin-bottom: 0;
              border-bottom: 0;
            }
            a {
              @include flex();
              align-items: center;
            }
            .pic {
              @include flex();
              justify-content: center;
              align-items: center;
              flex: 0 0 55px;
              width: 55px;
              height: 55px;
              @include radius(50px);
              margin-right: 10px;
              border: 1px solid $border;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
              span {
                @include font(18);
                font-weight: 500;
                color:$black;
                text-align: center;
                text-transform: capitalize;
              }
            }
            .desgination {
              @include font(14);
              font-weight: 300;
              color: $black;
              line-height: 1.4;
              strong {
                display: block;
                font-weight: 600;
                color: $blackColor;
              }
            }
            &:hover {
              background: rgba($lightBlue, 0.2);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:991px) {
    .navbar {
        padding-left: 20px;
        padding-right: 15px;
        .pagetitle {
          @include font(15);
        }
    }
}
@media screen and (max-width:639px) {
  .navbar {
    .navbar-menu {
        li {
            &.searchbox  {
                display: none;
            }
            &.mobileSearchIcon {
                display: block;
                margin-right: 5px;
            }
            a {
                svg {
                    margin: 0 4px;
                }
            }
        }
    }
  } 
}
@media screen and (max-width:567px) {
    .navbar {
      .pagetitle {
          @include font(13);
          h1 {
            .count {
              display: none;
            }
          }
      }
    }
    .modal {
        &.switch-user-modal {
          .modal-body {
            padding: 15px 5px;
            .switch-AccountUser-list {
              .item {
                padding: 8px 5px;
                label {
                  padding-left: 10px;
                  padding-right: 10px;
                  @include font(13);
                }
              }
            }
          }
        }
    }
}
@media screen and (max-width:374px) {
  .navbar {
      .pagetitle {
        @include font(11);
        width: 80px;
      }
  }
}