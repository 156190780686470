@import "../../../../assets/css/mixin";
@import "../../../../assets/css/reset";
@import "../../../../assets/css/variables";
.modal {
  &.featured-video-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow-y: scroll;
    .modal-dialog {
      max-width: 600px;
    }
    .modal-content {
      border: none;
      -webkit-box-shadow: 0px 3px 20px #00000080;
      box-shadow: 0px 3px 20px #00000080;
    }
    .modal-header {
      padding: 15px 25px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.4);
      margin-bottom: 0;
      .modal-title {
        @include font(20);
        font-weight: 700;
        color:$blackColor;
        margin-bottom: 0;
      }
      .close {
        font-weight: 300;
        color: #3E3E3E;
        outline: none;
      }
    }
    .modal-body {
      padding: 20px 25px 25px;
    }
    form {
      .fieldBox {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &.flexBox {
          @include flex();
          align-items: center;
          justify-content: space-between;
          &.phone {
            margin-bottom: 0;
            .flexBox {
              @include flex();
              justify-content: space-between;
              align-items: center;
              padding: 0 10px;
              max-width: 100%;
              border: 1px solid $gray;
              @include radius(4px);
              margin-bottom: 0!important;
              .countryFlag {
                width: 35px;
                img {
                  max-width: 100%;
                  height: auto;
                }
              }
              select {
                padding: 5px 15px 5px 5px;
                background: none;
                outline: none;
                border: none;
                @include font(14);
                color: $black;
                -webkit-appearance: button;
                appearance: button;
                width: 70px;
              }
              input {
                width: calc(100% - 100px);
                border: none;
              }
            }
          }
          .half {
            width: calc(50% - 10px);
          }
        }
        h5 {
          @include font(18);
          font-weight: 500;
          margin-bottom: 15px;
        }
        label {
          display: block;
          @include font(15);
          font-weight: 400;
          color: $black;
          margin-bottom: 10px;
          position: relative;
          sup {
            position: absolute;
            font-weight: 600;
            color: $baseColor;
            margin-left: 3px;
            top: 8px;
          }
        }
        input {
          @include font(14);
          font-weight: 300;
          color: $black;
          width: 100%;
          border: 1px solid $border;
          @include radius(4px);
          padding: 0 1em;
          height: 45px;
          box-shadow: none;
        }
        select {
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 14px 35px 14px 10px;
          @include font(14);
          font-weight: 300;
          color:$black;
          //background-image: url(../../../assets/img/arrowdown.png);
          background-repeat: no-repeat;
          background-position: bottom 14px right 12px;
          background-size: 12px;
          height: 45px;
          border: 1px solid $border;
          @include radius(4px);
        }
        textarea {
          display: block;
          width: 100%;
          padding: 15px;
          @include font(14);
          font-weight: 300;
          line-height: 22px;
          border: 1px solid $border;
          @include radius(4px);
          min-height: 120px;
        }
        .countryPhoneFlexBox {
          input, select {
            height: 30px!important;
          }
        }
        &.phone {
          .flexBox {
            @include radius(4px);
            input, select {
              border: none;
              width: auto;
            }
          }
        }
        .captcha-flex-box {
          @include flex();
          align-items: center;
          justify-content: space-around;
          .item {
            width: 70%;
          }
          .captcha-code {
            @include flex();
            align-items: center;
            justify-content: space-between;
            width: 30%;
            padding-left: 20px;
            .code {
              @include flex();
              align-items: center;
              justify-content: center;
              padding: 10px;
              background: $gray;
              @include font(18);
              color:$black;
              height: 45px;
              min-width: 100px;
              @include radius(5px);
            }
            .icon {
              display: inline-block;
              vertical-align: middle;
              img {
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      padding:25px;
      justify-content: center;
      .btn {
        background: $lightBlue;
        @include font(16);
        font-weight: 300;
        padding: 10px 15px;
        min-width: 100px;
        margin: 0 0.35rem;
      }
    }
  }
}
@media screen and (max-width:639px) {

  .modal {
    &.featured-video-modal {
      .modal-header {
        padding-left: 15px;
        padding-right: 15px;
        .modal-title {
          @include font(20);
        }
      }
      .modal-body {
        padding: 20px 15px 25px;
      }
      form {
        .fieldBox {
          margin-bottom: 15px;
          h5 {
            @include font(16);
          }
          &.flexBox {
            display: block;
            .half {
              width: 100%;
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .captcha-flex-box {
            .item {
              width: 50%;
            }
            .captcha-code {
              width: 50%;
            }
          }
        }
      }
    }
  }
}