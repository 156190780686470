@import "../../../../assets/css/mixin";
@import "../../../../assets/css/reset";
@import "../../../../assets/css/variables";
.video-and-photo-wrap {
    .video-share-box {
      text-align: right;
      margin-bottom: 1.25em;
      .share-btn {
        padding: 8px 16px;
        background: rgba($gray, 0.4%);
        @include font(13);
        color: $black;
        @include radius(4px);
        svg {
          margin-right: 5px;
          @include transition();
        }
        &:hover {
          background: $blue;
          color: $white;
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
    .video-and-photo-main {
      @include flex();
      justify-content: space-between;
      border-top: 1px solid $gray;
      .sidebarbox {
        width: 200px;
        padding-top: 1.2em;
        padding-right: 1em;
        margin-right: 1em;
        border-right: 1px solid $gray;
        min-height: 1000px;
        .menu-links {
          li {
            display: block;
            margin-bottom: 20px;
            .videoTag{
              display: flex;
              align-items: center;
              span{
                margin-left: 10px;
              }
            }
            a {
              display: block;
              text-decoration: none;
              @include font(14);
              font-weight: 500;
              color: $black;
              position: relative;
              padding-right: 30px;
              @include transition();
              &:hover {
                color: $baseColor;
                svg{
                  fill: $baseColor;
                }
                &:after {
                  content: "";
                  position: absolute;
                  background: url("../img/arrow-right-red-icon.svg") no-repeat;
                  width: 18px;
                  height: 18px;
                  right: 0;
                  top: 0;
                  z-index: 1;
                }
              }
            }
            &.active {
              a {
                color: $baseColor;
                svg{
                  fill: $baseColor;
                }
                &:after {
                  content: "";
                  position: absolute;
                  background: url("../img/arrow-right-red-icon.svg") no-repeat;
                  width: 18px;
                  height: 18px;
                  right: 0;
                  top: 0;
                  z-index: 1;
                }
              }
            }
          }
        }
        .category-list {
          .item {
            padding: 1em 0;
            border-top: 1px solid $gray;
            a {
              display: inline-block;
              vertical-align: middle;
              text-decoration: none;
              &.active {
                color: $baseColor;
              }
            }
            .add-toggle-button {
              padding: 0 30px 0 22px;
              @include font(14);
              font-weight: 500;
              color: $black;
              position: relative;
              cursor: pointer;
              &:after {
                content: "";
                background: url("../img/minus-circle-icon.svg") no-repeat;
                background-position: center center;
                position: absolute;
                width: 16px;
                height: 16px;
                left: 0;
                top: -1px;
                z-index: 11;
              }
              .edit-button {
                display: block;
                background: rgba($gray, 0.5%);
                position: absolute;
                width: 20px;
                height: 20px;
                right: 0;
                top: -3px;
                z-index: 1;
                @include radius(50%);
                &:after {
                  content: "";
                  background: url("../img/edit-icon.svg") no-repeat;
                  background-position: center center;
                  position: absolute;
                  width: 9px;
                  height: 9px;
                  right: 5px;
                  top: 6px;
                  z-index: 11;
                }
              }
            }
            .category-toggle-data {
              li {
                margin-top: 15px;
                .field-box {
                  padding: 8px 32px 8px 10px;
                  @include font(13);
                  font-weight: 300;
                  color: $black;
                  border: 1px solid $gray;
                  @include radius(4px);
                  position: relative;
                  input {
                    display: block;
                    width: 100%;
                    border: none;
                    @include font(13);
                    font-weight: 300;
                    color: $black;
                  }
                  .delete-button {
                    display: block;
                    background: url("../img/trash-red-icon.svg") no-repeat;
                    background-position: center center;
                    position: absolute;
                    width: 12px;
                    height: 15px;
                    right: 10px;
                    top: 10px;
                    z-index: 1;
                    cursor: pointer;
                  }
                }
                .button-box {
                  @include flex();
                  justify-content: flex-end;
                  align-items: center;
                  padding-top: 15px;
                  .close-button-c {
                    margin-right: 10px;
                    cursor: pointer;
                    svg {
                      width: 25px;
                      color:$baseColor!important;
                    }
                  }
                  .btn {
                    padding: 8px 10px;
                    @include font(12);
                    font-weight: 300;
                  }
                }
              }
            }
            &:first-child {
              a {
                padding-right: 0;
              }
            }
          }
        }
      }
      .content-box {
        padding-top: 1.2em;
        width: calc(100% - 200px);
      }
      .video-top-block {
        @include flex();
        justify-content: space-between;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $gray;
        .video-links {
          @include flex();
          justify-content: space-between;
          width: 52%;
          .half {
            width: calc(50% - 8px);
          }
        }
        .video-help-text {
          width: calc(100% - 52%);
          @include font(14);
          font-weight: 300;
          color: $black;
          text-align: right;
          svg {
            width: 15px;
            margin-right: 5px;
          }
        }
        .video-links {
          @include flex();
          align-items: flex-start;
          justify-content: space-between;
        }
        .link-box {
          @include flex();
          justify-content: center;
          align-items: center;
          text-decoration: none;
          padding: 1em;
          @include font(12);
          font-weight: 400;
          color: $black;
          line-height: 1.5;
          border: 1px solid $gray;
          box-shadow: 0 1px 3px #00000029;
          @include radius(5px);
          min-height: 230px;
          text-align: center;
          overflow: hidden;
          position: relative;
          .icon-box {
            @include flex();
            justify-content: center;
            background: $green;
            width: 50px;
            height: 50px;
            @include radius(50%);
            margin: 0 auto 12px;
            img {
              width: 22px;
            }
          }
          .text {
            display: block;
            min-height: 58px;
          }
          .browse-img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
          }
        }
      }
    }
    .video-second-block {
      .top-block {
        @include flex();
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      .tabs {
        @include flex();
        li {
          position: relative;
          @include font(14);
          color: rgba($black, 0.8%);
          padding-bottom: 10px;
          margin-right: 12px;
          cursor: pointer;
          &.current {
            color: $baseColor;
            &:after {
              content: "";
              position: absolute;
              background: $baseColor;
              width: 100%;
              height: 1px;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 11;
            }
          }
        }
      }
      .video-grid-control {
        @include flex();
        li {
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
          a {
            display: block;
            text-decoration: none;
            @include font(14);
            color: $black;
            span {
              display: inline-block;
              vertical-align: middle;
              border-bottom: 1px solid $black;
            }
            svg {
              display: inline-block;
              vertical-align: middle;
              margin-right: 5px;
              path {
                fill: $black;
                @include transition();
              }
            }
            &:hover,
            &.active {
              color: $baseColor;
              span {
                border-bottom: 1px solid $baseColor;
              }
              svg {
                path {
                  fill: $baseColor;
                }
              }
            }
          }
        }
      }
      .video-tab-contents {
        .tab-content {
          display: none;
          &.current {
            display: block;
          }
        }
        .video-grid-list {
          .video-box {
            float: left;
            width: 48.5%;
            margin-right: 3%;
            background: $white;
            box-shadow: 0 3px 6px #00000017;
            border: 1px solid $gray;
            @include radius(5px);
            margin-bottom: 20px;
            position: relative;
            @include transition();
            &:nth-child(2n + 2) {
              margin-right: 0;
            }
            &:nth-child(2n + 3) {
              clear: both;
            }
            &:hover {
              .img-box .info-icon li {
                display: flex;
                opacity: 1;
              }
            }
            .img-box {
              background:rgba($gray, .2);
              position: relative;
              height: 200px;
              overflow: hidden;
              @include radius(4px 4px 0 0);
              video {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              iframe {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .video-play-button {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;
                .video-play-inner {
                  @include flex();
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                }
                button {
                  @include flex();
                  justify-content: center;
                  align-items: center;
                  background: $baseColor;
                  width: 36px;
                  height: 36px;
                  @include radius(50%);
                  outline: none;
                  border: none;
                  svg path {
                    fill: $white;
                  }
                }
              }
              img {
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
              .info-icon {
                position: absolute;
                right: 10px;
                top: 15px;
                z-index: 11;
                li {
                  @include flex();
                  justify-content: center;
                  align-items: center;
                  background: $white;
                  width: 23px;
                  height: 23px;
                  text-align: center;
                  @include radius(50px);
                  margin-bottom: 10px;
                  display: none;
                  opacity: 0;
                  cursor: pointer;
                  @include transition();
                  &:first-child {
                    display: flex;
                    opacity: 1;
                  }
                  &.button-favorite {
                    svg {
                      path {
                        fill: $black;
                      }
                    }
                    &.active {
                      background: $yellow;
  
                      svg path {
                        fill: $white;
                      }
                    }
                  }
                  &.delete-button {
                    svg {
                      path {
                        fill: $baseColor;
                      }
                    }
                  }
                }
              }
            }
            .video-detail-box {
              background: none!important;
              padding: 15px 10px;
              .title-box {
                @include flex();
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .title {
                  @include font(13);
                  color: $blackColor;
                  line-height: 1.3;
                  width: 50%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                .switch-video-button {
                  cursor: pointer;
                  .form-check {
                    display: block;
                    padding-left: 0;
                    .form-check-label {
                      display: inline-block;
                      vertical-align: middle;
                      @include font(10);
                      color: $black;
                      margin-right: 5px;
                      cursor: pointer;
                    }
                    &.form-switch {
                      .form-check-input {
                        background-image: none;
                        border-width: 0;
                        width: 28px;
                        height: 10px;
                        background: rgba($green, 0.5%);
                        border: none;
                        margin-left: 0;
                        appearance: none;
                        @include radius(50px);
                        position: relative;
                        @include transition();
                        cursor: pointer;
                        &:after {
                          content: "";
                          background: $green;
                          position: absolute;
                          width: 14px;
                          height: 14px;
                          right: 0;
                          top: -2px;
                          border: none;
                          border-radius: 50%;
                          z-index: 2;
                          @include transition();
                        }
                      }
                      input:checked[type="checkbox"]:after {
                        left: 0 !important;
                        right: auto !important;
                      }
                    }
                  }
                }
              }
              .video-info {
                @include flex();
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                .half {
                  @include font(11);
                  color: $black;
                  span {
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: 5px;
                    width: 85%;
                    img {
                      display: block;
                      height: 28px;
                    }
                    &.ml-8 {
                      float: right;
                      margin-left: 8px;
                      text-align: right;
                    }
                    &.mr-8 {
                      margin-right: 8px;
                    }
                    &.shareLink {
                      display: block;
                      width: 100%;
                      border-top: 1px solid rgba($gray, 0.3%);
                      padding-top: 10px;
                      margin-top: 6px;
                      margin-bottom: 0;
                      text-align: center;
                      .share-btn {
                        display: inline-block;
                        vertical-align: middle;
                        background: transparent;
                        padding: 0;
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        @include transition();
                        .icon {
                          display: inline-block;
                          vertical-align: middle;
                          margin-left: 6px;
                          @include transition();
                          .a {
                            fill:$black;
                          }
                        }
                        &:hover {
                          color:$baseColor;
                          .icon {
                            .a {
                              fill:$baseColor;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .video-select-options {
                  @include flex();
                  justify-content: center;
                  align-items: center;
                  span {
                    position: relative;
                    margin-right: 10px;
                    cursor: pointer;
                    img {
                      height: auto;
                    }
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                  .video-settings-option {
                    display: none;
                    background: $white;
                    padding: 10px;
                    box-shadow: 0 1px 3px #00000029;
                    position: absolute;
                    width: 130px;
                    right: 20px;
                    top: 0;
                    @include radius(4px);
                    z-index: 11;
                    li {
                      display: block;
                      margin-bottom: 10px;
                      &:last-child {
                        margin-bottom: 0;
                      }
                      a {
                        display: block;
                        text-decoration: none;
                        @include font(12);
                        font-weight: 400;
                        color: $black;
                        @include transition();
                        &:hover {
                          color: $baseColor;
                        }
                      }
                    }
                  }
                }
              }
              .video-text {
                p {
                  &.checkbox-list {
                    span {
                      display: inline-flex;
                      align-items: center;
                      position: relative;
                      input[type="checkbox"] {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        z-index: 1;
                        cursor: pointer;
                        &:checked + label {
                          &::before {
                            border-color: $lightBlue;
                          }
                          &::after {
                            content: "";
                            display: inline-block;
                            background: url(../img/checkBlue.png) no-repeat;
                            background-size: cover;
                            position: absolute;
                            width: 8px;
                            height: 8px;
                            left: 3px;
                            top: 4px;
                          }
                        }
                      }
                      label {
                        position: relative;
                        @include font(12);
                        font-weight: normal;
                        color: $black;
                        line-height: 15px;
                        padding-left: 20px;
                        cursor: pointer;
                        &::before {
                          content: "";
                          display: inline-block;
                          position: absolute;
                          width: 14px;
                          height: 14px;
                          left: 0;
                          top: 0;
                          border: 1px solid $black;
                          @include radius(4px);
                        }
                      }
                    }
                  }
                }
              }
            }
            &.active {
              .video-settings-option {
                display: block !important;
              }
              &:after {
                content: "";
                background: rgba($black, 0.3%);
                position: absolute;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                @include radius(4px);
              }
            }
          }
          &.list {
            .video-box {
              @include flex();
              align-items: center;
              float: none;
              width: 100%;
              margin-right: 0;
              margin-bottom: 15px;
              .img-box {
                width: 220px;
                height: 120px;
              }
              .video-detail-box {
                width: calc(100% - 220px);
                padding: 10px 10px 15px;
              }
            }
          }
        }
        .clear-filter-box {
          display: inline-block;
          padding-top: 20px;
          text-align: center;
          border-top: 1px solid $gray;
          width: 100%;
          .clear-filters-button {
            background: rgba($gray, 0.4%);
            padding: 8px 16px;
            @include font(13);
            color: $black;
            &:hover {
              background: $blue;
              color: $white;
            }
          }
        }
      }
    }
  }
  .modal {
    .img-box {
      position: relative;
    }
  
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: relative;
    }
    iframe {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: relative;
    }
  
    .video-play-button {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      cursor: pointer;
  
      .video-play-inner {
        @include flex();
        justify-content: center;
        align-items: center;
        height: 100%;
      }
  
      button {
        @include flex();
        justify-content: center;
        align-items: center;
        background: $baseColor;
        width: 32px;
        height: 32px;
        @include radius(50%);
        outline: none;
        border: none;
  
        svg path {
          fill: $white;
        }
      }
    }
  
    &.common-slide-modal {
      .video-detail-box {
        @include flex();
        justify-content: space-between;
  
        .img-box {
          width: 450px;
          height: 360px;
          overflow: hidden;
  
          img {
            width: 100%;
            height: 100%;
          }
        }
  
        .detail-box {
          width: calc(100% - 470px);
          position: relative;
          padding-bottom: 40px;
        }
  
        .video-share-box {
          @include flex();
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
  
          .share-btn {
            background: rgba($gray, 0.5%);
            padding: 7px 15px;
            @include font(13);
            color: $black;
            @include radius(4px);
            @include transition();
  
            svg {
              margin-right: 5px;
              @include transition();
  
              path {
                fill: $black;
              }
            }
  
            &:hover {
              background: $blue;
              color: $white;
  
              svg path {
                fill: $white;
              }
            }
          }
  
          .close {
            outline: none;
          }
        }
  
        .profile-info-box {
          height: 100%;
  
          .top-block {
            @include flex();
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $gray;
  
            span {
              @include font(14);
              color: $black;
  
              &.edit-button-toggle {
                @include font(13);
                color: $baseColor;
                cursor: pointer;
              }
            }
          }
  
          .profile-content {
            display: none;
  
            .form-group {
              margin-bottom: 15px;
              padding-bottom: 15px;
              border-bottom: 1px solid $gray;
  
              &:last-child {
                margin-bottom: 0;
                border-bottom: none;
              }
            }
  
            input {
              font-family: 'Roboto';
              width: 100%;
              padding: 10px;
              color: $black;
              @include font(14);
              border: 1px solid $gray;
              @include radius(4px);
            }
  
            select {
              width: 100%;
              border: 1px solid $gray;
              @include radius(4px);
              -webkit-appearance: none;
              appearance: none;
              padding: 14px 15px 14px 10px;
              font-family: 'Roboto';
              color: $black;
              @include font(14);
              background-image: url(..//img/arrowdown.png);
              background-repeat: no-repeat;
              background-position: 95% center;
              background-size: 14px;
              line-height: 24px;
            }
          }
  
          .button-box-info {
            position: absolute;
            right: 0;
            bottom: 0;
  
            button {
              padding: 8px 15px;
              @include font(14);
              min-width: 80px;
              margin-right: 12px;
  
              &.delete-button {
                background: transparent;
                @include font(13);
                color: $baseColor;
  
                svg {
                  display: inline-block;
                  vertical-align: 0;
                  margin-right: 6px;
  
                  path {
                    fill: $baseColor;
                  }
                }
              }
  
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
  
      .owl-nav {
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        right: 0;
        z-index: 1;
        margin-top: -30px;
        &.disabled {
          display: none;
        }
        button {
          background: $white;
          width: 45px;
          height: 45px;
          @include radius(50px);
          box-shadow: 0 4px 15px #00000082;
        }
  
        button.owl-next {
          float: right;
          position: absolute;
          right: -70px;
          outline: none;
  
          span {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            right: 2px;
            left: auto;
            top: -5px;
          }
        }
  
        button.owl-prev {
          float: left;
          position: absolute;
          left: -70px;
          outline: none;
        }
  
        button span {
          font-size: 0;
          content: "";
          width: 14px;
          height: 14px;
          border-right: 2px solid $black;
          border-bottom: 2px solid $black;
          display: inline-block;
          position: relative;
          transform: rotate(-0deg);
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          left: 2px;
          top: -5px;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
      }
    }
  }  
.editProfile {
    .flex {
        @include flex();
        justify-content: space-between;
        flex-wrap: wrap;
        background: $white; 
        width:100%;
        overflow: hidden;
        position: relative;
        justify-content: flex-end;
    }
    .subHeader{
        width:100%;
        background: $blackBg;
        @include flex();
        justify-content: space-between;
        align-items: center;
        padding:.7em 1em;
        margin-bottom: 1px;
        .menu{
            button{
                background: none;
                border:0;
                color:$white;
                @include flex();
                align-items: center;
                @include font(18);
                font-weight: 500;
                img{
                    display: block;
                    margin-right:.8em;
                }
                &:focus{
                    outline: none;
                }
            }
            span{
                width: 20px;
                height: 2px;
                background: none;
                margin-right: 1em;
                position: relative;
                @include transition();
                &:before,&:after{
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: $white;
                    display: block;
                    left: 0;
                    position: absolute;
                    @include transition();
                }
                &:before{
                    top:0;
                    @include rotate(45deg);
                }
                &:after{
                    top:0;
                    @include rotate(-45deg);
                }
            }
        }
        .save{
            button{
                @include flex();
                align-items: center;
                @include font(16);
                font-weight: 500;
                background: $white;
                border:0;
                @include radius(3px);
                padding:.6758em 1em;
                @include transition();
                svg{
                    margin-right: 10px;
                    @include translateY(-1px);
                    path{
                        @include transition();
                    }
                    rect{
                        @include transition();
                    }
                }
                &:hover{
                    background: $lightBlue;
                    color:$white;
                    svg{
                        path{
                            fill:$white;
                        }
                        rect{
                            fill:$white;
                        }
                    }
                }
                &:focus{
                    outline: none;
                }
            }
        }
    }
    .sidebar{
        background: $white;
        width: 300px;
        overflow: hidden;
        border:1px solid $border;
        border-top:0;
        @include transition(all, .5s, cubic-bezier(.41,.18,.26,.99));
        position: absolute;
        left: 0;
        top: 61px;
        bottom: 0;
        .progressbar{
            background: rgba($green, .2%);
            @include radius(20px);
            height: 24px;
            position: relative;
            overflow: hidden;
            .progress{
                background: $green;
                @include radius(20px);
                @include flex();
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width:65%;
                @include flex();
                align-items: center;
                justify-content: center;
                @include font(14);
                font-weight: 800;
                color: $white;
            }
            &.small{
                height: 10px;
                width: 46px;
            }
        }
        .profileImg{
            background: url('../img/profileBg.png') center no-repeat;
            background-size:cover;
            min-height: 350px;
            padding:2em;
            text-align: center;
            @include flex();
            justify-content: center;
            flex-wrap: wrap;
            .profile{
                position: relative;
            }
            .pic{
                position: relative;
                display: inline-block;
                width: 140px;
                height: 140px;
                @include flex();
                align-items: center;
                justify-content: center;
                background: $white;
                @include radius(100%);
                border:3px solid rgba($black, .9%);
                @include font(35);
                font-weight: 800;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    &.verified{
                        position: absolute;
                        max-width: 100%;
                        height: auto;
                        width: auto;
                        right: 0;
                        bottom: 0;
                        @include translate(50%, 0);    
                    }
                }
            }
            .browse{
                position: absolute;
                bottom: 0;
                padding:10px;
                cursor: pointer;
                left: 50%;
                @include translate(-50%, -35%);
                &:before{
                    content: '+';
                    position: absolute;
                    z-index: 0;
                    width: 17px;
                    height: 17px;
                    background: $black;
                    color:$white;
                    right: 0;
                    top: 0;
                    line-height: 16px;
                    text-align: center;
                    @include radius(100%);
                    @include font(12);
                    font-weight: bold;
                }
                input[type=file]{
                    position: absolute;
                    z-index: 3;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    opacity: 0;
                    left: 0;
                    top: 0;
                }
            }
            .name{
                width: 100%;
                margin: 1.5em 0 .75em;
                h2{
                    color:$white;
                    @include font(18);
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                h3{
                    color:$white;
                    @include font(16);
                    font-weight: 500;
                }
            }
            .back{
                a{
                    background: $yellow;
                    display: block;
                    @include radius(30px);
                    color:$white;
                    padding:1em 2em;
                    @include transition();
                    img{
                        margin-right: 10px;
                    }
                    &:hover{
                        background: $lightBlue;
                        text-decoration: none;
                        img{
                            animation: animArrowRev infinite .5s;
                        }
                    }
                }
            }
        }
        .profileCompletion{
            padding:1.25em 1em;
            border-bottom: 1px solid $border;
            small{
                display: block;
                color:#9D9D9D;
                @include font(13);
            }
            .completion{
                @include font(15);
                font-weight: bold;
                margin: .25em 0 1em;
            }
        }
        .checklist{
            padding: .75em 0;
            li{
                @include flex();
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                padding:0 1em;
                position: relative;
                @include transition();
                &.active{
                    background: rgba($green, .1%);
                    &::before{
                        content:'';
                        border: 0;
                        height: 60%;
                        width: 4px;
                        @include radius(2px);
                        background: $green;
                        position: absolute;
                        left: 0;
                    }
                }
                &:hover{
                    background: rgba($green, .1%);
                }
            }
            .title{
                flex-grow: 1;
            }
            a{
                color:$black;
                text-decoration: none;
                padding:1em 0;
                @include flex();
                align-items: center;
                span{
                    position: relative;
                    margin-left: 10px;
                    display: inline-block;
                    em{
                        font-style: normal;
                        background:$yellow;
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        @include radius(50%);
                        @include font(12);
                        font-weight: bold;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        top:0;
                        right: 0;
                        @include translate(60%, -40%)
                    }
                }
                &:hover{
                    color: $green;
                }
            }
        }
    }
    .editSection{
        width: calc(100% - 300px);
        min-height: 1260px;
        @include transition(all, .5s, cubic-bezier(.41,.18,.26,.99));
        .subTitle{
            background: rgba($border,.2%);
            position: relative;
            padding:15px 20px;
            margin:20px 20px .5em;
            @include font(18);
            color:$green;
            &:before{
                content: '';
                position: absolute;
                width:4px;
                height: 100%;
                background: $green;
                left: 0;
                top: 0;
                @include radius(4px);
            }
        }
        .flexRow{
            padding:.5em 1em;
            @include flex();
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            &:first-of-type{
                border-bottom: 1px solid $border;
                padding:.75em 1em;
                align-items: center;
            }
            .title{
                @include font(20);
                font-weight: 500;
            }
            .btnWrap{
                @include flex();
                .btn{
                    margin-left: 1em;
                }
            }
            .col{
                flex-grow: 1;
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
            label{
                display: block;
                margin-bottom: .5em;
            }
            input[type=text],input[type=date]{
                width: 100%;
                border:1px solid $border;
                @include radius(4px);
                padding:0 1em!important;
                min-height: 46px;
            }
            .MuiOutlinedInput-input {
                @include font(14);
            }
            .MuiOutlinedInput-root {
                &:hover {
                  .MuiOutlinedInput-notchedOutline {
                    border-color: $gray;
                    outline: none;
                    box-shadow: none;
                  }
                }
                .Mui-disabled{
                    color: black;
                }
            }
            .note{
                margin-top: .5em;
                color:rgba($black,.7%);
            }
            &.subscription{
                background: $green;
                margin: 5em 1em 1em;
                @include radius(4px);
            }
            input[type=checkbox]{
                position: absolute;
                opacity: 0;
                + label{
                    position: relative;
                    color:$white;
                    margin: 0;
                    padding:.65em 1em .65em 2em;
                    line-height: 18px;
                    cursor: pointer;
                    display: inline-block;
                    &:before{
                        content: '';
                        border:.1em solid $white;
                        @include radius(2px);
                        position: absolute;
                        left:0;
                        top:50%;
                        width: 18px;
                        height: 18px;
                        @include translateY(-50%);
                    }
                    &:after{
                        content: '';
                        width: 18px;
                        height: 18px;
                        background: url('../img/check.svg') center no-repeat;
                        left:0;
                        top:50%;
                        @include translateY(-50%);
                        position: absolute;
                        @include transition();
                        opacity: 0;
                    }
                }
                &:checked{
                    +label{
                        &:after{
                            opacity: 1;
                        }
                    }
                }
            }
            &.link{
                justify-content: flex-start;
                a{
                    color:$baseColor;
                    display: inline-block;
                    margin-left: 4px;
                }
            }
        }
        .btnRow{
            justify-content: center;
            @include flex();
            border-top:1px solid $border;
            margin:1em;
            padding:1.5em 0;
            button{
                margin: 0 .5em
            }
        }
    }
    .inactive{
        .sidebar{
            @include translateX(-100%);
        }
        .subHeader{
            .menu{
                span{
                    background: $white; 
                    &:before{
                        @include rotate(0deg);
                        top:-8px;
                    }
                    &:after{
                        @include rotate(0deg);
                        top:8px;
                    }
                }
            }
        }
        .editSection{
            width: 100%;
        }
    }
}
.MuiInput-underline {
    &:before, &:after {
        border: none!important;
    }
}
.TimelineData {
  .video-and-photo-wrap {
    .video-and-photo-main {
      .sidebarbox {
        width: 140px;
      }
      .content-box {
        width: calc(100% - 140px);
      }
    }
  }
  .video-second-block {
    .video-tab-contents {
      .video-grid-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .video-box {
          width: 31.333%;
          &:nth-child(2n+2) {
            margin-right:3%;
          }
          &:nth-child(2n+3) {
            clear: none;
          }
          &:nth-child(3n+3) {
            margin-right: 0;
          }
          &:nth-child(3n+4) {
            clear: both;
          }
          .img-box {
            height: 160px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
    .editProfile {
        .editSection {
            .subTitle{
                padding:1em;
            }
            .flexRow{
                flex-wrap: wrap;
                padding:0 1em;
                &.subscription{
                    margin: 1em;
                }
                .col{
                    flex-basis: auto;
                    padding:0;
                    &:first-of-type{
                        padding-right: 0;
                    }
                    &:last-of-type{
                        padding-left: 0;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
  .TimelineData {
    .video-second-block {
      .video-tab-contents {
        .video-grid-list {
          .video-box {
            width: 48.5%;
            &:nth-child(3n+3) {
              margin-right: 3%;
            }
            &:nth-child(3n+4) {
              clear: none;
            }
            &:nth-child(2n+2) {
              margin-right:0;
            }
            &:nth-child(2n+3) {
              clear: both;
            }
            .img-box {
              height: 200px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
    .editProfile {
        .sidebar{
            position: absolute;
            z-index: 2;
            top:61px;
            overflow-y: auto;
        }
        .editSection{
            width: 100%;
            min-height: auto;
        }
    }
    .video-and-photo-wrap {
      .video-and-photo-main {
        display: block;
        .sidebarbox {
          width: 100%;
          min-height: 1px;
          margin-right: 0;
          border-right: none;
          border-bottom: 1px solid $border;
        }
        .content-box {
          width: 100%;
        }
      }
    }
    .TimelineData {
      .video-and-photo-wrap {
        .video-and-photo-main {
          .sidebarbox {
            width: 100%;
          }
          .content-box {
            width: 100%;
          }
        }
      }
    }
}

@media screen and (max-width: 579px) {
    .editProfile {
        .editSection {
            .subTitle{
                margin-top: 0;
            }
            .flexRow{
                &:first-of-type{
                    flex-wrap: wrap;
                    border: 0;
                    padding:2em 1em;
                    .title{
                        text-align: center;
                        width: 100%;
                    }
                    .btnWrap{
                        width: 100%;
                        justify-content: center;
                        button{
                            margin: 1em .5em 0;
                        }
                    }
                }
            }
            .btnRow{
                flex-wrap: wrap;
                .btn{
                    width: 100%;
                    &:first-of-type{
                        margin-bottom: 1em;
                    }
                }
            }
        }
    }
    .video-and-photo-wrap {
      .video-second-block {
        .video-tab-contents {
          .video-grid-list {
            display: block;
            flex-wrap: nowrap;
            .video-box {
              float: none;
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }
    }
    .TimelineData {
      .video-second-block {
        .video-tab-contents {
          .video-grid-list {
            .video-box {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }
    }
}

