@import '../css/mixin';
@import "../css/variables";
@import "./reset";

.dashboard {
    padding: 20px 20px;

    &.scrollbar-wrap {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .inviteStatusText {
        margin-bottom:10px;
        p {
            @include font(14);
            text-align: right;
            font-weight: 400;
            color: $black;
            line-height: 1.2;
            margin-bottom: 0;
        }
    }

    .filter-box {
        @include flex();
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .search-box {
            width: 500px;
            &.tab-width-none {
                width: auto;
            }
            input {
                display: block;
                background: $white url(../img/search.svg) 15px center no-repeat;
                padding: 10px 15px 10px 35px;
                width: 100%;
                height: 44px;
                @include font(14);
                font-weight: 400;
                color: $black;
                border: 1px solid #E8E8E8;
                @include radius(4px);

                &::placeholder {
                    color: rgba($black, .3);
                }
            }

            &.tab-width-auto {
                width: 440px;
            }

            .tab-wrap {
                background: $white;
                padding: 0 15px;
                border: 1px solid #E8E8E8;
                @include radius(4px);
                margin: 0;
                position: relative;
                top: auto;

                .tabs {
                    @include flex();
                    align-items: center;

                    li {
                        background: none;
                        padding: 11.5px 0;
                        position: relative;
                        min-width: auto;
                        @include font(15);
                        font-weight: 400;
                        color: $black;
                        border: none;
                        box-shadow: none;
                        margin: 0 15px 0 0;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.current {
                            color: $baseColor;

                            &::after {
                                content: "";
                                background: $baseColor;
                                position: absolute;
                                width: 100%;
                                height: 2px;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }

        .filters {
            @include flex();
            align-items: center;
            justify-content: flex-end;
            @include font(15);
            font-weight: 400;
            color: $black;
            width: 100%;

            .title {
                display: block;
                background: $white;
                padding: 10px 15px;
                min-width: 65px;
                @include font(15);
                font-weight: 400;
                color: $black;
                border: 1px solid #E8E8E8;
                @include radius(4px);
                margin-bottom: 0;
                cursor: pointer;

                .icon {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 5px;
                }

                &.togglebtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    position: relative;

                    .dropdownbox {
                        padding: 12px;
                        background: $white;
                        position: absolute;
                        width: 150px;
                        top: 100% !important;
                        transform: none !important;
                        border: 0;
                        left: auto !important;
                        right: 0 !important;
                        box-shadow: -2px 3px 11px #00000029;
                        @include radius(6);
                        z-index: 111;
                        li {
                            @include font(15);
                            font-weight: 400;
                            color: $black;
                            margin: 0 0 8px;
                            cursor: pointer;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            .flexbox {
                display: block;
                padding: 15px 20px !important;
                background: $white;
                position: fixed;
                width: 300px;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 9999;
                @include radius(0px);
                overflow-y: scroll;
                opacity: 0;
                visibility: hidden;
                overflow-x: hidden;

                &.show {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                    z-index: 999;
                }

                .item {
                    width: 100%;
                    margin: 0 0 15px;

                    &.filter-close-btn {
                        display: block;
                        text-align: right;

                        .close {
                            float: none;
                            @include font(30);
                            font-weight: 400;
                            color: rgba($black, .4);
                            border: none;
                            outline: none;
                        }
                    }

                    select {
                        background-color: $white;
                        padding: 0 30px 0 10px;
                        background-image: url(../img/arrowdown.svg);
                        background-repeat: no-repeat;
                        background-position: bottom 17px right 12px;
                        background-size: 11px;
                        @include font(14);
                        color: $black;
                        width: 100%;
                        height: 44px;
                        line-height: 44px;
                        border: 1px solid #E8E8E8;
                        -webkit-appearance: none;
                        appearance: none;
                        @include radius(4px);
                    }

                    label+.MuiInput-formControl {
                        margin-top: 0;
                    }

                    .MuiFormControl-root {
                        width: 100%;

                        .MuiInputLabel-formControl {
                            @include font(14);
                            color: $black;
                            left: 10px;
                            top: -8px;
                            margin-top: 0 !important;

                            &.MuiInputLabel-shrink {
                                background: $white;
                                padding: 0 5px;
                                z-index: 1;
                            }
                        }

                        .MuiSelect-select {
                            &.MuiSelect-select {
                                padding-top: 12px;
                                padding-bottom: 11px;
                                @include font(14);
                                padding-left: 10px;
                                color: $black;
                                border: 1px solid #E8E8E8;
                                @include radius(4px);
                            }
                        }
                    }

                    .MuiAutocomplete-root {
                        padding: 6px 10px;
                        @include font(14);
                        color: $black;
                        border: 1px solid #E8E8E8;
                        @include radius(4px);

                        .MuiInputBase-input {
                            color: $blackColor;
                        }
                    }
                }
            }

            .sortFilter {
                @include flex();
                align-items: center;
                margin-left: 15px;

                label {
                    @include flex();
                    align-items: center;
                    @include font(15);
                    font-weight: 400;
                    color: $black;
                    margin-right: 15px;

                    span {
                        display: inline-block;
                        vertical-align: middle;

                        &.icon {
                            margin-right: 10px;
                        }
                    }
                }

                select {
                    background-color: $white;
                    padding: 0 30px 0 10px;
                    background-image: url(../img/arrowdown.svg);
                    background-repeat: no-repeat;
                    background-position: bottom 17px right 12px;
                    background-size: 11px;
                    @include font(14);
                    color: $black;
                    width: auto;
                    height: 40.5px;
                    line-height: 40px;
                    border: 1px solid #E8E8E8;
                    -webkit-appearance: none;
                    appearance: none;
                    @include radius(4px);
                }
            }
        }
    }

    .card-listing {
        @include flex();
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -15px;

        .cart-item {
            width: 33.333%;
            padding: 0 15px;
            margin-bottom: 30px;

            .cart-box {
                background: $white;
                padding: 20px;
                box-shadow: 0px 8px 8px #AFAFAF29;
                @include radius(8px);
                cursor: pointer;

                .info-box {
                    @include flex();
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-bottom: 20px;

                    .detail-box {
                        @include flex();
                        align-items: center;
                        width: 70%;

                        .icon-box {
                            margin-right: 15px;
                        }

                        .detail-text {
                            @include font(15);
                            font-weight: 400;
                            color: $black;

                            .title {
                                @include font(15);
                                color: #A5A5A5;
                                margin-bottom: 6px;
                            }

                            .count-text {
                                @include font(24);
                            }
                        }
                    }

                    .percentage-text {
                        @include font(14);
                        color: $green;
                        width: 30%;
                        text-align: right;
                    }
                }

                .card-link {
                    position: relative;
                    @include font(12);
                    font-weight: 400;
                    color: #A5A5A5;
                    border-top: 2px solid #A5A5A5;
                    border-radius: 50px;
                    margin: 5px 0;

                    .link-text {
                        padding: 0 20px 0 15px;
                        background: $white;
                        position: absolute;
                        right: 0;
                        top: -7px;
                        line-height: 1;

                        &::after {
                            content: '';
                            width: 6px;
                            height: 6px;
                            border-right: 1px solid #A5A5A5;
                            border-bottom: 1px solid #A5A5A5;
                            display: inline-block;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            position: absolute;
                            right: 5px;
                            top: 4px;
                        }
                    }
                }
            }

            &.green {
                .cart-box {
                    .info-box {
                        .percentage-text {
                            color: $green;
                        }
                    }
                }
            }

            &.yellow {
                .cart-box {
                    .info-box {
                        .percentage-text {
                            color: $yellow;
                        }
                    }
                }
            }

            &.orange {
                .cart-box {
                    .info-box {
                        .percentage-text {
                            color: $orange;
                        }
                    }
                }
            }

            &.red {
                .cart-box {
                    .info-box {
                        .percentage-text {
                            color: $baseColor;
                        }
                    }
                }
            }
        }
    }

    .white-box {
        background: $white;
        padding: 20px 15px;
        @include radius(4px);
        height: calc(100vh - 205px);
        .sec-title {
            @include font(18);
            font-weight: 500;
            color: $blackColor;
            margin-bottom:15px;
        }
        .table {
            width: 100%;
            @include font(14);
            font-weight: 400;
            color: $black;
            line-height: 1.333;
            margin-bottom: 0;

            th,
            td {
                padding: 12px 10px;
                vertical-align: middle;
                border-top-color: #E8E8E8;

                &:first-child {
                    padding-left: 0;
                }

                &.sticky-first-col {
                    background: $white;
                    position: sticky;
                    left: 0;
                    z-index: 1;
                }

                &.sticky-second-col {
                    background: $white;
                    position: sticky;
                    left: 47px;
                    z-index: 1;
                }

                .text-status {
                    width: 100px;
                }

                .team-text {
                    height: 40px;
                    overflow-y: scroll;
                    line-height: 1.3;
                    width: 180px;

                    // Window Scrolbar
                    &::-webkit-scrollbar {
                        border-radius: 10px;
                        width: 8px;
                        height: 10px;
                        background: rgba($gray, 0.3%);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background: rgba($gray, 0.8%);
                        cursor: pointer;
                    }
                }

                a {
                    text-decoration: none;
                    color: $lightBlue;
                    cursor: pointer;
                    &.text-underline {
                        text-decoration: underline;
                    }
                }

                .btn {
                    padding: 8px 12px;
                    @include font(14);
                    font-weight: 400;
                    line-height: 1;
                    min-width: 90px;
                    margin-left: 8px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
                .view-athlete-btn {
                    display: inline-block;
                    vertical-align: middle;
                    text-decoration: none;
                }
                .switch-button {
                    @include flex();
                    align-items: center;

                    input {
                        background-image: none;
                        background: rgba($black, .1);
                        width: 34px;
                        height: 12px;
                        border-width: 0;
                        @include radius(50px);
                        border: none;
                        margin-left: 0;
                        appearance: none;
                        position: relative;
                        @include transition();
                        cursor: pointer;
                        margin-top: 0;

                        &:after {
                            content: "";
                            background: $black;
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            left: 0;
                            top: -2px;
                            border: none;
                            border-radius: 50%;
                            @include transition();
                        }
                    }

                    input:checked[type="checkbox"] {
                        background: #c7ecfa;

                        &:after {
                            left: auto !important;
                            right: 0 !important;
                            background: $lightBlue;
                        }
                    }
                }

                .checkbox-info {
                    display: inline-flex;
                    align-items: center;
                    position: relative;

                    input[type="checkbox"] {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        z-index: 1;
                        cursor: pointer;
                        @include radius(2px);

                        &:checked {
                            +label {
                                &::before {
                                    border-color: $lightBlue;
                                }

                                &::after {
                                    content: "";
                                    display: inline-block;
                                    background: url(../img/checkBlue.png) no-repeat;
                                    background-size: cover;
                                    position: absolute;
                                    width: 12px;
                                    height: 12px;
                                    left: 3px;
                                    top: 5px;
                                }
                            }
                        }
                    }

                    label {
                        position: relative;
                        font-size: 14px;
                        color: $black;
                        font-weight: normal;
                        padding-left: 18px;
                        cursor: pointer;
                        line-height: 18px;
                        color: $black;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 18px;
                            height: 18px;
                            border: 1px solid $black;
                            @include radius(2px);
                        }
                    }
                }

                .unverified,
                .verified {
                    display: inline-block;
                    vertical-align: middle;
                    width: 16px;
                    height: 16px;
                }

                .name-text {
                    display: inline-flex;
                    align-items: center;
                    gap: 12px;
                    .btn-transparent {
                        display: inline-block;
                        vertical-align: middle;
                        background: transparent;
                        padding: 0;
                        outline: none;
                        border: none;
                        box-shadow: none;
                    }
                    a {
                        display: inline-block;
                        vertical-align: middle;
                        text-decoration: none;
                        color:$black;
                        cursor: auto;
                        min-width: 160px;
                        &:hover {
                            color:$black;
                            text-decoration: none;
                        }
                    }
                }

                .request-text {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .icon {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 6px;

                        svg {
                            display: inline-block;
                            vertical-align: middle;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .request-icon {
                    display: inline-block;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }

            tr {
                th {
                    background: $white;
                    padding-top: 0;
                    border-top: none;

                    &.sticky-first-col,
                    &.sticky-second-col {
                        z-index: 3 !important;
                    }

                    .checkbox-flex {
                        @include flex();
                        align-items: center;
                    }

                    .dropdown {
                        li {
                            position: relative;
                            margin-left: 5px;
                            &:first-child {
                                margin-left: 0;
                            }
                            .dropdown-toggle {
                                display: block;
                                padding: 0 5px 0;
                                @include radius(50px);

                                img {
                                    transform: rotate(90deg);
                                }

                                &:after {
                                    display: none;
                                }
                            }

                            .dropdown-menu {
                                padding: 10px;
                                background: $white;
                                min-width: 160px;
                                width: auto;
                                top: 100% !important;
                                transform: none !important;
                                border: 0;
                                box-shadow: -2px 3px 11px #00000029;
                                @include radius(6);

                                li {
                                    @include font(15);
                                    font-weight: 400;
                                    color: $black;
                                    margin: 0 0 8px;
                                    cursor: pointer;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                td {
                    .dropdown {
                        &.flex-dropdown {
                            @include flex();
                            align-items: center;
                        }
                        li {
                            position: relative;
                            margin-left: 5px;

                            .dropdown-toggle {
                                display: block;
                                padding: 0 5px 0;
                                @include radius(50px);

                                &:after {
                                    display: none;
                                }
                            }

                            .dropdown-menu {
                                padding: 12px;
                                background: $white;
                                width: 200px;
                                top: 100% !important;
                                transform: none !important;
                                border: 0;
                                left: auto !important;
                                right: 0 !important;
                                box-shadow: -2px 3px 11px #00000029;
                                @include radius(6);

                                li {
                                    @include font(15);
                                    font-weight: 400;
                                    color: $black;
                                    margin: 0 0 8px;
                                    cursor: pointer;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .tableFixHead {
            scroll-margin-top: 0;
            overflow: auto;
            height: calc(100% - 45px);

            thead th {
                background: $white;
                position: sticky;
                top: 0;
                z-index: 2 !important;
            }
        }

        /* Start Tageed page css */
        .tagged-tab-data {
            overflow-y: auto;
            height: calc(100% - 45px);
        }

        .tagged-dataBox {
            .dataItem {
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid rgba($border, .5);

                &:last-child {
                    margin-bottom: 0;
                }

                .dataFlex {
                    @include flex();
                    flex-wrap: wrap;
                    align-items: flex-start;
                    align-items: center;

                    .leftData {
                        @include flex();
                        align-items: center;
                        width: calc(100% - 100px);

                        div {
                            &.sort {
                                position: relative;
                                margin-right: 10px;
                                top: 10px;
                            }

                            &.count {
                                position: relative;
                                margin-right: 10px;
                                top: 10px;
                                @include font(16);
                                color: $black;
                                font-weight: 500;
                            }

                            &.data {
                                width: calc(100% - 60px);
                                padding-right: 20px;

                                .headFlex {
                                    @include flex();

                                    div {
                                        &.icon {
                                            margin-right: 10px;
                                            max-width: 35px;

                                            img {
                                                max-width: 100%;
                                                height: auto;
                                            }
                                        }

                                        h5 {
                                            @include font(15);
                                            font-weight: 500;
                                            color: $blackColor;
                                            margin: 0 0 5px 0;
                                        }

                                        ul {
                                            @include flex();

                                            li {
                                                @include flex();
                                                align-items: center;
                                                list-style: none;
                                                margin-right: 6px;

                                                span {
                                                    display: inline-block;
                                                    @include font(12);
                                                    color: rgba($black, .5);

                                                    &.circle {
                                                        background: rgba($black, .5);
                                                        width: 5px;
                                                        height: 5px;
                                                        @include radius(50%);
                                                        margin-left: 6px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .stepperBox {
                                    @include flex();
                                    justify-content: space-between;
                                    align-items: center;
                                    margin: 15px 0;

                                    .item {
                                        width: 16.66%;
                                        position: relative;
                                        cursor: pointer;

                                        span {
                                            display: block;
                                            text-align: center;
                                            @include font(12);
                                            color: $black;

                                            &.circle {
                                                background-color: #E5E5E5;
                                                width: 14px;
                                                height: 14px;
                                                @include radius(50%);
                                                margin: 0 auto 5px auto;
                                                position: relative;
                                                z-index: 1;
                                            }
                                        }

                                        &::before {
                                            content: "";
                                            display: inline-block;
                                            background-color: #E5E5E5;
                                            position: absolute;
                                            width: 100%;
                                            height: 2px;
                                            left: 0;
                                            top: 7px;
                                        }

                                        &.passed {
                                            &::before {
                                                background-color: $lightBlue;
                                            }

                                            span {
                                                &.circle {
                                                    background-color: $lightBlue
                                                }
                                            }
                                        }

                                        &.active {
                                            &::before {
                                                background-color: $lightBlue;
                                                width: 50%;
                                            }

                                            &::after {
                                                content: "";
                                                display: inline-block;
                                                background-color: #E5E5E5;
                                                position: absolute;
                                                width: 50%;
                                                height: 2px;
                                                left: 50%;
                                                top: 7px;
                                            }

                                            span {
                                                &.circle {
                                                    background-color: $lightBlue
                                                }
                                            }
                                        }

                                        &:last-child {
                                            &.active {
                                                &::before {
                                                    background-color: $lightBlue;
                                                    width: 100%;
                                                }

                                                &::after {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .rightData {
                        @include flex();
                        justify-content: flex-end;
                        width: 85px;
                        margin-left: 15px;
                        position: relative;
                        top: 10px;
                        z-index: 11;
                        right: 10px;

                        .actionBox {
                            @include flex();
                            justify-content: space-between;
                            align-items: center;

                            .badgeBox {
                                text-align: center;

                                a {
                                    display: inline-block;
                                    vertical-align: middle;
                                    text-decoration: none;
                                    cursor: pointer;

                                    svg {
                                        path {
                                            fill: none;
                                            stroke: #ff6624;
                                        }
                                    }

                                    &.favourite {
                                        svg {
                                            path {
                                                fill: #ff6624;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .UpdateFlexBox {
                        @include flex();
                        margin-left: 60px;

                        li {
                            @include flex();
                            align-items: center;
                            list-style: none;
                            margin-right: 10px;
                            background: #6767670D;
                            padding: 6px 10px;
                            @include radius(2px);
                            @include font(13);

                            &:last-child {
                                margin-right: 0;
                            }

                            label {
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 5px;
                            }

                            span {
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
        /* End Tag page css */
        &.athlete-infofrom {
            background:transparent;
            padding: 0;
            border-radius:0;
            .athlete-formbox-item {
                background: $white;
                padding: 20px 15px;
                @include radius(4px);
                margin-bottom: 15px;
                &.hide {
                    display: none;
                }
                &.show {
                    display: block;
                }
                .titlebox {
                    @include flex();
                    justify-content: space-between;
                    align-items: center;
                    .title {
                        @include font(16);
                        font-weight: 500;
                        color:$blackColor;
                        margin-bottom:20px;
                    }
                    .buttonbox {
                        margin-bottom:20px;
                        .editLink {
                            padding: 0;
                            background:transparent;
                            @include font(16);
                            font-weight:400;
                            color:$green;
                            border: none;
                            outline: none;
                            text-decoration: underline;
                            margin:0 10px 0 0;
                            &.removeLink {
                                color:$baseColor;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .add-parentBox {
                    display: block;
                    text-align: right;
                    .addLink {
                        padding: 0;
                        background:transparent;
                        @include font(16);
                        font-weight:400;
                        color:$lightBlue;
                        border: none;
                        outline: none;
                        text-decoration: underline;
                        margin:0 10px 0 0;
                    }
                }
                .buttonWrap {
                    justify-content: flex-end;
                }
            }
            .form-group-row {
                display: block;
                &.flexbox {
                    @include flex();
                    align-items: flex-start;
                    flex-wrap: wrap;
                    margin-left:-8px;
                    margin-right:-8px;
                    .form-group {
                        width: 20%;
                        padding-left:8px;
                        padding-right:8px;
                    }
                }
            }
            .form-group {
                margin-bottom: 20px;
                label {
                    display: block;
                    position: relative;
                    @include font(15);
                    font-weight: 400;
                    color: $black;
                    line-height: 1.4;
                    margin-bottom: 10px;
                    sup {
                        position: absolute;
                        top: 6px;
                        font-weight: 600;
                        color: $baseColor;
                        margin-left: 3px;
                    }
                }
                input {
                    background: $white;
                    padding: 10px;
                    width: 100%;
                    height: 44px;
                    @include font(15);
                    color: $black;
                    border: 1px solid #E8E8E8;
                    outline: none;
                    box-shadow: none;
                    @include radius(4px);
                    &::placeholder {
                      color: rgba($black, .5);
                    }
                }
                select {
                    background-color:$white;
                    padding: 10px 30px 10px 10px;
                    background-image: url(../img/arrowdown.svg);
                    background-repeat: no-repeat;
                    background-position: bottom 17px right 12px;
                    background-size: 11px;
                    @include font(15);
                    color: $black;
                    width: 100%;
                    height: 44px;
                    border: 1px solid #E8E8E8;
                    -webkit-appearance: none;
                    appearance: none;
                    @include radius(4px);
                }
                .radioflexBox {
                    @include flex();
                    align-items: center;
                    .option {
                      @include flex();
                      align-items: center;
                      position: relative;
                      margin-right: 15px;
                      margin-bottom: 0;
                      input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        z-index: 1;
                        cursor: pointer;
                        &:checked {
                          + label {
                            &::before {
                              border-color: $lightBlue;
                            }
                            &::after {
                              content: "";
                              background-color: $lightBlue;
                              display: inline-block;
                              position: absolute;
                              width: 8px;
                              height: 8px;
                              left: 5px;
                              top: 5px;
                              @include radius(50%);
                            }
                          }
                        }
                      }
                      label {
                        position: relative;
                        @include font(14);
                        color: $black;
                        font-weight: normal;
                        padding-left: 30px;
                        cursor: pointer;
                        margin-top: 15px;
                        margin-bottom: 0;
                        &::before {
                          content: "";
                          position: absolute;
                          width: 18px;
                          height: 18px;
                          left: 0;
                          top: 0;
                          border: 1px solid $black;
                          @include radius(50%);
                        }
                      }
                    }
                }
                &.phone {
                    .countryPhoneFlexBox {
                        border: 1px solid #E8E8E8;
                        @include radius(4px);
                        > div {
                          &.countrySelect {
                            width:130px;
                          }
                          .select__control, .css-1pahdxg-control {
                            border: none;
                            outline: none;
                            box-shadow: none;
                          }
                          .select__single-value {
                            @include flex();
                            align-items: center;
                          }
                        }
                        input {
                            @include font(15);
                            font-weight: 400;
                            color: $black;
                            height: 22px;
                        }
                        .textBox {
                          input {
                            border: none;
                            //@include font(12);
                          }
                        }
                    }
                }
                .MuiAutocomplete-root {
                    padding: 10px 10px;
                    width: 100%;
                    @include font(14);
                    color: $black;
                    border: 1px solid $gray;
                    outline: none;
                    box-shadow: none;
                    @include radius(4px);
                    input {
                      border: none;
                      padding: 0;
                      height: 21px;
                    }
                }
                .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, 
                .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
                    padding-right: 45px;
                }
            }
            .buttonWrap {
                @include flex();
                justify-content: space-between;
                align-items: center;
                .buttons {
                    .btn {
                        background:$white;
                        display: inline-block;
                        vertical-align: middle;
                        padding: 10px 20px;
                        @include font(16);
                        font-weight:400;
                        color: $black;
                        line-height:18px;
                        border: 1px solid $black;
                        @include radius(4px);
                        margin:0 10px 0 0;
                        &.lightblue {
                            background:$lightBlue;
                            color:$white;
                            border-color:$lightBlue;
                            &:hover {
                                background:$lightBlue;
                                color:$white;
                                border-color:$lightBlue;
                            }
                        }
                        &:hover {
                            background:$black;
                            color:$white;
                            border-color:$black;
                        }
                    }
                }
                .buttonsLinks {
                    .btnLink {
                        padding: 0;
                        background:transparent;
                        @include font(16);
                        font-weight:400;
                        color:$lightBlue;
                        border: none;
                        outline: none;
                        text-decoration: underline;
                        margin:0 10px 0 0;  
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .tab-wrap {
        background: #F2F3F5;
        padding: 20px 0 20px;
        @include flex();
        justify-content: center;
        align-items: center;
        position: sticky;
        top: -20px;
        z-index: 1;
        margin: -20px -20px 0;

        .tabs {
            li {
                display: inline-block;
                vertical-align: middle;
                background: $white;
                padding: 10px 15px;
                min-width: 90px;
                @include font(15);
                font-weight: 400;
                color: $blue;
                text-align: center;
                text-transform: capitalize;
                box-shadow: 0px 8px 8px #B1B1B129;
                border: 1px solid $blue;
                @include radius(4px);
                margin: 5px;
                cursor: pointer;
                @include transition();

                &.current {
                    background: $blue;
                    color: $white;
                }
            }
        }
    }

    .tab-contents {
        .tab-content {
            display: none;

            &.current {
                display: block;
            }
        }
    }

    .pagination {
        .flex {
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            width: 100%;
            padding-top: 15px;
        }

        .item {
            align-items: center;
            @include flex();
            justify-content: space-between;

            label {
                @include font(14);
                font-weight: 400;
                color: $black;
            }

            button {
                @include font(14);
                font-weight: 400;
                background: none;
                border: 0px;
                @include transition();
                @include radius(4px);
                padding: 0 8px;
                height: 30px;

                &:hover {
                    background: rgba($border, .3%);
                }
            }
            &.goto {
                button {
                    background: $lightBlue;
                    color: $white;
                    font-weight: 400;
                    border: none;
                    outline: none;
                }
            }
        }

        input[type=text],
        input[type=number] {
            max-width: 50px;
            @include font(14);
            font-weight: 400;
            color: $black;
            text-align: center;
            height: 30px;
            padding: 0 6px;
            margin: 0 10px;
            border: 1px solid $border;
            @include radius(4px);
            @include transition();

            &:focus {
                border-color: $black;
            }
        }

        select {
            background-image: url(../img/arrowdown.svg);
            background-repeat: no-repeat;
            background-position: bottom 12px right 10px;
            background-size: 8px;
            padding: 0 25px 0 10px;
            @include font(14);
            font-weight: 400;
            color: $black;
            width: 60px;
            height: 30px;
            margin: 0 10px;
            border: 1px solid $border;
            @include radius(4px);
            -webkit-appearance: none;
            appearance: none;
            @include transition();

            &:focus {
                border-color: $black;
            }
        }

        .paging {
            @include flex();
            overflow: hidden;

            a,
            span {
                @include flex();
                align-items: center;
                justify-content: center;
                padding: 10px;
                height: 30px;
                min-width: 36px;
                @include font(14);
                font-weight: 500;
                color: $black;
                @include radius(4px);

                &.active {
                    background: $lightBlue;
                    color: $white;
                    margin: -1px;
                    height: 32px;
                    min-width: 38px;
                }

                &:hover {
                    background: rgba($lightBlue, .1%);
                    text-decoration: none;
                }
            }

            li {
                &.active {
                    a {
                        background: $lightBlue;
                        color: $white;
                        margin: -1px;
                        height: 32px;
                        min-width: 38px;
                    }
                }

            }
        }

        &.pagination-whitebox {
            background: $white;
            padding: 15px;
            @include radius(0 0 8px 8px);
        }
    }

    &.dashboardAthleteInfo {
        .white-box {
            height: calc(100vh - 231px);
        }
    }
}

.notification-wrap {
    margin-bottom: 30px;

    >.item {
        @include flex();
        justify-content: space-between;
        align-items: center;

        .notification-date-item {
            width: 150px;
            margin-right: 30px;

            .date-text {
                @include font(12);
                font-weight: 600;
                color: rgba($black, .50%);
                text-align: right;
                padding-right: 15px;
                margin-top: -30px;
                position: relative;

                span {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;

                    +span {
                        margin-top: 5px;
                    }
                }

                &:after {
                    content: '';
                    background: $white;
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    right: 0;
                    border: 3px solid $blue;
                    @include radius(50px);
                }
            }
        }

        .notification-item {
            @include flex();
            align-items: center;
            padding: 15px 25px 15px 35px;
            background: $white;
            width: calc(100% - 180px);
            @include radius(4px);
            box-shadow: 1px 2px 2px #00000017;
            margin-bottom: 15px;
            position: relative;
            cursor: pointer;
            @include transition();

            &::before {
                content: '';
                background: $white;
                position: absolute;
                width: 2px;
                height: 100%;
                top: 50%;
                left: -38px;
                margin-top: 4px;
            }

            &:after {
                content: '';
                background: $blue;
                position: absolute;
                width: 7px;
                height: 7px;
                top: 50%;
                left: 14px;
                @include radius(50%);
                margin-top: -3px;
                display: none;
            }

            .user-profile {
                @include flex();
                align-items: center;
                justify-content: center;
                flex: 0 0 36px;
                width: 36px;
                height: 36px;
                @include font(14);
                font-weight: 400;
                color: $black;
                text-transform: uppercase;
                border: 1px solid rgba($blackColor, .1);
                @include radius(50px);
                overflow: hidden;
                margin-right: 15px;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                a {
                    color: $black;
                    text-decoration: none;
                }
            }

            .user-detial-box {
                @include flex();
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding-right: 65px;
                @include font(15);
                color: rgba($black, .60%);
                position: relative;

                .item {
                    &:last-child {
                        position: absolute;
                        right: 0;
                        top: 4px;
                        margin-right: 0;
                    }
                }

                .name {
                    font-weight: 700;
                    color: $black;
                    margin-bottom: 0;
                }

                .text-box {
                    @include flex();
                    align-items: center;
                    @include font(14);

                    .subject-line {
                        font-weight: 500;
                        line-height: 1.3;
                        margin-right: 10px;
                    }

                    a {
                        display: inline-block;
                        vertical-align: middle;
                        text-decoration: none;
                        color: $lightBlue;
                    }
                }

                .time-text {
                    @include font(13);
                    color: rgba($black, .30%);
                    font-weight: 600;
                    text-align: right;
                }
            }
        }

        &:last-child {
            .notification-item {
                &::before {
                    display: none;
                }
            }
        }

        &.active {
            .notification-item {
                background: $gray;

                &::before {
                    background: $gray;
                }

                &::after {
                    display: block;
                }
            }

        }
    }
}

.swal-text {
    text-align: center !important;
}

@media screen and (max-width:1699px) {
    .dashboard {
        .white-box {
            &.athlete-infofrom {
                overflow-y: scroll;
                .form-group-row {
                    &.flexbox {
                        .form-group {
                            width: 25%;
                        }
                    }
                }
            }
            .table-responsive {
                margin-bottom: 15px;
                overflow-x: scroll;

                // Window Scrolbar
                &::-webkit-scrollbar {
                    border-radius: 10px;
                    width: 8px;
                    height: 10px;
                    background: rgba($gray, 0.3%);
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background: rgba($gray, 0.8%);
                    cursor: pointer;
                }
                &.table-widthAuto {
                    .table {
                        width: 1200px;
                        min-width: 1200px;
                    }
                }
                .table {
                    width: 1380px;
                    min-width: 1380px;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .dashboard {
        overflow-y: scroll;
        .white-box {
            &.athlete-infofrom {
                .athlete-formbox-item {
                    .titlebox {
                        .buttonbox {
                            .editLink {
                                @include font(15);
                            }
                        }
                    }
                }
                .form-group-row {
                    &.flexbox {
                        .form-group {
                            width: 33.333%;
                        }
                    }
                }
                .buttonWrap {
                    .buttonsLinks {
                        .btnLink {
                            @include font(15);
                        }
                    }
                }
            }
        }
        .tab-wrap {
            margin-left: -15px;
            margin-right: -15px;

            .tabs {
                li {
                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .dashboard {
        padding-left: 15px;
        padding-right: 15px;

        .card-listing {
            .cart-item {
                width: 50%;
            }
        }

        .filter-box {
            .search-box {
                &.tab-width-auto {
                    width: 460px;
                }
                .tab-wrap {
                    padding: 0 10px;

                    .tabs {
                        @include flex();
                        align-items: center;

                        li {
                            @include font(15);
                        }
                    }
                }
            }
        }
    }

    .notification-wrap {
        >.item {
            .notification-item {
                .user-detial-box {
                    @include font(15);
                    padding-right: 90px;

                    .text-box {
                        display: block;

                        .subject-line {
                            display: block;
                            width: 100%;
                            margin-bottom: 5px;
                        }

                        p {
                            @include font(13);
                            width: 100%;
                        }
                    }

                    .item {
                        &:last-child {
                            top: 22px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .dashboard {
        .card-listing {
            margin: 0 -8px;

            .cart-item {
                padding-left: 8px;
                padding-right: 8px;
                margin-bottom: 25px;
            }
        }
        .filter-box {
            .search-box {
                &.tab-width-auto {
                    width: 500px;
                }
            }

            .filters {
                .sortFilter {
                    label {
                        display: none;
                    }
                }
                .title {
                    padding-left:10px;
                    padding-right: 10px;
                    @include font(13);
                }
            }
        }

        .white-box {
            &.athlete-infofrom {
                .form-group-row {
                    &.flexbox {
                        .form-group {
                            width: 50%;
                        }
                    }
                }
            }
            .tagged-dataBox {
                .dataItem {
                    .dataFlex {
                        .leftData {
                            width: calc(100% - 43px);
                        }

                        .rightData {
                            width: 28px;
                        }

                        .UpdateFlexBox {
                            li {
                                @include font(12);
                            }
                        }
                    }
                }
            }

        }
    }

    .notification-wrap {
        >.item {
            .notification-date-item {
                width: 150px;
                margin-right: 20px;

                .date-text {
                    @include font(12);
                }
            }

            .notification-item {
                width: calc(100% - 170px);

                &::before {
                    left: -28px;
                    margin-top: 4px;
                }
            }
        }
    }
}

@media screen and (max-width: 639px) {
    .dashboard {
        .card-listing {
            margin: 0 -8px;

            .cart-item {
                padding-left: 8px;
                padding-right: 8px;
                margin-bottom: 25px;
            }
        }

        .filter-box {
            .filters {
                .flexbox {
                    width: 100%;
                }
            }

            .search-box {
                width: 460px;
            }

            &.tagged-filter {
                display: block;

                .filters {
                    justify-content: space-between;

                    .title {
                        padding-left: 10px;
                        padding-right: 10px;
                        @include font(13);
                        height: 40.5px;
                        line-height: 20px;
                    }

                    .sortFilter {
                        margin-left: 10px;
                    }
                }
            }

            .search-box {
                &.tab-width-auto {
                    width: 100%;
                    margin-bottom: 10px;
                }

                .tab-wrap {
                    .tabs {
                        li {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        .white-box {
            height: calc(100vh - 260px);
            .tableFixHead {
                height: calc(100% - 130px);
                thead {
                    th {
                        background: none;
                        position: static;
                        left: auto;
                        z-index: 0;
                    }
                }
            }
            .table {
                th,
                td {
                    &.sticky-first-col {
                        background: none;
                        position: static;
                        left: auto;
                        z-index: 0;
                    }

                    &.sticky-second-col {
                        background: none;
                        position: static;
                        left: auto;
                        z-index: 0;
                    }
                }
            }
            .tagged-tab-data {
                overflow-x: scroll;
                height: calc(100% - 114px);

                .tagged-dataBox {
                    width: 640px;
                    min-width: 640px;
                }
            }
            &.athlete-infofrom {
                height: calc(100% - 44px);
            }
        }

        .tab-wrap {
            .tabs {
                li {
                    min-width: 70px;
                }
            }
        }

        .pagination {
            .flex {
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;

                .item {
                    display: block;

                    label {
                        display: block;
                        @include font(13);
                    }

                    select,
                    input[type=text],
                    input[type=number] {
                        margin-left: 0;
                        margin-top: 10px;
                    }

                    &.perPage {
                        order: 2;

                        select {
                            +label {
                                display: none;
                            }
                        }
                    }

                    &.goto {
                        order: 3;
                    }

                    &.pages {
                        order: 1;
                        width: 100%;

                        .paging {
                            @include flex();
                            margin: 0 auto 1em;
                            width: 100%;
                            height: 30px;
                            border: 1px solid rgba($border, .3%);
                            border-radius: 4px;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;

                            li {
                                width: 100%;
                                border-right: 1px solid rgba($border, .3%);

                                &:last-child {
                                    border-right: none;
                                }

                                a {
                                    min-width: 26px;
                                    height: 30px;
                                }
                            }

                            a,
                            span {
                                padding: 5px;
                                @include font(12);
                            }
                        }
                    }
                }
            }
        }
        &.dashboardAthleteInfo {
            .filter-box {
                display: block;
    
                .search-box {
                    width: 100%;
                    margin-bottom: 10px;
                }
    
                .filters {
                    justify-content: flex-end;
    
                    .title {
                        padding: 10px;
                        @include font(13);
                        width: 100%;
                        text-align: center;
                        line-height: 18px;
                        min-height: 40px;
                        &:first-child {
                            width: auto;
                        }
                        &.togglebtn {
                            .dropdownbox {
                                left: 0 !important;
                                right: auto !important;
    
                                li {
                                    @include font(14);
                                }
                            }
                        }
                    }
                }
            }
            .white-box {
                height: calc(100vh - 300px);
            }
        }
    }
}

@media screen and (max-width: 567px) {
    .dashboard {
        .card-listing {
            display: block;
            margin-left: 0;
            margin-right: 0;

            .cart-item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .filter-box {
            .search-box {
                width: 340px;
            }

            .filters {
                .title {
                    padding: 12px 10px;
                    @include font(13);
                    min-height: 40px;
                    line-height: 18px;
                }
            }
        }
        .white-box {
            &.athlete-infofrom {
                .form-group-row {
                    &.flexbox {
                        display: block;
                        .form-group {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .notification-read-box {
        .notification-title {
            @include flex();
            align-items: center;
            justify-content: space-between;
        }

        .notification-read {
            position: static;
            right: auto;
        }
    }

    .notification-wrap {
        margin-bottom: 0;
        padding-top: 15px;

        >.item {
            display: block;
            padding-bottom: 15px;
            margin-left: 25px;
            position: relative;

            &::after {
                content: '';
                background: $gray;
                position: absolute;
                width: 2px;
                height: 100%;
                top: 0;
                left: -19px;
                z-index: -1;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            .notification-date-item {
                width: 100%;
                margin: 15px auto 15px;

                .date-text {
                    text-align: left;

                    span {
                        +span {
                            margin-top: 0;
                        }
                    }

                    &:after {
                        right: auto;
                        left: -25px;
                    }
                }
            }

            .notification-item {
                width: 100%;

                &::before {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 479px) {
    .dashboard {
        .filter-box {
            display: block;
            .search-box {
                width: 100%;
                margin-bottom: 10px;
            }
            .filters {
                justify-content: flex-end;
                .title {
                    padding: 10px;
                    @include font(13);
                    width: 100%;
                    text-align: center;
                    line-height: 18px;
                    min-height: 40px;
                    &.togglebtn {
                        .dropdownbox {
                            left: 0 !important;
                            right: auto !important;

                            li {
                                @include font(14);
                            }
                        }
                    }
                }
            }
        }
    }

    .notification-read-box {
        .notification-title {
            @include font(14);
        }
    }

    .notification-wrap {
        >.item {
            margin-left: 20px;

            .notification-item {
                align-items: flex-start;
                padding: 15px 15px 15px 20px;

                .user-detial-box {
                    display: block;
                    @include font(13);
                    padding-right: 0;

                    .item {
                        .name {
                            padding-right: 0;
                            margin-bottom: 5px;
                        }

                        &:last-child {
                            position: static;
                            top: auto;
                        }
                    }

                    .text-box {
                        @include font(13);
                    }

                    .time-text {
                        @include font(12);
                    }
                }
            }

            &.active {
                .notification-item {
                    &::after {
                        left: 8px;
                        top: 33px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 374px) {
    .dashboard {
        .filter-box {
            .search-box {
                .tab-wrap {
                    .tabs {
                        li {
                            @include font(12);
                        }
                    }
                }
            }

            &.tagged-filter {
                .filters {
                    .title {
                        @include font(12);
                        line-height: 12px;
                    }

                    .sortFilter {
                        select {
                            @include font(12);
                        }
                    }
                }
            }
        }
    }
}