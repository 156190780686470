@import "../../assets/css/mixin";
@import "../../assets/css/reset";
@import "../../assets/css/variables";
body {
    margin: 0;
}
.admin-chosefile-wrap {
    @include flex();
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    .inner-wrap {
        width: 220px;
        position: relative;
        margin: 0 auto;
    }
    input {
        position: absolute;
        width: 100%;
        cursor: pointer;
        opacity: 0;
        left: 0;
        top: 0;
        height: 40px;
    }
    button {
        display: block;
        width: 100%;
        padding: 10px 20px;
        background: $baseColor;
        @include font(15);
        color: $white;
        font-weight: 400;
        @include radius(5px);
        height: 40px;
        border: none;
        outline: none;
        cursor: pointer;
    }
}