// Variable
@import "./variables";
// Mixin
@import "./mixin";

.MuiOutlinedInput-root{
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color:#dddddd!important;
      }
    }
  }
.signIn{
    width: 100vw;
    height: 100vh;
    overflow: auto;
    font-family: 'Montserrat', sans-serif !important;
    background: url(https://cdn.sportsforce.io/loginBG.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    .flex{
        background: $white;
        height: 100vh;
        max-width: 500px;
        margin: 0 auto;
        padding:30px 40px;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
        .logo {
            text-align: center;
            margin-bottom: 2em;
            img{
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
    }
    h2 {
        @include font(22);
        color: $black;
        margin-bottom: 0.8em;
        font-weight: 600;
        text-align: center;
    }
    .form {
        .user-box {
            margin-bottom: 1.2em;
            position: relative;
            label {
                display: block;
                @include font(15);
                font-weight: 600;
                color: $black;
                margin-bottom: 10px;
            }
            input[type=email],input[type=password],input[type=text]{
                box-sizing: border-box;
                border:1px solid $border;
                padding:15px 20px;
                width: 100%;
                @include font(18);
                @include radius(5px);
                &::placeholder{
                    font-family: 'Montserrat', sans-serif;
                }
            }
            input[name=password]{
                border: none;
                padding: 26px 22px;
            }
            #showPwd{
                position: absolute;
                right: 10px;
                top:10px;
                border:0px;
                background: none;
                padding:10px;
                color:$baseColor;
                @include transition();
                @include radius(5px);
                @include font(16);
                font-weight: 400;
                &:focus, &:hover{
                    outline: 0px;
                    background: #eee;
                    color:$black;
                }
            }
            input[type=checkbox]{
                position: absolute;
                opacity: 0;
                visibility: hidden;
                + label{
                    position: relative;
                    padding-left: 56px;
                    cursor: pointer;
                    @include transition();
                    @include font(18);
                    line-height: 1.5em;
                    &:before{
                        content: '';
                        position: absolute;
                        left:0px;
                        top:50%;
                        @include translateY(-50%);
                        width: 46px;
                        height: 16px;
                        @include radius(10em);
                        background: $border;
                        @include transition();
                    }
                    &:after{
                        content: '';
                        position: absolute;
                        left:0px;
                        top:50%;
                        @include translateY(-50%);
                        width: 24px;
                        height: 24px;
                        @include radius(10em);
                        background: $black;
                        @include transition();
                    }
                }
                &:checked{
                    +label{
                        color:$black;
                        
                        &:before{
                            background: rgba($lightBlue,.5%);
                        }
                        &:after{
                            background: $lightBlue;
                            left:22px;
                        }
                    }
                }
            }
            &.forgotPwd{
                text-align: center;
                margin-top: 1.5em;
                a{
                    color:$baseColor;
                    @include font(20);
                    font-weight: 600;
                }
            }
        }
        .btn {
            display: block;
            @include font(18);
            width: 100%;
            margin-top: 25px;
            cursor: pointer;
        }
    }
}
@media only screen and (max-width:580px){
    .signIn{
        .container{
            padding: 0px;
        }
        .flex{
            display: block;
            padding:40px;
            .logo {
                max-width: 160px;
                margin: 1em auto 1.2em;
            }
        }
        h2 {
            @include font(20);
            margin-bottom: 20px;
        }
        .form {
            .user-box {
                margin-bottom: 1em;
                input[type=email], input[type=password], input[type=text], input[type=checkbox] + label {
                    @include font(16);
                }
                &.forgotPwd{
                    margin: 1.6em 0;
                    a {
                        @include font(16);
                    }
                }
            }
            .btn {
                @include font(18);
            }
        }
    }
}
@media only screen and (max-width:480px){
    .signIn{
        .flex{
            padding:20px;
        }
    }
}