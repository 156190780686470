@import  '../css/mixin';
@import "../css/variables";
@import "./reset";

.sidebar-admin {
    background-color:#040404;
    padding:20px 25px;
    @include flex();
    flex-direction: column;
    position: fixed;
    z-index: 9;
    width: 300px;
    height: 100%;
    overflow-y: auto;
    top: 0;
    left: 0;
    transition: transform $ease-time $easing;
    .logo {
        max-width:255px;
        margin: 0 auto 10px;
        a {
            display: block;
            img {
                max-width: 100%;
            }
        }
    }
    .menu {
        display: block;
        li {
            display: block;
            position: relative;
            &.requestlink {
                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }
            }
            a {
                display: block;
                padding: 15px 15px;
                position: relative;
                text-decoration: none;
                @include font(15);
                font-weight: 400;
                color:$gray;
                @include radius(5px);
                @include transition();
                &.active {
                    background:$baseColor;
                    color: $white!important;
                }
                &:hover {
                    color: $white!important;
                }
                .count {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    background:$white;
                    @include font(15);
                    font-weight:500;
                    color:$baseColor;
                    width: 25px;
                    height: 25px;
                    @include radius(50px);
                }
                .droparrow {
                    position: absolute;
                    right: 15px;
                    &.uparrow {
                        transform: rotate(180deg);
                    }
                    svg {
                        path {
                            fill:$white;
                        }
                    }
                }
            }

            &.show-submenu {
                > a {
                    padding-right: 30px;
                    &.active {
                        background:none;
                        color: $baseColor!important;
                        .droparrow {
                            svg {
                                path {
                                    fill:$baseColor;
                                }
                            }
                        }
                    }
                }
            }
            .dropdown-menu {
                display: block;
                background: none;
                padding: 0;
                position:static;
                @include font(15);
                float: none;
                margin: 0 0 0 15px;
                border: none;
                box-shadow: none;
                li {
                    display: block;
                    @include transition();
                    a {
                        display: block;
                        padding: 15px 15px;
                        @include radius(5px);
                        &.active {
                            background:$baseColor;
                            color: $white!important;
                        }
                    }
                }
            }
        }
    }
}