//@import '../sportforce_admin_advisor/src/assets/css/mixin';
@import "./src/assets/css/mixin";

.forgot-pass-sec {
  background: url(https://cdn.sportsforce.io/loginBG.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  .forgot-box {
    background: #ffffff;
    padding: 20px 45px 35px;
    max-width: 520px;
    margin: 0 auto;
    height: 100vh;
    .back-link-box {
      margin-bottom: 15px;
      a {
        @include flex();
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        @include font(15);
        color: #c0321f;
        @include transition();
        svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          path {
            fill: none;
          }
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .detail-text-box {
      padding: 45px 0 25px;
      @include font(15);
      color: rgba(#3e3e3e, 0.5%);
      .logo-box {
        text-align: center;
        margin: 0 auto 60px;
      }
      h1 {
        @include font(18);
        font-weight: 600;
        color: rgba(#3e3e3e, 0.7%);
        margin-bottom: 10px;
      }
      p {
        line-height: 1.5;
        color: #3e3e3e;
      }
    }
    .bottom-text-box {
      @include font(15);
      color: rgba(#3e3e3e, 0.5%);
      text-align: center;
      a {
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        color: #c0321f;
        @include transition();
        &:hover {
          color: #1cb79e;
        }
      }
    }
    .form-box {
      margin-bottom: 60px;
      .form-group {
        margin-bottom: 15px;
        input {
          @include font(14);
          font-weight: 300;
          color: #3e3e3e;
          width: 100%;
          border: 1px solid #3e3e3e;
          @include radius(4px);
          height: 45px;
          padding: 0 1em;
          box-shadow: none;
          &::placeholder {
            color: #3e3e3e;
          }
        }
        &:last-child {
          margin-top: 25px;
        }
        #password {
          border: none;
          border-radius: 4px 0 0 4px;
        }
      }
      .btn {
        @include font(15);
        color: rgba(#ffffff, 0.7%);
        padding: 10px 20px;
        display: block;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .forgot-pass-sec {
    .forgot-box {
      .detail-text-box {
        padding-top: 35px;
      }
      .form-box {
        margin-bottom: 25px;
      }
    }
  }
}
@media screen and (max-width: 639px) {
  .forgot-pass-sec {
    height: auto;
    .container {
      padding-left: 0;
      padding-right: 0;
    }
    .forgot-box {
      padding-left: 20px;
      padding-right: 20px;
      height: auto;
      .detail-text-box {
        padding-top: 20px;
        .logo-box {
          margin-bottom: 30px;
        }
      }
    }
  }
}
