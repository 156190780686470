@import "../../../../assets/css/mixin";
@import "../../../../assets/css/reset";
@import "../../../../assets/css/variables";


html {
  scroll-behavior: smooth;
}
.fakeInput {
  position: absolute !important;
  top: 0;
  left: -999px;
  width: 20px;
  opacity: 0;
}
.MuiDialog-paperWidthSm {
  max-width: 900px !important;
}
.about-sec-wrap {
  @include flex();
  justify-content: space-between;
  .sidebar {
    width: 200px;
    padding-top: 1.2em;
    position: -webkit-sticky;
    max-height: 640px;
    position: sticky;
    top: 110px;

    &.statsSidebar{
      max-height: 1200px;
      .menu-links{
        a{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
          transform: rotate(0deg);
          svg {
            width: 13px;
            height: 13px;
            cursor: pointer;
          }
          &.collapsed, &.active {
            transform: rotate(180deg);
            svg {
              transform: rotate(180deg);
            }
          }
        }
        &.cursorClass{
          cursor: pointer;
        }
        }
      }

    }
    &.aboutsidebar-show-mobile,
    &.statsSidebar-show-mobile {
      display: none;
    }
    &.statsSidebar-hide-mobile{
      max-height: 1200px;
      .menu-links{
        a{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .icon {
            transform: rotate(0deg);
            svg {
              width: 13px;
              height: 13px;
              cursor: pointer;
            }
            &.collapsed, &.active {
              transform: rotate(180deg);
              svg {
                transform: rotate(180deg);
              }
            }
          }
          &.cursorClass{
            cursor: pointer;
          }
        }
      }
    }
    .menu-links {
      display: block;
      li {
        display: block;
        margin: 0 -16px;
        .MuiTreeItem-content {
          display: block;
          .MuiTreeItem-label {
            padding-left: 0;
            a {
              .MuiListItemText-root {
                margin: 0;
                .icon {
                  margin-left: 10px;
                }
              }
              .MuiTypography-body1 {
                @include font(14);
                letter-spacing: normal;
              }
            }
          }
        }
        a {
          display: block;
          text-decoration: none;
          padding: 15px 20px;
          @include font(14);
          color: $blackColor;
          font-weight: 400;
          @include transition();
          position: relative;
          &:hover, &.active {
            background: rgba($gray, 0.1%);
            color: $green;
            &::after {
              content:'';
              background: $green;
              position: absolute;
              width: 4px;
              height: 25px;
              left: 0;
              top: 10px;
              @include radius(10px);
            }
          }
        }
        .MuiTreeItem-group {
          margin: 0;
          li {
            margin: 0;
            .MuiTreeItem-group {
              li {
                a {
                  padding-left: 45px;
                }
              }
            }
            a {
              padding-left: 35px;
            }
          }
        }
      }
    }
  }
  .about-detail-box {
    width: calc(100% - 200px);
    padding-top: 1.2em;
    padding-left: 1em;
    margin-left: 1em;
    padding-bottom:  1.2em;
    border-left: 1px solid #ccc;
    .common-item-box {
      margin-bottom: 20px;
      .title {
        background: rgba($gray, 0.1%);
        padding: 0.7em 0.8em;
        @include font(15);
        font-weight: 500;
        color: $green;
        border-left: 3px solid $green;
        margin-bottom: 20px;
      }
      .content-box {
        @include font(14);
        font-weight: 400;
        color: $blackColor;
        line-height: 1.6;
        p {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          strong {
            color: #9B9B9B;
            font-weight: 400;
          }
          a {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            color: $black;
            overflow-wrap: anywhere;
            &:hover {
              color: $baseColor;
            }
          }
        }
        .data-info-item {
          @include flex();
          flex-wrap: wrap;
          margin: 0 -5px;
          border-bottom: 1px solid #ccc;
          margin-top: 10px;
          li {
            margin-bottom: 15px;
            position: relative;
            width: calc(33.333% - 5px);
            padding: 0 5px;
            &.w-100 {
              span {
                &:first-child {
                  font-weight: 500;
                }
              }
            }
            span {
              display: block;
              margin-bottom: 8px;
              word-break: break-word;
              &:first-child {
                color: #9B9B9B;
              }
              &.black-text {
                color: $blackColor;
              }
              &.link-text {
                color:$lightBlue !important;
                text-decoration: underline;
                cursor: pointer;
              }
              &:last-child {
                margin-bottom: 0;
              }
              &.profile-img {
                span {
                  @include flex();
                  align-items: center;
                  justify-content: center;
                  border: 1px solid $border;
                  width: 90px;
                  height: 90px;
                  @include radius(50%);
                  color: $black;
                  text-transform: uppercase;
                }
                img {
                  width: 90px;
                  height: 90px;
                  object-fit: cover;
                  @include radius(50%);
                }
              }
              input {
                width: 20px;
                height: 20px;
                border: 1px solid $gray;
                @include radius(4px);
              }
              &.edit-profile-link {
                .edit-link {
                  display: inline-block;
                  vertical-align: middle;
                  text-decoration: none;
                  @include font(14);
                  font-weight: 500;
                  color: $baseColor;
                  text-transform: none;
                  position: absolute;
                  right: 0;
                  top: 0;
                }
              }
              &.name {
                color: $baseColor;
              }
              &.address-link {
                position: relative;
                padding-left: 25px;
                .pin-icon {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 0;
                  position: absolute;
                  left: 0;
                  top:4px;
                  z-index:1;
                }
              }
            }
          }
          &:last-child {
            margin-bottom: 10px;
            border: none;
          }
          .pin-icon {
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle;
            path {
              fill: $green;
            }
          }
          + h3 {
            margin-top: 30px;
            @include font(14);
            color:#9B9B9B;
            font-weight: 400;
            text-transform: none;
          }
          &.team-list {
            @include flex();
            justify-content: space-between;
            li {
              @include flex();
              margin-bottom: 15px;
              position: relative;
              width: calc(50% - 10px);
              span {
                display: inline-block;
                vertical-align: middle;
              }
            }
            &.circle {
              li {
                align-items: flex-start;
              }
              .img-box {
                height: 70px;
                @include radius(50%);
                overflow: hidden;
                img {
                  width: 70px;
                  height: 70px;
                  object-fit: cover;
                }
              }
            }
            &.event {
              display: block;
              > li {
                width: 100%;
                padding-bottom: 15px;
                border-bottom: 1px solid $gray;
                &:last-child {
                  padding-bottom: 0;
                  border-bottom: none;
                }
              }
              .img-box {
                text-align: center;
                img {
                  width: auto;
                  height: auto;
                }
              }
              .detail-box {
                width: 100%;
                li {
                  width: 100%;
                  margin-bottom: 2px;
                  padding: 0;
                  span {
                    &:first-child {
                      width: 240px;
                    }
                    em {
                      margin-left: 10px;
                      font-style: normal;
                    }
                  }
                }
              }
              h5 {
                position: relative;
                padding-right: 30px;
                margin-bottom: 5px !important;
                .icon {
                  position: absolute;
                  right: 0;
                  top: -2px;
                  width: auto;
                  margin-right: 0;
                  svg {
                    .a {
                      fill: $green;
                    }
                    .b {
                      fill: none;
                      stroke: $white;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-width: 4px;
                    }
                  }
                }
              }
            }
            .img-box {
              flex: 0 0 70px;
              width: 70px;
              margin-right: 15px;
              img {
                width: 70px;
                max-width: 100%;
              }
            }
            .detail-box {
              @include font(14);
              width: 100%;
              color: $black;
              p {
                &.reference-item {
                  margin-bottom: 0;
                }
              }
              .reference-item {
                @include flex();
                width: 100%;
                strong {
                  width: 105px;
                }
                span {
                  width: calc(100% - 105px);
                }
              }
              .referenceName{
                display: inline;
                font-size: 15px;
                font-weight: 400;
              }
              h5 {
                @include font(15);
                font-weight: 500;
                color: $blackColor;
                margin-bottom: 2px;
                position: relative;
                padding-right: 30px;
                .edit-link {
                  display: inline-block;
                  vertical-align: middle;
                  text-decoration: none;
                  @include font(14);
                  font-weight: 500;
                  color: $baseColor;
                  text-transform: none;
                  position: absolute;
                  right: 0;
                  top: 0;
                }
              }
              .name {
                color: $baseColor;
              }
            }
            + .detail-box {
              width: calc(100% - 70px) !important;
            }
          }
          &.fourColumn {
            justify-content: space-between;
            li {
              width: calc(29.333% - 5px);
              &:nth-child(4n+4) {
                width:12%;
                text-align: right
              }
              span {
                :first-child {
                  width: auto!important;
                }
              }
            }
          }
        }
        h3 {
          @include font(15);
          font-weight: 600;
          color: $blackColor;
          margin-bottom: 15px;
          text-transform: uppercase;
        }
        .edit-link-box {
          @include flex();
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 18px;
          .edit-link {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            @include font(14);
            font-weight: 500;
            color: $baseColor;
            text-transform: none;
          }
        }
        h4 {
          @include font(14);
          color: $blackColor;
          margin-bottom: 20px;
          text-transform: uppercase;
          position: relative;
          padding-right: 38px;
          .edit-link {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            @include font(14);
            font-weight: 500;
            color: $baseColor;
            text-transform: none;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        .box-item {
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 2px solid #f8f8f8;
          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
        .scores-listing {
          overflow: hidden;
          li {
            float: left;
            width: 31.33%;
            margin-right: 2%;
            padding: 10px;
            border: 1px solid $gray;
            @include radius(4px);
            margin-bottom: 15px;
            min-height: 300px;
            &:nth-child(3n + 3) {
              margin-right: 0;
            }
            &:nth-child(3n + 4) {
              clear: both;
            }
            h5 {
              @include font(15);
              font-weight: 600;
              color: $black;
              text-transform: uppercase;
              margin-bottom: 10px;
            }
            .circle-box {
              position: relative;
              margin-bottom: 20px;
              svg {
                .mainline {
                  stroke: 4px;
                  stroke-linecap: round;
                }
                .CircularProgressbar-text {
                  @include font(14);
                  fill:  $black;
                  width: 50px;
                  margin: 0 auto;
                  font-weight: 700;
                }
              }
              .inner-box {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                @include font(12);
                font-weight: 600;
                color: rgba($black, 0.6);
                text-transform: uppercase;
                text-align: center;
                margin: 0 auto;
              }
              h6 {
                @include font(16);
                font-weight: 600;
                color: $blackColor;
                margin-bottom: 2px;
                span {
                  color: rgba($black, 0.6);
                }
              }
            }
            .botom-detail {
              @include flex();
              justify-content: space-between;
              @include font(11);
              font-weight: 600;
              color: rgba($black, 0.6);
              text-transform: uppercase;
              .item-box {
                margin-right: 5px;
                &:last-child {
                  margin-right: 0;
                }
                h6 {
                  @include font(13);
                  font-weight: 600;
                  color: $blackColor;
                  span {
                    color: rgba($black, 0.6);
                  }
                }
              }
            }
          }
        }
        .edit-video-link {
          position: relative;
          .edit-link {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            @include font(14);
            font-weight: 500;
            color: $baseColor;
            text-transform: none;
            position: absolute;
            top: -5px;
            right: 0;
          }
        }
        .video-grid-list {
          li {
            width: 48%;
            background: $white;
            border: 1px solid $gray;
            box-shadow: 0 3px 6px #00000017;
            @include radius(5px);
            position: relative;
            overflow: hidden;
            margin-bottom: 30px;
            cursor: pointer;
            .img-box {
              position: relative;
              height: 200px;
              video {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: relative;
              }
              .video-play-button {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;
                .video-play-inner {
                  @include flex();
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                  video{
                    max-width: 100%;
                    height: 150px;
                  }
                }
                button {
                  @include flex();
                  justify-content: center;
                  align-items: center;
                  background: $yellow;
                  width: 40px;
                  height: 40px;
                  @include radius(50%);
                  outline: none;
                  border: none;
                  svg {
                    a {
                      fill: $white;
                    }
                  }
                }
              }
            }
            .video-detail-box {
              padding: 15px 10px;
              .title-box {
                @include flex();
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                .video-title {
                  @include font(13);
                  font-weight: 600;
                  color: $blackColor;
                }
                .share-video-button {
                  @include flex();
                  justify-content: center;
                  align-items: center;
                  width: 24px;
                  height: 24px;
                  border: 1px solid $black;
                  @include radius(50%);
                }
              }
              .video-info {
                line-height: 1;
                span {
                  @include font(11);
                  display: inline-flex;
                  align-items: center;
                  vertical-align: middle;
                  margin-right: 5px;
                  padding-right: 8px;
                  border-right: 1px solid $gray;
                  &:last-child {
                    padding-right: 0;
                    margin-right: 0;
                    border-right: 0;
                  }
                  svg {
                    margin-right: 5px;
                    path {
                      fill: $yellow;
                    }
                    .a {
                      fill: $orange;
                    }
                  }
                }
              }
            }
          }
        }
        .buton-box {
          .btn {
            &.outlined {
              &.blue {
                border-color: $lightBlue;
                color:$lightBlue;
                &:hover {
                  background: $lightBlue;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .stats-box-item{
      .tab-content{
        &.active{
          display: block;
        }
      }
    }
  }
}
.athleteProfileFinalDarkSection {
  margin-top: 60px;
  background-color: $bgLightGray;
  padding: 15px 0;
  .topSection {
    background-image: url(https://cdn.sportsforce.io/Groundbg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    .coachProfileFlexBox {
      @include flex();
      flex-wrap: wrap;
      padding: 35px 30px;
      margin-top: -15px;
      align-items: flex-start;
      .carousel {
        background-color: $white;
        width: 190px;
        height: 220px;
        margin-right: 15px;
        @include radius(4px);
        @include shadow(0, 3px, 6px, #00000029);
        overflow: hidden;
        .owl-carousel {
          position: relative;
          .owl-stage-outer {
            height: 220px;
            .owl-stage {
              height: 100%;
              .owl-item {
                height: 100%;
                .item {
                  height: 100%;
                  img {
                    max-width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  .profile-inner-box {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    height: 220px;
                    img {
                      text-align: center;
                    }
                  }
                  .initial {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    @include font(40);
                    font-weight: 700;
                  }
                }
              }
            }
          }
          .owl-nav {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            z-index: 1;
            width: 100%;
            @include flex();
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            &.disabled {
              display: none;
            }
            button {
              font-family: monospace;
              outline: none;
              &.owl-prev {
                background: rgba(0, 0, 0, 0.5);
                @include flex();
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                @include radius(50%);
                @include font(20);
                color: $white;
                span {
                  display: inline-flex;
                }
              }
              &.owl-next {
                @include flex();
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.5);
                width: 20px;
                height: 20px;
                @include font(20);
                color: $white;
                @include radius(50%);
                span {
                  display: inline-flex;
                }
              }
            }
          }
        }
      }
      .coachInfo {
        padding-right: 10px;
        width: calc(100% - 1005px);
        .videoUrl{
          .featuredVideo{
            width: 46px;
            margin-top: 10px;
          }
        }
        .pName {
          @include flex();
          align-items: center;
          @include font(20);
          color: $white;
          font-weight: 300;
          margin: 0 0 10px 0;
          span {
            display: inline-flex;
            &.icon {
              margin-left: 10px;
              // svg {
              //   .a {
              //     fill: $lightBlue;
              //   }
              // }
              svg.verified {
                .a {
                  fill: $lightBlue !important;
                }
                .b {
                  fill: $white !important;
                }
              }
              svg.unverified {
                .a {
                  fill: #ff4a31 !important;
                }
                .b {
                  fill: $white !important;
                }
              }
            }
          }
        }
        .designation {
          display: inline-flex;
          background-color: $yellow;
          //padding: 6px 18px;/*client change*/
          @include font(14);
          color: $white;
          margin: 0 0 10px 0;
          @include radius(4px);
        }
        p {
          &.info {
            @include font(14);
            color: $white;
            margin: 0 0 10px 0;
            line-height: 22px;
          }
          &.email {
            @include font(14);
            color: $white;
            margin: 0 0 10px 0;
            line-height: 22px;
          }
          &.social {
            display: inline-flex;
            align-items: center;
            @include font(16);
            color: $white;
            padding: 5px 10px;
            margin: 0 0 10px 0;
            span {
              display: inline-flex;
              &.instagramIcon{
                width: 22px;
                height: auto;
              }
              &.icon {
                margin-right: 10px;
                svg {
                  width: 22px;
                  height: auto;
                  .a {
                    fill: #34c7ff;
                  }
                }
              }
            }
          }
          &:empty {
            display: none
          }
        }
        .social-links {
          @include flex();
          align-items: center;
          .social-item {
            margin: 0 12px 10px 0;
            &:last-child {
              margin-right: 0;
            }
            a {
              display: inline-block;
              vertical-align: middle;
              svg {
                width: 35px;
                height: 35px;
              }
            }
          }
        }
        ul {
          margin-top: 10px;
          padding: 0;
          @include flex();
          flex-wrap: wrap;
          li {
            @include flex();
            align-items: center;
            list-style: none;
            padding-right: 10px;
            margin-right: 10px;
            border-right: 1px solid $white;
            margin-bottom: 5px;
            @include font(14);
            color: $white;
            &:last-child {
              padding-right: 0;
              margin-right: 0;
              border-right: none;
            }
            &.likes {
              span {
                display: inline-flex;
                font-weight: 600;
                &.icon {
                  margin-right: 10px;
                  svg {
                    .a {
                      fill: $white;
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &.follower {
              span {
                font-weight: 600;
                &.label {
                  font-weight: normal;
                  margin-left: 5px;
                }
              }
            }
            &.following {
              span {
                font-weight: 600;
                &.label {
                  margin-left: 5px;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
      .video-with-infobox {
        @include flex();
        width: 800px;
        padding-left: 20px;
        .video-profilebox {
          width: 250px;
          margin-right: 25px;
          .video-thum {
            position: relative;
            border: 4px solid $white;
            @include radius(5px);
            height: 160px;
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            video{
              max-width: 100%;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .video-play-button {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              z-index: 1;
              cursor: pointer;
              .video-play-inner {
                @include flex();
                align-items: center;
                justify-content: center;
                height: 100%;
              }
              .featuredVideo {
                @include flex();
                justify-content: center;
                align-items: center;
                background: $baseColor;
                width: 45px;
                height: 45px;
                @include radius(50px);
                outline: none;
                border: none;
                svg {
                  path {
                    fill: $white;
                  }
                }
              }
            }
          }
          .btn {
            &.add-video-btn {
              @include flex();
              align-items: center;
              background: $lightBlue;
              padding:10px 20px;
              float: right;
              .icon {
                display: inline-block;
                vertical-align: middle;
                margin-right:6px;
                svg {
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
      .infoBoxFlex {
        width: calc(100% - 275px);
        @include flex();
        flex-wrap: wrap;
        .item {
          padding-top: 22px;
          padding-bottom: 22px;
		      position: relative;
          margin-right: 10px;
          @include radius(5px);
          border: 1px solid #ddd;
          background-color: $white;
          width: calc(25% - 10px);
          margin-bottom: 10px;
          @include flex();
          align-items: flex-start;
          justify-content: center;
          // flex-direction: column;
          height: 110px;
          background-color: $white;
          span {
            display: block;
            text-align: center;
            @include font(22);
            color: $blackColor;
            font-weight: 700;
            &.label {
              margin-bottom: 15px;
              @include font(14);
              font-weight: normal;
              color: $black;
            }
          }
          &.gpaScore {
            span {
              &.label {
                color: $black;
                /* margin-bottom: 10px; */
              }
              &.infoIcon {
                position: absolute;
                right: 10px;
                bottom: 5px;
                z-index: 1;
                svg {
                  .a {
                    opacity: 0.5;
                    fill: $black;
                  }
                }
              }
              &.lockIcon {
                svg {
                  width: 20px;
                  height: auto;
                  .a {
                    opacity: 0.5;
                    fill: $black;
                    fill-rule: evenodd;
                  }
                }
              }
            }
          }
          &.satAct {
            span {
              color: $blackColor;
              &.label {
                color: $black;
                margin-bottom: 15px;
              }
            }
          }
          &.rating {
            span {
              &.label {
                color: rgba(62, 62, 62, 0.5);
                margin-bottom: 15px;
              }
              &.infoIcon {
                position: absolute;
                right: 10px;
                bottom: 5px;
                z-index: 1;
                svg {
                  .a {
                    opacity: 0.5;
                    fill: $black;
                  }
                }
              }
              &.lockIcon {
                svg {
                  width: 18px;
                  height: auto;
                  .a {
                    fill: #ccc;
                    fill-rule: evenodd;
                  }
                }
              }
            }
          }
          &.commmitBox {
            display: block;
            background-color: $white;
            border: none;
            padding-top: 0;
            overflow: hidden;
            span {
              font-weight: normal;
              margin-bottom:0;
              margin-top: 5px;
              @include font(11);
              padding: 0 5px;
              color: $black;
              border: 2px solid $white;
              border-radius: 5px 5px 0 0!important;
              &.label {
                background: $black;
                padding: 8px 5px;
                @include font(12);
                font-weight: 600;
                color: $white;
                text-transform: uppercase;
                margin-bottom: 2px;
                margin-top: 0;
              }
              &.imgBox {
                width: 40px;
                height: 30px;
                margin: 0 auto;
                text-align: center;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  height: auto;
                }
              }
            }
            &.uncommit{
              span {
                &.label {
                  background: none;
                  @include font(15);
                  color: $black;
                  padding: 10px 5px 8px;
                }
              }
              a {
                text-decoration: underline;
                cursor: pointer;
                color: $baseColor;
                 span {
                  color: $baseColor;
                 }
              }
            }
            .tell-us-btn {
              display: block;
              padding: 5px 10px;
              @include font(14);
              width: 90%;
              max-width: 100%;
              text-transform: uppercase;
              margin: 5px auto 0;
            }
          }
          &:nth-child(3) {
            span {
              color: $black;
            }
          }
          &:nth-child(5) {
            span {
              color: $black;
            }
          }
          &:nth-child(4n+4) {
            margin-right: 0;
          }
        }
        .showhideBox {
          display: none;
          width: 100%;
          text-align: center;
          a {
            display: inline-flex;
            align-items: center;
            padding: 5px 10px;
            background: $white;
            color: $baseColor;
            @include radius(4px);
            text-decoration: none;
            position: relative;
            cursor: pointer;
            &::before {
              content: "Show more";
              display: inline-block;
              @include font(14);
              color: $baseColor;
            }
            span {
              &.icon {
                display: inline-block;
                margin-left: 10px;
                vertical-align: middle;
                svg {
                  display: inline-block;
                  vertical-align: middle;
                  .a {
                    fill: $baseColor;
                  }
                }
              }
            }
          }
        }
        &.hide {
          .item {
            display: none;
            &:nth-child(1) {
              @include flex();
            }
            &:nth-child(2) {
              @include flex();
            }
          }
          .showhideBox {
            a {
              &::before {
                content: "Show less";
                color: $baseColor;
              }
              span {
                &.icon {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
      .actionBox {
        margin-top: 15px;
        @include flex();
        justify-content: center;
        align-items: center;
        position: relative;
        width: 190px;
        button {
          outline: none;
          border: none;
          box-shadow: none;
          &.followBtn {
            width: 45px;
            height: 40px;
            @include radius(4px);
            padding: 10px;
            background-color: $yellow;
            @include font(15);
            font-weight: 600;
            border: 1px solid #000000;
            color: #000000;
            @include flex();
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            span {
              display: inline-flex;
              &.icon {
                svg {
                  .a {
                    fill: $white;
                    opacity: 1;
                  }
                }
              }
            }
          }
          &.privacyBtn {
            width: 45px;
            height: 40px;
            @include radius(4px);
            padding: 10px;
            background-color: #c0321f;
            @include font(15);
            font-weight: 600;
            border: 1px solid #000000;
            color: #000000;
            @include flex();
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            span {
              display: inline-flex;
              &.icon {
                svg {
                  .a {
                    fill: $white;
                    opacity: 1;
                  }
                }
              }
            }
          }
          &.promoteBtn {
            width: 45px;
            height: 40px;
            @include radius(4px);
            padding: 10px;
            background-color: $green;
            font-weight: 600;
            @include font(15);
            color: $white;
            @include flex();
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            span {
              display: inline-flex;
              &.icon {
                svg {
                  .a {
                    fill: $white;
                    opacity: 1;
                  }
                }
              }
            }
          }
          &.menuBtn {
            width: 40px;
            height: 40px;
            @include radius(4px);
            padding: 10px;
            background-color: $lightBlue;
            @include font(14);
            color: $black;
            @include flex();
            align-items: center;
            margin-right: 0;
            justify-content: center;
            span {
              display: inline-flex;
              &.icon {
                svg {
                  .a {
                    fill: $black;
                    opacity: 1;
                  }
                }
              }
            }
          }
          &.claim-profile-btn {
            @include flex();
            align-items: center;
            justify-content: center;
            background-color: $lightBlue;
            padding: 10px;
            @include font(14);
            color: $white;
            min-height: 40px;
            @include radius(4px);
            span {
              display: inline-flex;
              &.icon {
                margin-right:5px;
                svg {
                  width:16px;
                  height:16px;
                  path {
                    fill:$white;
                  }
                }
              }
            }
          }
        }
        .dropdown-menu {
          min-width: 220px;
          transform: none !important;
          top: 45px !important;
          @include shadow(0, 3px, 13px, #30303042);
          border: none;
          a {
            padding: 5px 10px;
            cursor: pointer;
            span {
              display: inline-block;
              @include font(14);
              color: $black;
              &.icon {
                margin-right: 10px;
                svg {
                  width: 13px;
                  height: auto;
                  .a {
                    fill: $black;
                  }
                }
              }
            }
          }
        }
      }
      .metricCarouselBox {
        width: calc(100% - 690px);
        margin-left: 25px;
        margin-top: 8px;
        @include flex();
        align-items: center;
        margin-left: auto;
        &:empty {
          display: none;
        }
        .title {
          @include font(16);
          color: $white;
          margin-right: 20px;
          font-weight: 600;
        }
        .owl-carousel {
          padding: 0 30px;
          position: relative;
          width: calc(100% - 80px);
          .owl-stage-outer {
            position: relative;
            z-index: 2;
            .owl-stage {
              .owl-item {
                text-align: -webkit-center;//this is class"owl-item" added as per changes june 2022
                .item {
                  min-height: 30px;
                  margin-top: 5px;
                  width: 100%;//this is added as per changes june 2022

                  > div {
                    width: 100%;
                    min-height: 42px;
                    min-width: 144px;
                    justify-content: space-between;
                  }

                  .velocityMax {
                    display: inline-flex;
                    align-items: center;
                    padding: 3px 10px;
                    background-color: $white;
                    @include radius(20px);

                    span {
                      display: inline-block;
                      @include font(16);
                      color: $black;
                      font-weight: 600;

                      &.label {
                        @include font(12);
                        font-weight: normal;
                        margin-left: 10px;
                        max-width: 50px;
                        text-align: center;
                      }
                    }
                  }

                  .chValueBox {
                    display: inline-flex;
                    align-items: center;
                    padding: 3px 10px;
                    justify-content: center;
                    background-color: $white;
                    @include radius(20px);

                    .iconBox {
                      margin-right: 10px;
                      @include flex();
                      align-items: center;

                      span {
                        display: inline-block;
                        font-weight: 900;
                        font-size: 26px;
                        color: #5c5c5c;

                        &.icon {
                          margin-right: 5px;

                          svg {
                            width: 15px;
                            height: auto;

                            .a {
                              fill: none;
                              stroke: $baseColor;
                              stroke-linecap: round;
                              stroke-linejoin: round;
                              stroke-width: 1.5px;
                            }
                          }
                        }
                      }
                    }

                    .label {
                      @include font(12);
                      color: $black;
                      font-weight: normal;
                      margin-left: 10px;
                      max-width: 40px;
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
          .owl-nav {
            font-family: monospace;
            position: absolute;
            z-index: 1;
            left: 0;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            @include flex();
            align-items: center;
            justify-content: space-between;
            // &.disabled {
            //   display: none;
            // }
            button {
              outline: none;
              &.owl-prev {
                span {
                  display: inline-flex;
                  @include font(40);
                  color: $white;
                }
              }
              &.owl-next {
                span {
                  display: inline-flex;
                  @include font(40);
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
  .bottomSection {
    padding: 20px 0px;
    .container {
      .checkListBox {
        max-width: 900px;
        margin: 0 auto 15px auto;
        background-color: $white;
        border: 1px solid $gray;
        @include radius(4px);
        @include flex();
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        .progressbar {
          background: rgba($green, 0.2%);
          height: 24px;
          position: relative;
          overflow: hidden;
          width: 50%;
          margin: 0 auto;
          cursor: pointer;
          @include radius(20px);
          .progress {
            background: $green;
            @include flex();
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 65%;
            height: 100%;
            left: 0;
            top: 0;
            @include font(14);
            font-weight: 800;
            color: $white;
            @include radius(20px);
          }
          &.small {
            width: 46px;
            height: 10px;
          }
        }
        .checkListNotify {
          a {
            text-decoration: none;
            display: flex;
            align-items: center;
            @include font(16);
            color: $black;
            padding: 5px;
            span {
              display: inline-block;
              margin-right: 10px;
              &.icon {
                margin-right: 10px;
                position: relative;
                svg {
                  width: auto;/*client change*/
                  height: auto;
                  .a {
                    fill: $baseColor;
                  }
                }
                .count {
                  width: 18px;
                  height: 18px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  background-color:$baseColor;
                  font-size: 12px;
                  color: $white;
                  position: absolute;
                  right: 0;
                  top: -10px;/*client change*/
                  @include radius(50px);/*client change*/
                }
              }
            }
          }
        }
        p {
          &.info {
            @include font(14);
            color: $black;
            text-align: center;
            width: calc(100% - 253px);
            padding: 0 10px;
            line-height: 18px;
          }
        }
        button {
          &.viewCheckBtn {
            @include radius(30px);
            border: 1px solid $black;
            outline: none;
            box-shadow: none;
            background-color: transparent;
            padding: 10px 15px;
            @include font(14);
            color: $black;
          }
        }
      }
      .tabBox {
        @include radius(4px);
        background-color: $white;
        border: 1px solid #00000038;
        padding: 0 30px;
        max-width: 900px;
        margin: 0 auto 15px auto;
        position: sticky;
        top: 60px;
        z-index: 99;
        &.profileTab-show-mobile {
          display: none;
          nav {
            ul {
              margin: 0;
              padding: 0;
              @include flex();
              align-items: center;
              justify-content: center;
              li {
                list-style: none;
                margin-right: 60px;
                a {
                  padding: 15px 10px;
                  @include font(18);
                  color: $black;
                  display: inline-block;
                  text-decoration: none;
                  position: relative;
                  &:hover {
                    color: $baseColor;
                  }
                }
                &.selected {
                  a {
                    color: $baseColor;
                    &::after {
                      content: "";
                      display: inline-block;
                      position: absolute;
                      bottom: -1px;
                      left: 0;
                      width: 100%;
                      height: 1px;
                      background-color: $baseColor;
                    }
                  }
                }
                &:last-child {
                  margin-right: 0;
                }
                &.reportLink {
                  a {
                    span {
                      &.icon {
                        display: inline-block;
                        margin-left: 5px;
                        svg {
                          .a {
                            fill: $black;
                            fill-rule: evenodd;
                          }
                        }
                      }
                    }
                    &:hover {
                      span {
                        &.icon {
                          .a {
                            fill: $baseColor;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        nav {
          ul {
            margin: 0;
            padding: 0;
            @include flex();
            align-items: center;
            justify-content: center;
            li {
              list-style: none;
              margin-right: 60px;
              a {
                padding: 15px 10px;
                @include font(18);
                color: $black;
                display: inline-block;
                text-decoration: none;
                position: relative;
                &:hover {
                  color: $baseColor;
                }
              }
              &.selected {
                a {
                  color: $baseColor;
                  &::after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $baseColor;
                  }
                }
              }
              &.closeBtnBox {
                display: none;
                text-align: right;

                button {
                  padding: 5px;
                  outline: none;
                  background-color: transparent;
                  border: none;
                  cursor: pointer;
                  svg {
                    .a {
                      opacity: 0.7;
                      fill: $black;
                    }
                  }
                }
              }
              &:last-child {
                margin-right: 0;
              }
              &.reportLink {
                a {
                  span {
                    &.icon {
                      display: inline-block;
                      margin-left: 5px;
                      svg {
                        .a {
                          fill: $black;
                          fill-rule: evenodd;
                        }
                      }
                    }
                  }
                  &:hover {
                    span {
                      &.icon {
                        .a {
                          fill: $baseColor;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .flexBox {
          display: none;
          div {
            &.menuBtnBox {
              margin-right: 10px;
              width: auto;
              display: block;
              .tabMenuBtn {
                border: none;
                padding: 5px;
                background: transparent;
                outline: none;
                cursor: pointer;
                svg {
                  .a {
                    fill: #343434;
                  }
                }
              }
            }
            width: calc(100% - 36px);
            @include flex();
            align-items: center;
            p {
              @include font(15);
              color: $baseColor;
            }
          }
        }
        &.tabBoxshow {
          z-index: 999;
        }
      }
      .TimelineData {
        max-width: 900px;
        margin: 0 auto;
        &.video-tab-data {
          .item {
            padding-top: 0;
            padding-bottom: 0;
            .video-and-photo-wrap {
              .video-and-photo-main {
                border-top: 0;
                .sidebarbox {
                  .category-list {
                    .item {
                      padding: 1em 0;
                      border-radius: 0;
                      border-left: 0;
                      border-right: 0;
                      border-bottom: 0;
                      margin-bottom: 0;
                      .category-toggle-data {
                        padding-left: 22px;
                        padding-top: 10px;
                        display: none;
                        li {
                          padding-left: 14px;
                          position: relative;
                          @include font(12);
                          margin-top: 12px;
                          color: $black;
                          &:after {
                            content: "";
                            position: absolute;
                            width: 5px;
                            height: 5px;
                            background: $black;
                            @include radius(50%);
                            left: 0;
                            top: 3px;
                            z-index: 1;
                          }
                        }
                      }
                      &.active {
                        .category-toggle-data {
                          display: block;
                        }
                      }
                    }
                  }
                }
                .video-second-block {
                  .video-tab-contents {
                    .edit-videolink-box {
                      text-align: right;
                      margin-bottom: 15px;
                      a {
                        display: inline-block;
                        vertical-align: middle;
                        text-decoration: underline;
                        @include font(18);
                        font-weight: 400;
                        color: $blackColor;
                        &:hover {
                          color: $baseColor;
                        }
                      }
                    }
                    .video-grid-list {
                      .video-box {
                        overflow: hidden;
                        .video-detail-box {
                          .video-info {
                            margin-bottom: 0;
                          }
                        }
                        .img-box {
                          .info-icon {
                            li {
                              &.delete-button {
                                path {
                                  fill: $baseColor;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .item {
          padding: 15px;
          background-color: $white;
          border: 1px solid $gray;
          @include radius(4px);
          margin-bottom: 15px;
          .headFlex {
            @include flex();
            align-items: flex-start;
            margin-bottom: 15px;
            .imgBox {
              width: 34px;
              height: 40px;
              @include radius(2px);
              margin-right: 15px;
              overflow: hidden;
              img {
                max-width: 100%;
                height: auto;
              }
            }
            .info {
              width: calc(100% - 165px);
              padding-right: 10px;
              .name {
                @include font(16);
                color: $blackColor;
                font-weight: 600;
                margin: 0 0 5px 0;
              }
              span {
                display: block;
                @include font(14);
                line-height: 18px;
                color: rgba(62, 62, 62, 0.7);
              }
            }
            .time {
              width: 115px;
              @include flex();
              align-items: center;
              justify-content: space-between;
              span {
                display: inline-block;
                @include font(14);
                color: $black;
                color: rgba(62, 62, 62, 0.7);
                text-align: right;
                &.pinIcon {
                  margin-left: 5px;
                  a {
                    text-decoration: none;
                    cursor: pointer;
                    padding: 3px;
                    svg {
                      .a {
                        fill: $baseColor;
                      }
                    }
                  }
                }
                &.dotMenu {
                  margin-left: 5px;
                  a {
                    text-decoration: none;
                    cursor: pointer;
                    padding: 3px;
                    svg {
                      .a {
                        fill: $black;
                      }
                    }
                  }
                }
              }
            }
          }
          .flexItem {
            @include flex();
            align-items: flex-start;
            padding-bottom: 15px;
            border-bottom: 1px solid $gray;
            margin-bottom: 15px;
            .imgBox {
              border: 1px solid $gray;
              @include radius(4px);
              width: 120px;
              height: 120px;
              margin-right: 15px;
              @include flex();
              align-items: center;
              justify-content: center;
              img {
                max-width: 100%;
                height: auto;
              }
            }
            .info {
              width: calc(100% - 135px);
              max-width: 650px;
              h5 {
                @include font(16);
                color: $blackColor;
                font-weight: 600;
                line-height: 22px;
                margin: 0 0 5px 0;
              }
              span {
                display: block;
                @include font(14);
                color: rgba(62, 62, 62, 0.7);
                line-height: 18px;
              }
              .videoBox {
                margin-top: 15px;
                // height: 350px;
                img {
                  max-width: 100%;
                  width: 100%;
                  height: auto;
                  max-height: 350px;
                }
              }
            }
            &:last-child {
              padding-bottom: 0;
              border: none;
              margin-bottom: 0;
            }
          }
        }
      }
      .public-setting-wrapper {
        .TimelineData {
          .item {
            .athleteDashSection {
              background: none;
              padding: 0;
              > .container {
                padding: 0;
                > .flexBox {
                  .dataBox {
                    margin: 0;
                  }
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .report-data {
        background: $white;
        max-width: 900px;
        margin:  0 auto;
        padding: 20px;
        border: 1px solid $gray;
        @include radius(4px);
        .report-profile-info {
            .title {
                background: rgba($gray, 0.1%);
                padding: 0.7em 0.8em;
                @include font(15);
                font-weight: 500;
                color: $green;
                border-left: 3px solid $green;
                margin-bottom: 20px;
            }
            .scouting-info-box {
                background: rgba($gray, 0.1%);
                padding: 15px 15px 18px;
                @include font(15);
                font-weight: 400;
                color: $black;
                border: 1px solid rgba($gray, .6);
                @include radius(5px);
                margin-bottom: 20px;
                h4 {
                    @include font(16);
                    font-weight: 400;
                    color: $blackColor;
                    margin-bottom: 10px;
                    span {
                        font-weight: 500;
                    }
                }
                p {
                    margin-bottom: 0;
                }
            }
            .profile-info {
                @include flex();
                align-items: center;
                border-bottom: 1px solid rgba($gray, .5);
                padding-bottom: 20px;
                margin-bottom: 20px;
                .pick-box {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    width: 75px;
                    height: 75px;
                    text-align: center;
                    text-transform: uppercase;
                    border: 1px solid $gray;
                    @include radius(50px);
                    margin-right: 15px;
                    img {
                        display: block;
                        width: 75px;
                        height: 75px;
                        border: 1px solid rgba($gray, .5);
                        @include radius(50px);
                    }
                }
                .detail-box {
                    @include font(15);
                    color: $black;
                    .name {
                        @include font(18);
                        color: $blue;
                        font-weight: 700;
                        margin-bottom:8px;
                        text-transform: capitalize;
                    }
                    p {
                        margin-bottom: 0;
                    }
                }
            }
            h5 {
                @include font(18);
                font-weight: 500;
                color: $blackColor;
                margin-bottom: 15px;
            }
            ul {
                li {
                    @include font(16);
                    font-weight: 400;
                    color: $black;
                    margin-bottom: 22px;
                    strong {
                        font-weight: 500;
                    }
                }
                &.key-type-list {
                    margin-bottom: 25px;
                }
            }
        }
        .report-flexbox {
            padding: 30px 0 40px;
            @include flex();
            .item {
                width: calc(100% - 420px);
                .report-range-item {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .report-diagram {
                width: 300px;
                margin: 0 50px 0 70px;
                .diagram-box {
                    position: relative;
                    box-shadow: 0px 3px 20px #00000017;
                    @include radius(5px);
                    img {
                        max-width: 100%;
                    }
                    .range-point {
                        li {
                            position: absolute;
                            @include font(15);
                            font-weight: 500;
                            color: $black;
                            margin-bottom: 0;
                            z-index: 1;
                            &::after {
                                content: '';
                                position: absolute;
                                background: $black;
                                width: 10px;
                                height: 10px;
                                @include radius(50px);
                            }
                            &:nth-child(1) {
                                left: 48.5%;
                                bottom:8.5%;
                                &::after {
                                    left: 50%;
                                    bottom: 23px;
                                    margin-left: -7px;
                                }
                            }
                            &:nth-child(2) {
                                left: 48.5%;
                                bottom: 33.8%;
                                &::after {
                                    left: 50%;
                                    bottom: -11px;
                                    margin-left: -6px;
                                }
                            }
                            &:nth-child(3) {
                                left: 33.5%;
                                bottom: 33.8%;
                                &::after {
                                    left: 50%;
                                    bottom: -11px;
                                    margin-left: -5px;
                                }
                            }
                            &:nth-child(4) {
                                left: 36.5%;
                                bottom: 41.5%;
                                &::after {
                                    left: 50%;
                                    bottom: 16px;
                                    margin-left: -6px;
                                }
                            }
                            &:nth-child(5) {
                                left: 58.5%;
                                bottom: 41.5%;
                                &::after {
                                    left: 50%;
                                    bottom: 16px;
                                    margin-left: -6px;
                                }
                            }
                            &:nth-child(6) {
                                left: 60.5%;
                                bottom: 33.5%;
                                &::after {
                                    left: 50%;
                                    bottom: -10px;
                                    margin-left: -6px;
                                }
                            }
                            &:nth-child(7) {
                              left: 18.5%;
                              bottom: 55.5%;
                                &::after {
                                    left: 50%;
                                    bottom: 2px;
                                    margin-left: 14px;
                                }
                            }
                            &:nth-child(8) {
                              left: 46.5%;
                              bottom: 74.5%;
                                &::after {
                                    left: 50%;
                                    bottom: -16px;
                                    margin-left: -6px;
                                }
                            }
                            &:nth-child(9) {
                              left: 75.5%;
                              bottom: 55.5%;
                                &::after {
                                    left: 50%;
                                    bottom: 2px;
                                    margin-left: -25px;
                                }
                            }
                            &.active {
                                color: $green;
                                &::after {
                                    background: $green;
                                }
                            }
                        }
                    }
                }
            }
        }
        .range-progress-bar {
            .user-icon {
                @include flex();
                align-items: center;
                justify-content: center;
                background: $black;
                width: 40px;
                height: 40px;
                text-align: center;
                @include radius(50px);
            }
            .MuiSlider-rail {
              background: rgba($gray, .5);
              height: 8px;
              border-radius: 20px;
              opacity: 1;
            }
            .MuiSlider-track {
              background: $baseColor;
              height: 8px;
            }
            .MuiSlider-thumb {
              background: $baseColor;
              width: 14px;
              height: 14px;
              margin-top: -3px;
            }
            .MuiSlider-mark {
              background: rgba($black, .5);
              width: 1px;
              height: 6px;
              top: 1px;
            }
            .progressbar {
                @include flex;
                justify-content: center;
                padding-top: 8px;
                position: relative;
                overflow: visible;
                &::after  {
                    content: "";
                    background: rgba($gray, .5);
                    position: absolute;
                    width: 100%;
                    height: 8px;
                    left: 0;
                    right: 0;
                    top: 0;
                    @include radius(20px);
                }
                .progress {
                    background: $baseColor;
                    position: absolute;
                    height: 100%;
                    width: 90.9%;
                    left: 0;
                    top: 0;
                    z-index: 11;
                    overflow: visible;
                    &::after {
                        content:'';
                        background: $baseColor;
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        right: 0;
                        top: -3px;
                        @include radius(50px);
                        z-index:11;
                    }
                    &.progress-btn {
                        width:21% ;
                        left: auto;
                        right: auto;
                        &:before {
                            content:'';
                            background: $baseColor;
                            position: absolute;
                            width: 14px;
                            height: 14px;
                            left: 0;
                            top: -3px;
                            @include radius(50px);
                            z-index: 1;
                        }
                    }
                }
            }
            .range-point-number {
                @include flex();
                align-items: center;
                justify-content: space-between;
                padding:10px 5px;
                .item {
                    width: 20%;
                    @include font(15);
                    color: $black;
                    position: relative;
                    text-align: right;
                    &:after {
                        content:"";
                        position: absolute;
                        background: rgba($black, .5);
                        width: 1px;
                        height: 6px;
                        right: 3px;
                        top: -10px;
                        z-index: 1;
                    }
                    &:before {
                        content:"";
                        position: absolute;
                        background: rgba($black, .5);
                        width: 1px;
                        height: 6px;
                        left: 50%;
                        margin-left: -1px;
                        top:-10px;
                        z-index: 1;
                    }
                    &:first-child {
                        width: auto;
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        .report-range-item {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
            .range-top-bar {
                @include flex();
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 25px;
                h4 {
                    @include font(16);
                    font-weight: 500;
                    color: $blackColor;
                    margin-bottom: 0;
                    .icon {
                      display: inline-block;
                      vertical-align: -3px;
                      margin-left: 10px;
                    }
                }
                .range-info {
                    display: block;
                    background: $white;
                    border: 1px solid rgba($gray, .5);
                    @include radius(4px);
                    .item {
                        padding: 8px 10px;
                        width: 100%;
                        @include font(14);
                        font-weight: 500;
                        color:$baseColor;
                        text-align: center;
                        &:first-child {
                            background: $green;
                            color: $white;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        .field-box-item {
            margin-bottom: 25px;
            h5 {
                @include font(16);
                font-weight: 500;
                color: $blackColor;
                margin-bottom: 15px;
                sup {
                    display: inline-block;
                    vertical-align: -8px;
                }
                .icon {
                  display: inline-block;
                  vertical-align: -4px;
                  margin-left: 8px;
                }
            }
            label {
                display: block;
                @include font(14);
                font-weight: 400;
                color: $black;
                margin-bottom: 18px;
            }
            input {
                @include font(14);
                font-weight: 400;
                color: $black;
                width: 100%;
                border: 1px solid $border;
                @include radius(4px);
                padding: 0 15px;
                height: 50px;
                box-shadow: none;
                &::placeholder {
                  color: $black;
                }
            }
            a{
              padding: 10px;
              width: 100%;
              font-weight: 500;
              color: #3e3e3e;
              line-height: 1.5;
              border: 1px solid #ccc;
              border-radius: 4px;
              cursor: pointer;
              -webkit-border-radius: 4px;
            }
            textarea {
                padding: 15px;
                width: 100%;
                min-height: 150px;
                @include font(16);
                font-weight: 400;
                color: $black;
                line-height: 1.5;
                border: 1px solid $gray;
                outline: none;
                box-shadow: none;
                @include radius(4px);
                &::placeholder {
                  color: $black;
                }
            }
        }
        .projected-division-wrap {
          .top-info {
            @include flex();
            align-items: center;
            margin-bottom: 20px;
            h5 {
              @include flex();
              align-items:center;
              font-weight: 500;
              color: $blackColor;
              margin-bottom: 0;
              .icon {
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                svg {
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
            .info-box {
              @include flex();
              align-items: center;
              margin-left: 15px;
              .btn {
                padding: 10px 20px;
                line-height: 20px;
                margin-left: 20px;
              }
              .item {
                @include flex();
                align-items: center;
                margin-left: 25px;
                text-transform: uppercase;
                .icon {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 10px;
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
          .division-table {
            @include font(15);
            font-weight: 400;
            color: $black;
            border: 1px solid rgba($gray, .5);
            @include radius(5px);
            margin-bottom: 25px;
            .table {
              margin-bottom: 0;
            }
            tr {
              &:nth-child(odd) {
                background: rgba($gray, 0.1%)
              }
              &:first-child {
                td {
                  border-top: none;
                  font-weight: 500;
                  .icon {
                    margin-left: 8px;
                    svg {
                      width: 15px;
                      height: 15px;
                    }
                  }
                }
              }
              td {
                border-top:1 solid rgba($gray, .5);
                padding: 15px 15px;
                .icon {
                  display: inline-block;
                  vertical-align: middle;
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
        .btn-wrap {
            .btn {
                display: inline-block;
                vertical-align: middle;
                padding: 10px 15px;
                margin:0 8px 5px;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                span {
                    &.icon {
                        margin-right: 10px;
                    }
                }
                &.blue {
                    background: $lightBlue;
                }
                &.yellow {
                    background: $yellow;
                }
            }
        }
      }
    }
  }
}
.shareLinkBox {
  @include flex();
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid $gray;
  padding-top: 10px;
  margin-top: 15px;
  a {
    @include flex();
    padding: 5px;
    @include font(14);
    text-decoration: none;
    @include transition();
    span {
      @include transition();
    }
    .icon {
      margin-left: 10px;
      margin-right: 10px;
    }
    &.fireRed {
      @include transition();
      svg {
        path {
          fill: $orange;
          @include transition();
        }
        .a {
          fill: $orange;
          @include transition();
        }
      }
    }
    &.shareLink {
      svg {
        path {
          fill: $black;
          @include transition();
        }
      }
    }
    &:hover {
      text-decoration: none;
      color: $baseColor;
      svg {
        path {
          fill: $baseColor;
        }
      }
      &.fireRed {
        svg {
          path {
            fill: $baseColor;
          }
          .a {
            fill: $baseColor;
          }
        }
      }
      span {
        color: $baseColor !important;
      }
    }
  }
}
.public-setting-wrapper {
  @include flex();
  justify-content: space-between;
  flex-direction: row-reverse;
  .profile-setting-menu {
    background: $white;
    width: 260px;
    border: 1px solid $gray;
    overflow: hidden;
    @include radius(5px);
    .title-box {
      background: #f2fcff;
      padding: 15px;
      .form-check {
        .form-check-label {
          @include font(15);
          font-weight: 600;
          line-height: 1.3;
        }
      }
    }
    .profile-setting-info {
      background: $white;
      padding: 15px;
      border-bottom: 1px solid $gray;
      @include font(13);
      font-weight: 400;
      color: $black;
      line-height: 1.5;
      h3 {
        @include font(14);
        font-weight: 600;
        color: $black;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .profile-menu {
      padding: 20px 15px;
      li {
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .form-check {
      display: block;
      padding-left: 0;
      .form-check-label {
        display: inline-block;
        vertical-align: middle;
        @include font(14);
        font-weight: 500;
        color: $black;
        width: 144px;
        margin-right: 5px;
        cursor: pointer;
      }
      &.form-switch {
        @include flex();
        justify-content: space-between;
        align-items: center;
        .form-check-input {
          background-image: none;
          background: rgba($gray, 0.5%);
          border-width: 0;
          @include radius(50px);
          width: 30px;
          height: 12px;
          border: none;
          margin-left: 0;
          appearance: none;
          position: relative;
          @include transition();
          cursor: pointer;
          margin-top: 0;
          margin-left: 6px;
          &:after {
            content: "";
            background: rgba($black, 0.5);
            position: absolute;
            left: 0;
            top: -2px;
            width: 16px;
            height: 16px;
            border: none;
            border-radius: 50%;
            z-index: 1;
            @include transition();
          }
        }
        input:checked[type="checkbox"] {
          background: rgba($green, 0.5%);
          &:after {
            left: auto !important;
            right: 0 !important;
            background: $green;
          }
        }
      }
    }
  }
  .public-setting-details {
    width: calc(100% - 280px);
  }
}
.video-modal-profile {
  .MuiDialogTitle-root {
    height: 45px;
  }
  .MuiDialogContent-root {
    video {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      object-fit: cover;
      width: 100%!important;
      height: 255px !important;
      overflow: hidden;
    }
    iframe {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      width: 100%!important;
      height: 255px !important;
      overflow: hidden;
      object-fit: cover;
      position: relative;
    }
  }
}

.modal {
  &.featured-video-modal, &.declare-here-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow-y: scroll;
    .modal-dialog {
      max-width: 600px;
    }
    .modal-content {
      border: none;
      -webkit-box-shadow: 0px 3px 20px #00000080;
      box-shadow: 0px 3px 20px #00000080;
    }
    .modal-header {
      padding: 15px 25px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.4);
      margin-bottom: 0;
      .modal-title {
        @include font(20);
        font-weight: 700;
        color:$blackColor;
        margin-bottom: 0;
      }
      .close {
        font-weight: 300;
        color: #3E3E3E;
        outline: none;
      }
    }
    .modal-body {
      padding: 20px 25px 25px;
    }
    form {
      .fieldBox {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &.flexBox {
          @include flex();
          align-items: center;
          justify-content: space-between;
          &.phone {
            margin-bottom: 0;
            .flexBox {
              @include flex();
              justify-content: space-between;
              align-items: center;
              padding: 0 10px;
              max-width: 100%;
              border: 1px solid $gray;
              @include radius(4px);
              margin-bottom: 0!important;
              .countryFlag {
                width: 35px;
                img {
                  max-width: 100%;
                  height: auto;
                }
              }
              select {
                padding: 5px 15px 5px 5px;
                background: none;
                outline: none;
                border: none;
                @include font(14);
                color: $black;
                -webkit-appearance: button;
                appearance: button;
                width: 70px;
              }
              input {
                width: calc(100% - 100px);
                border: none;
              }
            }
          }
          .half {
            width: calc(50% - 10px);
          }
        }
        h5 {
          @include font(18);
          font-weight: 500;
          margin-bottom: 15px;
        }
        label {
          display: block;
          @include font(15);
          font-weight: 400;
          color: $black;
          margin-bottom: 10px;
          position: relative;
          sup {
            position: absolute;
            font-weight: 600;
            color: $baseColor;
            margin-left: 3px;
            top: 8px;
          }
        }
        input {
          @include font(14);
          font-weight: 300;
          color: $black;
          width: 100%;
          border: 1px solid $border;
          @include radius(4px);
          padding: 0 1em;
          height: 45px;
          box-shadow: none;
        }
        select {
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 14px 35px 14px 10px;
          @include font(14);
          font-weight: 300;
          color:$black;
          //background-image: url(../../../assets/img/arrowdown.png);
          background-repeat: no-repeat;
          background-position: bottom 14px right 12px;
          background-size: 12px;
          height: 45px;
          border: 1px solid $border;
          @include radius(4px);
        }
        textarea {
          display: block;
          width: 100%;
          padding: 15px;
          @include font(14);
          font-weight: 300;
          line-height: 22px;
          border: 1px solid $border;
          @include radius(4px);
          min-height: 120px;
        }

        .countryPhoneFlexBox {
          .countrySelect {
            .select__control {
              box-shadow: none;
            }
          }
          .select__control {
            min-height: 43px;
          }
          input {
            height: auto;
            padding:0;
          }
          .select__single-value {
            @include flex();
            align-items: center;
          }
        }
        &.phone {
          .flexBox {
            @include radius(4px);
            input, select {
              border: none;
              width: auto;
            }
          }
        }
        .captcha-flex-box {
          @include flex();
          align-items: center;
          justify-content: space-around;
          .item {
            width: 70%;
          }
          .captcha-code {
            @include flex();
            align-items: center;
            justify-content: space-between;
            width: 30%;
            padding-left: 20px;
            .code {
              @include flex();
              align-items: center;
              justify-content: center;
              padding: 10px;
              background: $gray;
              @include font(18);
              color:$black;
              height: 45px;
              min-width: 100px;
              @include radius(5px);
            }
            .icon {
              display: inline-block;
              vertical-align: middle;
              img {
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      padding:25px;
      justify-content: center;
      &.border-none {
        border-top: none;
        padding-top: 0;
      }
      .btn {
        background: $lightBlue;
        @include font(16);
        font-weight: 300;
        padding: 10px 15px;
        min-width: 100px;
        margin: 0 0.35rem;
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .athleteProfileFinalDarkSection {
    .topSection {
      .coachProfileFlexBox {
        .coachInfo {
          width: calc(100% - 1305px);
          padding-right: 10px;
        }
        .video-with-infobox {
          width: 1100px;
        }
        .infoBoxFlex {
          .item {
            margin-right: 15px;
            width: calc(25% - 15px);
            margin-bottom: 20px;
          }
        }
      }
    }
    .bottomSection {
      .container {
        max-width: 1600px;
        .tabBox {
          max-width: 1300px;
          margin: 0 auto 15px auto;
        }
        .TimelineData {
          max-width: 1300px;
          margin: 0 auto;
        }
      }
    }
  }
}
@media screen and (max-width: 1699px) {
  .athleteProfileFinalDarkSection {
    .topSection {
      .coachProfileFlexBox {
        .infoBoxFlex {
          .item {
            span {
              @include font(18);
              &.label {
                @include font(13);
              }
            }
            &.commmitBox {
              &.uncommit {
                span {
                  &.label {
                    @include font(12);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1365px) {
  .athleteProfileFinalDarkSection {
    .topSection {
      .coachProfileFlexBox {
        .coachInfo {
          width: calc(100% - 905px);
          padding-right: 10px;
        }
        .video-with-infobox {
          width: 700px;
          padding-left: 10px;
          .video-profilebox {
            width: 175px;
          }
        }
        .infoBoxFlex {
          width: calc(100% - 200px);
          .item {
            &.commmitBox {
              span {
                @include font(11);
              }
            }
          }
        }
        .metricCarouselBox {
          width: calc(100% - 500px);
          .owl-carousel {
            .owl-stage-outer {
              .owl-stage {
                .owl-item {
                  .item {
                    > div {
                      min-width: 1px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1299px) {
  .athleteProfileFinalDarkSection {
    .topSection {
      .coachProfileFlexBox {
        padding: 20px 15px;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .athleteProfileFinalDarkSection {
    .topSection {
      .coachProfileFlexBox {
        padding-left: 15px;
        padding-right: 15px;
        .coachInfo {
          width: calc(100% - 725px);
        }
        .infoBoxFlex {
          width: calc(100% - 165px);
          .item {
            width: calc(33.333% - 7px);
            &:nth-child(4n+4) {
              margin-right: 10px;
            }
            &:nth-child(3n+3) {
              margin-right: 0;
            }
          }
        }
        .video-with-infobox {
          width: 520px;
          .video-profilebox {
            width: 150px;
          }
        }
        .metricCarouselBox {
          width: calc(100% - 445px);
        }
      }
    }
    .bottomSection {
      .container {
        .public-setting-details {
          .tabBox {
            nav {
              ul {
                li {
                  margin-right: 25px;
                  a {
                    @include font(16);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:1023px) {
  .athleteProfileFinalDarkSection {
    .topSection {
      .coachProfileFlexBox {
        flex-wrap: wrap;
        position: relative;
        .carousel {
          order: 1;
        }
        .coachInfo {
          width: calc(100% - 205px);
          order: 2;
          padding-right: 220px;
        }
        .video-with-infobox {
          padding: 20px 0;
          width: 100%;
          order: 4;
          .video-profilebox {
            width: 200px;
            position: absolute;
            top: 35px;
            right: -10px;
          }
        }
        .infoBoxFlex {
          width: 100%;
          margin-top: 0;
          .item {
            width: calc(25% - 8px);
            &:nth-child(4n+4) {
              margin-right: 0;
            }
            &:nth-child(3n+3) {
              margin-right: 10px;
            }
            &.commmitBox {
              span {
                @include font(13);
              }
            }
          }
        }
        .actionBox {
          order: 3;
        }
        .metricCarouselBox {
          order: 5;
          width: 100%;
          margin-left: 0px;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .athleteProfileFinalDarkSection {
    .topSection {
      .coachProfileFlexBox {
        .carousel {
          .owl-carousel {
            .owl-stage-outer {
              .owl-stage {
                .owl-item {
                  .item {
                    .initial {
                      @include font(35);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .bottomSection {
      .container {
        max-width: 100%;
        .tabBox {
          nav {
            ul {
              li {
                margin-right: 50px;
              }
            }
          }
        }
        .public-setting-wrapper {
          .tabBox {
            padding-left: 15px;
            padding-right: 15px;
            nav {
              ul {
                li {
                  margin-right: 0;
                  a {
                    @include font(15);
                  }
                }
              }
            }
          }
        }
        .report-data {
          .report-flexbox {
              .item {
                  width: calc(100% - 330px);
              }
              .report-diagram {
                  margin: 0 0 0 30px;
              }
          }
        }
      }
    }
  }
  .about-sec-wrap {
    .about-detail-box {
      .common-item-box {
        .content-box {
          .data-info-item {
            li {
              width: calc(50% - 5px);
            }
            &.team-list {
              li {
                width: 100%;
              }
              &.circle {
                li {
                  width: 100%;
                }
              }
            }
            &.fourColumn {
              li {
                width: calc(50% - 5px) !important;
                &:nth-child(2n+2) {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .athleteProfileFinalDarkSection {
    .topSection {
      .coachProfileFlexBox {
        .infoBoxFlex {
          justify-content: space-between;
          .item {
            width: calc(50% - 5px);
            max-width: 100%;
            &:nth-child(2n+2) {
              margin-right: 0;
            }
            &:nth-child(2n+3) {
              margin-right: 10px;
            }
          }
        }
        .coachInfo {
          padding-right: 160px;
        }
        .video-with-infobox {
          .video-profilebox {
            width: 150px;
            .btn {
              &.add-video-btn {
                padding-left:15px!important;
                padding-right: 15px!important;
                @include font(13);
              }
            }
          }
        }
      }
    }
    .bottomSection {
      > .container {
        .checkListBox {
          flex-wrap: wrap;
          justify-content: space-between;
          .checkListNotify {
            order: 1;
          }
          .viewCheckBtn {
            margin-left: 10px;
            order: 2;
          }
          p {
            &.info {
              order: 3;
              width: 100%;
              padding: 0;
              margin-top: 10px;
            }
          }
        }
        .tabBox {
          padding: 0px 15px;
          nav {
            ul {
              li {
                margin-right: 15px;
              }
            }
          }
        }
      }
      .container {
        .TimelineData {
          &.video-tab-data {
            .item {
              padding-top: 15px!important;
              padding-bottom: 15px!important;
            }
          }
        }
      }
    }
  }
  .about-sec-wrap {
    .about-detail-box {
      .common-item-box {
        .content-box {
          .video-grid-list {
            li {
              float: none;
              width: 100%;
              margin-right: 0;
            }
          }
          .data-info-item {
            &.team-list {
              &.event {
                .detail-box {
                  li {
                    span {
                      &:first-child {
                        width: auto;
                      }
                    }
                  }
                }
              }
            }
            li {
              span {
                &:first-child {
                  width: 168px;
                }
              }
            }
          }
        }
      }
    }
  }
  .public-setting-wrapper {
    display: block;
    .profile-setting-menu {
      width: 100%;
      margin-bottom: 20px;
    }
    .public-setting-details {
      width: 100%;
    }
  }
  .modal {
    &.featured-video-modal, &.declare-here-modal {
      .modal-header {
        padding-left: 15px;
        padding-right: 15px;
        .modal-title {
          @include font(20);
        }
      }
      .modal-body {
        padding: 20px 15px 25px;
      }
      form {
        .fieldBox {
          margin-bottom: 15px;
          h5 {
            @include font(16);
          }
          &.flexBox {
            display: block;
            .half {
              width: 100%;
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .captcha-flex-box {
            .item {
              width: 50%;
            }
            .captcha-code {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:639px) {
  .athleteProfileFinalDarkSection {
      .bottomSection {
        .container {
          .report-data {
            padding: 15px;
            .report-profile-info {
                .title {
                    @include font(18);
                }
                .scouting-info-box {
                    padding: 15px;
                }

                .profile-info {
                    .pick-box {
                      width: 65px;
                      height: 65px;
                      img {
                        width: 65px;
                        height: 65px;
                      }
                    }
                    .detail-box {
                        .name {
                            @include font(16);
                        }
                    }
                }
                ul {
                    li {
                        @include font(15);
                    }
                }
                h5 {
                    @include font(16);
                }
            }
            .report-flexbox {
                display: block;
                .item {
                    width: 100%;
                }
                .report-diagram {
                    margin:20px auto 0;
                }
            }
            .report-range-item {
                margin-bottom: 30px;
                .range-top-bar {
                    h4 {
                        @include font(16);
                    }
                }
            }
            .field-box-item {
                h5 {
                    line-height: 1.3;
                }
                label {
                    line-height: 1.4;
                }
                textarea {
                    @include font(15);
                }
            }
            .projected-division-wrap {
              .top-info {
                .info-box {
                  .item {
                    margin-left: 15px;
                  }
                }
              }
            }
            .btn-wrap {
                .btn {
                    @include font(13);
                    span {
                        &.icon {
                            margin-right: 5px;
                        }
                    }
                }
            }
          }
        }
      }
  }
  .video-modal-profile {
    .MuiDialogContent-root {
      iframe, video {
        object-fit: cover;
        height: 160px!important;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .athleteProfileFinalDarkSection {
    .topSection {
      box-shadow: none;
      background-color: transparent;
      background-image: url(../img/dark-mob-bg.svg);
      .coachProfileFlexBox {
        justify-content: center;
        .carousel {
          order: 1;
          width: 120px;
          height: 120px;
          margin: 0 auto;
          @include radius(50%);
          overflow: hidden;
          .owl-carousel {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            @include radius(50%);
            overflow: hidden;
            .owl-stage-outer {
              height: 100%;
              .owl-stage {
                .owl-item {
                  .item {
                    .profile-inner-box {
                      @include flex();
                      justify-content: center;
                      align-items: center;
                      height: 120px;
                      img {
                        text-align: center;
                      }
                    }
                    .initial {
                      @include font(24);
                    }
                  }
                }
              }
            }
          }
        }
        .coachInfo {
          margin-top: 15px;
          width: 100%;
          padding-right: 0;
          @include flex();
          align-items: center;
          flex-direction: column;
          .pName {
            justify-content: center;
          }
          .designation {
            text-align: center;
          }
          p {
            text-align: center;
            &.info {
              justify-content: center;
            }
            &.email {
              text-align: center;
            }
            &.social {
              justify-content: center;
            }
          }
          ul {
            justify-content: center;
            li {
              margin-right: 5px;
            }
          }
        }
        .video-with-infobox {
          display: block;
          .video-profilebox {
            width: 100%;
            margin-bottom: 20px;
            position: static;
            top: auto;
            right: auto;
            .btn {
              &.add-video-btn {
                @include font(16);
                padding-left: 20px !important;
                padding-right: 20px !important;
                float: none;
                margin: 0 auto;
              }
            }
          }
        }
        .infoBoxFlex {
          width: 100%;
          .item {
            display: none;
            width: calc(50% - 5px);
            margin-right: 0;
            &:nth-child(3n+3) {
              margin-right: 0;
            }
            &:nth-child(1) {
              @include flex();
            }
            &:nth-child(2) {
              @include flex();
            }
            &.commmitBox {
              display: none;
            }
          }
          &.hide {
            .item {
              display: flex!important;
              &.commmitBox {
                display: block!important;
              }
            }
          }
          .showhideBox {
            display: block;
          }
        }
        .actionBox {
          button {
            &.followBtn {
              padding: 12px 10px;
            }
            &.promoteBtn {
              padding: 10px;
            }
          }
        }
        .metricCarouselBox {
          .title {
            margin-right: 10px;
          }

          .owl-carousel {
            width: calc(100% - 70px);
            .owl-stage-outer {
              .owl-stage {
                .item {
                  > div {
                    min-width: 120px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .bottomSection {
      .container {
        .tabBox {
          padding: 10px;
          nav {
            position: fixed;
            left: -300px;
            width: 250px;
            height: 100%;
            background-color: $white;
            padding: 15px;
            top: 0;
            z-index: 999;
            transition: all 0.5s ease-in-out;
            ul {
              display: block!important;
              li {
                border-bottom: 1px solid $gray;
                padding-bottom: 0;
                margin-bottom: 0;
                margin-right: 0;
                a {
                  width: 100%;
                  padding: 15px 10px;
                }
                &.closeBtnBox {
                  display: block;
                  padding-bottom: 20px;
                }
              }
            }
            &::after {
              content: "";
              display: inline-block;
              transition: all 0.5s ease-in-out;
              width: 0;
              left: -300px;
              height: 100%;
              position: fixed;
              top: 0;
              background: rgba(0, 0, 0, 0.5);
            }
            &.show {
              left: 0px;
              &::after {
                width: calc(100% - 250px);
                left: 250px;
              }
            }
          }
          .flexBox {
            padding: 8px;
            display: flex!important;
          }
          &.profileTab-hide-mobile {
            display: none;
          }
          &.profileTab-show-mobile {
            display: block;
            padding: 0 30px!important;
            nav {
              background: none;
              position: static;
              width: 100%;
              left: auto;
              height: auto;
              padding: 0;
              &::after {
                display: none;
              }
              ul {
                margin: 0;
                padding: 0;
                display: block;
                li {
                  list-style: none;
                  padding: 0;
                  margin: 0;
                  border: none;
                  text-align: center;
                  a {
                    padding: 15px 0;
                    @include font(14);
                    color: $black;
                    display: inline-block;
                    text-decoration: none;
                    position: relative;
                    &:hover {
                      color: $baseColor;
                    }
                  }
                  &.selected {
                    a {
                      color: $baseColor;
                      &::after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background-color: $baseColor;
                      }
                    }
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  &.reportLink {
                    a {
                      span {
                        &.icon {
                          display: inline-block;
                          margin-left: 5px;
                          svg {
                            .a {
                              fill: $black;
                              fill-rule: evenodd;
                            }
                          }
                        }
                      }
                      &:hover {
                        span {
                          &.icon {
                            .a {
                              fill: $baseColor;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .owl-nav {
              display: block;
              position: absolute;
              width: 100%;
              top: 2px;
              left: 0;
              right: 0;
              z-index: 1;
              button {
                @include transition();
                span {
                  content: "";
                  display: inline-block;
                  position: relative;
                  width: 10px;
                  height: 10px;
                  font-size: 0;
                  border-right: 2px solid $black;
                  border-bottom: 2px solid $black;
                  transform: rotate(-0deg);
                  -webkit-transform: rotate(135deg);
                  -moz-transform: rotate(135deg);
                  -ms-transform: rotate(135deg);
                  -o-transform: rotate(135deg);
                  -webkit-transition: all 0.3s ease-out;
                  transition: all 0.3s ease-out;
                }
                &.owl-next {
                  float: right;
                  position: absolute;
                  right: -18px;
                  outline: none;
                  span {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                  }
                }
                &.owl-prev {
                  float: left;
                  position: absolute;
                  left: -18px;
                  outline: none;
                }
                &:hover {
                  opacity: 0.5;
                }
              }
            }
          }
        }
        .TimelineData {
          .item {
            .headFlex {
              flex-wrap: wrap;
              .imgBox {
                order: 2;
              }
              .info {
                width: calc(100% - 50px);
                padding-right: 0;
                order: 3;
              }
              .time {
                width: 100%;
                margin-left: auto;
                margin-bottom: 10px;
                justify-content: flex-end;
                order: 1;
                span {
                  &.pinIcon {
                    margin-left: 10px;
                  }
                  &.dotMenu {
                    margin-left: 10px;
                  }
                }
              }
            }
            .flexItem {
              .imgBox {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                img {
                  max-height: 40px;
                }
              }
              .info {
                width: calc(100% - 60px);
                h5 {
                  @include font(14);
                  line-height: 18px;
                }
              }
            }
          }
        }
        .report-data {
          .projected-division-wrap {
            .top-info {
              display: block;
              h5 {
                margin-bottom: 15px;
              }
              .info-box {
                margin-left: 0;
                .item {
                  margin-left: 15px;
                }
              }
            }
            .division-table {
              overflow-x: scroll;
              // Window Scrolbar
              &::-webkit-scrollbar {
                border-radius: 10px;
                width: 8px;
                height: 10px;
                background: rgba($gray, 0.3%);
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba($gray, 0.8%);
                cursor: pointer;
              }
              tr {
                td {
                  padding: 10px;
                  min-width: 120px;
                  &:first-child {
                    min-width: 160px;
                  }
                }
              }
            }
          }
          .btn-wrap {
              .btn {
                  width: 49%;
                  margin: 0;
                  &:first-child {
                      display: block;
                      width: 100%;
                      margin-bottom: 15px;
                  }
                  &:last-child {
                    margin-left: 2%;
                  }
              }
          }
        }
      }
    }
  }
  .video-and-photo-wrap {
    margin-top: 0;
  }
  .about-sec-wrap {
    display: block;
    .sidebar {
      width: 100%;
      max-height: 100%;
      position: static;
      &.aboutsidebar-show-mobile,
      &.statsSidebar-show-mobile
      {
        display: block;
        background-color: $white;
        padding: 0 30px;
        border: 1px solid #00000038;
        margin: 15px auto;
        @include radius(4px);
        .menu-links {
          li {
            margin: 0 auto;
            text-align: center;
            a {
              padding: 15px 0;
              @include font(14);
              &:hover,
              &.active {
                background: none;
                &::after {
                  display: none;
                }
              }
            }
          }
        }
        .tabslide {
          .owl-stage {
            transform: none!important;
          }
          li {
            display: block;
            text-align: center;
            a {
              display: inline-block;
              vertical-align: middle;
              padding:10px 0;
              @include font(13);
              font-weight: 400;
              color:$black;
              &:hover,
              &.active {
                color:$green;
              }
            }
          }
        }
      }
      &.aboutsidebar-hide-mobile {
        display: none;
      }
      &.statsSidebar-hide-mobile {
        display: none;
      }
      .owl-nav {
        display: block;
        position: absolute;
        width: 100%;
        top: 2px;
        left: 0;
        right: 0;
        z-index: 1;
        button {
          @include transition();
          span {
            content: "";
            display: inline-block;
            position: relative;
            width: 10px;
            height: 10px;
            font-size: 0;
            border-right: 2px solid $black;
            border-bottom: 2px solid $black;
            transform: rotate(-0deg);
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
          }
          &.owl-next {
            float: right;
            position: absolute;
            right: -18px;
            outline: none;
            span {
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
            }
          }
          &.owl-prev {
            float: left;
            position: absolute;
            left: -18px;
            outline: none;
          }
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
    .about-detail-box {
      width: 100%;
      margin-left: 0;
      padding-left: 0;
      border-left: 0;
      min-height: 1px;
      padding-top: 20px;
      border-top: 1px solid $gray;
      .common-item-box {
        .content-box {
          @include font(13);
          h4 {
            @include font(14);
            margin-bottom: 15px;
          }
          .data-info-item {
            li {
              margin-bottom: 12px;
              span {
                &.profile-img {
                  span {
                    width: 60px;
                    height: 60px;
                  }
                  img {
                    width: 60px;
                    height: 60px;
                  }
                }
                &:first-child {
                  width: auto;
                }
              }
            }
            &.team-list {
              .img-box {
                width: 60px;
              }
              .detail-box {
                @include font(13);
              }
              &.circle {
                .img-box {
                  flex: 0 0 60px;
                  width: 60px;
                  height: 60px;
                }
              }
              &.event {
                .detail-box {
                  li {
                    display: block;
                    span {
                      display: block;
                    }
                  }
                }
              }
            }
          }
          .scores-listing {
            li {
              width: 100%;
              min-height: 1px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
