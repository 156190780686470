.common-modal .modal-content .modal-body .claimprofile-password-box {
  display: block;
  text-align: center;
}

.common-modal .modal-content .modal-body .claimprofile-password-box div {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.common-modal .modal-content .modal-body .claimprofile-password-box div > div {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  width: 45px;
  display: inline-block !important;
}

.common-modal .modal-content .modal-body .claimprofile-password-box div > div input {
  font-size: 1.125rem;
  font-weight: 400;
  color: #3e3e3e;
  width: 100% !important;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: .5em;
  height: 45px;
  text-align: center;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 45px;
}

.common-modal .modal-content .modal-body .claimprofile-password-box li {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  width: 45px;
}

.common-modal .modal-content .modal-body .claimprofile-password-box li input {
  font-size: 1.125rem;
  font-weight: 400;
  color: #3e3e3e;
  width: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: .5em;
  height: 45px;
  text-align: center;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 45px;
}
