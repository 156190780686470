@import "../../../../../src/assets/css/variables";
@import "../../../../../src/assets/css/mixin";
@import "../../../../../src/assets/css/reset";

@keyframes shadowPulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    }

    70% {
        -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 150, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.recruiting-guide-sec {
    background: $white;
    @include flex();
    justify-content: space-between;
    margin-top: 60px;

    .block-info-box {
        padding: 200px 50px 100px;
        background: url(../img/bg-recruits.png) no-repeat;
        background-position: center top;
        background-size: cover;
        width: 50%;
        text-align: center;
        color: $white;

        .info-data {
            max-width: 620px;
            margin: 0 auto;
        }

        .brand-logo {
            max-width: 420px;
            margin: 0 auto 50px;

            img {
                max-width: 100%;
            }
        }

        .tagline {
            @include font(30);
            font-weight: 500;
            color: $white;
            margin-bottom: 20px;
        }

        .title {
            @include font(60);
            font-weight: 600;
            color: $white;
            padding-bottom: 15px;
            margin-bottom: 30px;
            position: relative;

            &::after {
                content: "";
                background: $baseColor;
                width: 120px;
                height: 6px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                z-index: 1;
            }
        }

        p {
            @include font(16);
            font-weight: 400;
            color: $white;
            line-height: 35px;
            margin-bottom: 20px;
        }
    }

    .data-box {
        background: $white;
        padding: 80px 60px;
        width: 50%;

        h2,
        h3,
        h4 {
            @include font(30);
            font-weight: 700;
            color: $blackColor;
            margin-bottom: 15px;
        }

        p {
            @include font(16);
            font-weight: 400;
            color: $black;
            line-height: 35px;
            margin-bottom: 20px;

            strong {
                font-weight: 500;
                color: $blackColor;
            }
        }

        ul,
        ol {
            @include font(16);
            font-weight: 500;
            color: $blackColor;
            margin-bottom: 20px;

            li {
                display: block;
                margin-bottom: 10px;
            }
        }

        .button-wrap {
            .btn {
                padding: 10px 20px;
                @include font(18);
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
}

.ads-recruiting-sec {
    background: $white;
    @include flex();
    justify-content: space-between;
    margin-top: 60px;

    .block-info-box {
        background: transparent linear-gradient(129deg, #071423 0%, #232E3B 51%, #182A3E 100%) 0% 0% no-repeat padding-box;
        padding: 60px 50px;
        width: 50%;
        color: $white;

        .info-data {
            max-width: 620px;
            margin: 0 auto;
        }

        .brand-logo {
            max-width: 420px;
            margin: 0 auto 30px;

            a {
                display: inline-block;
                vertical-align: middle;
            }

            img {
                max-width: 100%;
            }
        }

        .sec-title {
            @include font(36);
            font-weight: 700;
            color: $white;
            margin-bottom: 30px;
            text-align: center;
        }

        .service-item {
            margin-left: 115px;
            margin-top: 115px;
            position: relative;

            &::before {
                content: '';
                background: #fff url(../img/quote-red-icon.svg) no-repeat;
                background-position: center center;
                position: absolute;
                width: 70px;
                height: 70px;
                left: -80px;
                margin-left: -35px;
                top: -75px;
                bottom: 0;
                @include radius(50px);
                z-index: 1;
            }

            .item {
                &::after {
                    content: '';
                    background: rgba($white, .2);
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    left: -80px;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                }

                .title {
                    @include font(40);
                    font-weight: 600;
                    color: $white;
                    margin-bottom: 20px;
                    position: relative;

                    &::after {
                        content: '';
                        background: rgba($white, .2);
                        width: 60px;
                        height: 1px;
                        position: absolute;
                        left: -80px;
                        top: 25px;
                        z-index: 1;
                    }
                }

                .short-text {
                    @include font(16);
                    font-weight: 400;
                    color: $white;
                    margin-bottom: 40px;
                    position: relative;

                    &::after {
                        content: '';
                        background: rgba($white, .2);
                        width: 60px;
                        height: 1px;
                        position: absolute;
                        left: -80px;
                        top: 18px;
                        z-index: 1;
                    }

                    p {
                        line-height: 36px;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .profile-bio-box {
                    @include flex();
                    align-items: center;

                    .pic-box {
                        width: 60px;
                        height: 60px;
                        margin-right: 10px;

                        img {
                            display: block;
                            max-width: 100%;
                            width: 60px;
                            height: 60px;
                            object-fit: cover;
                        }
                    }

                    .info-box {
                        @include font(15);
                        font-weight: 400;
                        color: $white;

                        h4 {
                            @include font(18);
                            font-weight: 500;
                            margin-bottom: 5px;
                        }

                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .form-block-box {
        background: $white;
        padding: 60px 50px;
        width: 50%;

        .inner-box-wrap {
            max-width: 540px;
            margin: 0 auto;
        }

        .title {
            @include font(24);
            font-weight: 600;
            color: $black;
            margin-bottom: 15px;

            +p {
                @include font(16);
                font-weight: 400;
                color: rgba($blackColor, .5);
                line-height: 1.5;
                margin-bottom: 20px;
            }
        }

        .form-box {
            padding-bottom: 50px;

            .field-item {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                label {
                    display: block;
                    position: relative;
                    @include font(16);
                    font-weight: 400;
                    color: $black;
                    line-height: 1.4;
                    margin-bottom: 10px;

                    sup {
                        position: absolute;
                        top: 10px;
                        color: $baseColor;
                        margin-left: 3px;
                    }
                }

                input {
                    padding: 10px 10px;
                    width: 100%;
                    @include font(16);
                    color: $black;
                    border: 1px solid $gray;
                    outline: none;
                    box-shadow: none;
                    height: 48px;
                    @include radius(4px);

                    &::placeholder {
                        color: rgba($black, .5);
                    }
                }

                .btn {
                    display: block;
                    padding: 10px 15px;
                    width: 100%;
                    @include font(18);
                    font-weight: 500;
                    @include radius(4px);
                    cursor: pointer;
                    margin-top: 30px;
                }
            }
        }

        .bottom-text {
            text-align: center;
            @include font(18);
            font-weight: 400;
            line-height: 16px;

            p {
                margin-bottom: 0;
            }

            a {
                display: inline-block;
                vertical-align: middle;
                text-decoration: none;
                padding: 0 10px;
                color: rgba($blackColor, .5);
                cursor: pointer;

                &:first-child {
                    border-right: 1px solid rgba($blackColor, .5);
                }

                &:hover {
                    color: $baseColor;
                }
            }
        }
    }
}

.banner-lp-sec {
    background: #071423;
    background: url(../img/athlete-banner-img01.jpg) no-repeat;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    padding: 80px 0;
    margin-top: 60px;
    position: relative;

    &::after {
        content: '';
        background: rgba(#071423, .9);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
    }

    .container {
        position: relative;
        z-index: 11;
    }

    .banner-flexbox {
        @include flex();
        justify-content: space-between;
        align-items: center;

        .banner-content {
            width: 50%;
            margin-right: 5%;
            color: $white;

            .title {
                @include font(42);
                font-weight: 600;
                color: $white;
                line-height: 1.333;
                margin-bottom: 20px;

                span {
                    display: block;
                    color: $lightBlue;
                }
            }

            .brand-logo {
                margin: -10px 0 10px;

                img {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 100%;
                }
            }

            .short-text {
                @include font(20);
                font-weight: 500;
                color: $white;
                margin-bottom: 25px;

                p {
                    line-height: 36px;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.marktext {
                        @include font(16);
                    }
                }
            }

            .button-wrap {
                .btn {
                    padding: 10px 15px;
                    @include font(18);
                    font-weight: 500;
                    color: $white;
                    white-space: normal;
                    min-width: 240px;
                    @include radius(4px);
                    cursor: pointer !important;

                    &.lightblue {
                        background: $lightBlue;
                        min-width: 180px;
                    }

                    +p {
                        @include font(14);
                        margin-top: 18px;
                        text-align: center;
                    }
                }

                &.width-460 {
                    max-width: 460px;

                    .btn {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }

        .video-box-wrap {
            width: 45%;

            .video-img {
                position: relative;

                img {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    @include radius(6px);
                }

                a {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                }

                video {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    border: 2px solid #ccc;
                    background: #000000;
                }

                .video-play-button {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                    cursor: pointer;

                    .video-play-inner {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        height: 100%;

                        .play-btn {
                            @include flex();
                            justify-content: center;
                            align-items: center;
                            background: $white;
                            width: 75px;
                            height: 75px;
                            @include radius(50px);
                            outline: none;
                            border: none;
                            -webkit-animation: shadowPulse 2s linear infinite;
                            animation: shadowPulse 2s linear infinite;
                            -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3);
                            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3);
                            cursor: pointer;

                            svg {
                                .a {
                                    fill: $baseColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .college-banner-content {
        text-align: center;
        color: $white;

        .tagline {
            @include font(24);
            font-weight: 600;
            color: $white;
            margin-bottom: 10px;
        }

        .title {
            @include font(42);
            font-weight: 600;
            color: $white;
            margin-bottom: 10px;
        }

        .short-text {
            @include font(18);
            font-weight: 500;
            color: $white;
            margin-bottom: 25px;

            p {
                line-height: 36px;
                margin-bottom: 25px;
            }

            .field-box {
                max-width: 400px;
                margin: 0 auto;

                input {
                    background: transparent;
                    display: block;
                    padding: 0 15px;
                    @include font(18);
                    font-weight: 400;
                    color: $white;
                    width: 100%;
                    height: 60px;
                    border: 1px solid $white;
                    @include radius(8px);

                    &::placeholder {
                        color: $white;
                    }
                }
            }
        }

        .button-wrap {
            .btn {
                padding: 10px 15px;
                @include font(20);
                font-weight: 500;
                color: $white;
                min-width: 240px;
                @include radius(4px);
                cursor: pointer !important;
            }
        }
    }

    .commit-banner-content {
        text-align: center;
        color: $white;

        .title {
            @include font(54);
            font-weight: 600;
            color: $white;
            margin-bottom: 10px;
        }

        .sub-title {
            @include font(90);
            font-weight: 800;
            color: $lightBlue;
            margin-bottom: 10px;
        }

        .short-text {
            @include font(18);
            font-weight: 500;
            color: $white;
            margin-bottom: 25px;

            p {
                line-height: 36px;
                margin-bottom: 25px;
            }
        }

        .button-wrap {
            .btn {
                padding: 10px 15px;
                @include font(20);
                font-weight: 500;
                color: $white;
                min-width: 160px;
                @include radius(4px);
                cursor: pointer !important;

                &.lightblue {
                    background: $lightBlue;
                }
            }
        }
    }

    &.banner-hightlight {
        //background: url(../img/highlight-reels-banner.png) no-repeat;
        background-size: cover;
        background-position: center center;

        .banner-flexbox {
            .banner-content {
                .short-text {
                    @include font(18);
                    font-weight: 400;

                    p {
                        line-height: 34px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

.collage-coache-sec {
    background: $white;
    padding: 60px 0 40px;

    .top-block {
        @include font(18);
        font-weight: 400;
        color: $black;
        max-width: 1035px;
        margin: 0 auto 40px;
        text-align: center;

        .title {
            @include font(42);
            font-weight: 600;
            color: $blackColor;
            line-height: 1.4;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 0;
            line-height: 34px;
        }
    }

    .collage-coach-list {
        @include flex();
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -8px;

        .item {
            width: 25%;
            padding: 0 8px;
            margin: 15px 0 30px;

            .detail-box {
                background: $white;
                padding: 15px;
                @include radius(8px);
                box-shadow: 0px 10px 20px #9C9C9C33;

                .info-box {
                    background: #00B9FF1A;
                    @include flex();
                    align-items: center;
                    padding: 15px;
                    @include font(16);
                    font-weight: 500;
                    color: $blackColor;
                    line-height: 24px;
                    border: 1px solid $lightBlue;
                    @include radius(8px);
                    margin-top: -30px;
                    min-height: 82px;

                    .icon-box {
                        width: 42px;
                        margin-right: 12px;
                    }
                }

                p {
                    @include font(16);
                    font-weight: 400;
                    color: #757575;
                    line-height: 28px;
                    margin-top: 15px;
                }
            }
        }
    }
}

.get-started-sec {
    background: url(../img/get-started-bg.png) no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 60px 0;
    text-align: center;
    position: relative;

    &::after {
        content: '';
        background: rgba(#232526, 0.86);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .get-started-bio-content {
        margin-left: 320px;
        text-align: left;
        position: relative;
        z-index: 1;

        .short-text {
            margin-bottom: 20px;
        }
    }

    .get-started-flexbox {
        @include flex();
        flex-direction: column;
        justify-content: center;
        min-height: 220px;
    }

    .sec-title {
        @include font(42);
        font-weight: 600;
        color: $white;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;
    }

    .short-text {
        @include font(18);
        font-weight: 400;
        color: $white;
        line-height: 32px;
        margin-bottom: 35px;
        position: relative;
        z-index: 1;

        p {
            margin-bottom: 15px;

            .marktext {
                display: block;
                @include font(14);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .button-wrap {
        position: relative;
        z-index: 1;

        .btn {
            display: inline-block;
            vertical-align: middle;
            background: $baseColor;
            padding: 10px 20px;
            @include font(18);
            font-weight: 500;
            color: $white;
            white-space: normal;
            @include radius(4px);
            outline: none;
            cursor: pointer;

            +p {
                @include font(14);
                font-weight: 400;
                color: $white;
                padding-top: 20px;
                margin-bottom: 0;
            }
        }
    }
}

.athlete-common-sec {
    padding: 60px 0;

    .top-block {
        @include font(18);
        font-weight: 400;
        color: $black;
        max-width: 1035px;
        margin: 0 auto 50px;
        text-align: center;

        .title {
            @include font(42);
            font-weight: 600;
            color: $blackColor;
            line-height: 1.4;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 0;
            line-height: 34px;
        }
    }

    .common-listing {
        .item-row {
            @include flex();
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 60px;

            &:nth-child(even) {
                flex-direction: row-reverse;

                .content-box {
                    margin-right: 0;
                    margin-left: 6%;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            .content-box {
                width: 47%;
                margin-right: 6%;

                .top-block {
                    background: rgba($gray, .1);
                    padding: 15px 15px 15px 35px;
                    @include radius(12px);
                    margin-bottom: 20px;
                    position: relative;
                    text-align: left;

                    &::after {
                        content: '';
                        background: $lightBlue;
                        position: absolute;
                        width: 6px;
                        left: 15px;
                        top: 15px;
                        bottom: 15px;
                        @include radius(5px);
                        z-index: 1;
                    }

                    .tagline {
                        @include font(14);
                        color: #858585;
                        font-weight: 400;
                        text-transform: uppercase;
                        margin-bottom: 5px;
                    }

                    .title {
                        @include font(26);
                        font-weight: 700;
                        color: $blackColor;
                        margin-bottom: 0;
                    }
                }

                .detail-text {
                    @include font(16);
                    color: $blackColor;
                    font-weight: 400;
                    line-height: 30px;

                    p {
                        margin-bottom: 15px;
                    }

                    ul {
                        list-style: none;
                        padding-left: 0;
                        margin-bottom: 25px;

                        li {
                            position: relative;
                            padding-left: 40px;
                            margin-bottom: 8px;
                            white-space: break-spaces;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &::after {
                                content: '';
                                background: url(../img/check-blue-icon.svg) no-repeat;
                                background-position: center center;
                                position: absolute;
                                width: 24px;
                                height: 22px;
                                left: 0;
                                top: 5px;
                                z-index: 1;
                            }
                        }
                    }

                    .success-info-box {
                        @include flex();
                        align-items: flex-start;

                        .pick-box {
                            width: 100px;
                            margin-right: 15px;

                            .pick-img {
                                display: block;
                                max-width: 100%;
                                height: 100px;
                                object-fit: cover;
                                box-shadow: 0px 0px 15px #00000029;
                                border: 2px solid $white;
                                @include radius(6px);
                            }

                            .icon-box {
                                @include flex();
                                justify-content: center;
                                align-items: center;
                                background: $white;
                                width: 45px;
                                height: 45px;
                                box-shadow: 0px 0px 15px #00000029;
                                border: 2px solid $white;
                                @include radius(160px);
                                margin: -22px auto 0 auto;
                                position: relative;

                                img {
                                    display: block;
                                    max-width: 100%;
                                    width: auto;
                                    height: 30px;
                                    object-fit: contain;
                                }
                            }
                        }

                        .detail-info-box {
                            width: calc(100% - 115px);
                            @include font(15);
                            font-weight: 400;

                            .name {
                                @include font(16);
                                font-weight: 600;
                                margin-bottom: 8px;
                            }

                            p {
                                line-height: 26px;
                                margin-bottom: 10px;

                                &.star-rating-icons {
                                    svg {
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-right: 2px;
                                    }
                                }
                            }
                        }
                    }

                    .button-wrap {
                        .btn {
                            background: $lightBlue;
                            padding: 10px 15px;
                            min-width: 240px;
                            @include font(18);
                            font-weight: 500;
                            outline: none;
                            border: none;
                            cursor: pointer;
                        }
                    }
                }
            }

            .img-box {
                width: 47%;

                img {
                    display: block;
                    max-width: 100%;
                }
            }
        }
    }

    &.athlete-home-ls {
        padding: 0;

        .common-listing {
            .common-listing-item {
                background: $white;
                padding: 60px 0;

                .content-box {
                    .detail-text {
                        padding-left: 15px;
                    }
                }

                &:nth-child(even) {
                    background: rgba($blackColor, 0.85);
                    flex-direction: inherit;

                    .content-box {
                        margin-right: auto;
                        margin-left: none;

                        .top-block {

                            .tagline,
                            .title {
                                color: $white;
                            }
                        }

                        .detail-text {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

.video-feature-sec {
    background: $white;
    padding: 60px 0;

    .top-block {
        margin-bottom: 50px;

        .title {
            @include font(42);
            font-weight: 600;
            color: $blackColor;
            line-height: 1.4;
            text-align: center;
        }
    }

    .feature-list {
        @include flex();
        flex-wrap: wrap;
        align-items: flex-start;

        .item {
            width: 33.333%;
            padding: 25px;
            text-align: center;
            border-right: 1px solid rgba($gray, .4);
            border-top: 1px solid rgba($gray, .4);

            &:first-child,
            &:nth-child(2),
            &:nth-child(3) {
                border-top: none;
                padding-top: 0;
            }

            &:nth-child(3n+3) {
                border-right: none;
            }

            .icon-box {
                @include flex();
                justify-content: center;
                align-items: center;
                margin: 0 auto 15px;
                min-height: 55px;
            }

            h3 {
                @include font(20);
                font-weight: 600;
                color: $blackColor;
                margin-bottom: 10px;
            }

            p {
                @include font(16);
                font-weight: 400;
                color: rgba($blackColor, .8);
                line-height: 30px;
                margin-top: 0;
            }
        }
    }
}

.hightlight-video-sec {
    background: $white;
    padding: 60px 0;

    .top-block {
        @include flex();
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;

        .title {
            @include font(30);
            font-weight: 600;
            color: $blackColor;
            text-transform: capitalize;
        }

        .filter-box {
            @include flex();
            align-items: center;
            margin-left: 15px;
            width: 250px;

            label {
                @include font(18);
                font-weight: 500;
                color: $black;
                min-width: 80px;
                margin-right: 10px;
            }

            select {
                padding: 13px 40px 13px 10px;
                background-image: url(../img/arrowdown.png);
                background-repeat: no-repeat;
                background-position: 90% center;
                background-size: 16px;
                @include font(16);
                color: $black;
                width: 100%;
                border: 1px solid #ddd;
                -webkit-appearance: none;
                appearance: none;
                @include radius(4px);
            }
        }
    }

    .videos-listing {
        @include flex();
        flex-wrap: wrap;
        margin: 0 -12px 10px;

        .video-box {
            width: 25%;
            padding: 0 12px;
            margin-bottom: 35px;
            cursor: pointer;
            @include transition();

            .video-inner-box {
                background: $white;
                box-shadow: 0px 8px 15px #ABABAB2E;
                @include radius(0 0 20px 20px);
            }

            .img-box {
                position: relative;
                height: 215px;

                video {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include radius(20px);
                }
            }

            .video-play-button {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;

                .video-play-inner {
                    @include flex();
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    button {
                        background: transparent;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        outline: none;
                        border: none;
                    }
                }
            }

            .video-detail-box {
                padding: 15px;
                @include font(16);
                font-weight: 400;
                color: rgba($blackColor, .5);
                @include transition();

                p {
                    line-height: 1.5;
                    margin-bottom: 0;
                }

                .title-info {
                    @include flex();
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    position: relative;

                    h4 {
                        @include font(18);
                        font-weight: 600;
                        color: $black;
                        @include transition();
                    }

                    .views {
                        @include flex();
                        align-items: center;
                        @include font(16);
                        font-weight: 400;
                        color: #CCCCCC;

                        .icon-box {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 8px;
                        }
                    }
                }
            }

            &:hover {
                .video-detail-box {
                    .title-info {
                        h4 {
                            color: $lightBlue;
                        }
                    }
                }
            }
        }
    }

    .btn-wrap {
        text-align: center;

        .btn {
            padding: 10px 20px;
            min-width: 160px;
            @include font(18);
            font-weight: 500;
            color: $white;
            border: none;
            cursor: pointer;

            &.lightblue {
                background: $lightBlue;
                box-shadow: 0px 5px 15px #00000026;
                @include radius(4px);
            }
        }
    }
}

.athlete-team-sec {
    background: url(../img/athlete-team-bg.png) no-repeat;
    background-size: 100% 100%;
    background-position: center top;
    position: relative;

    &:after {
        content: '';
        background: rgba($blackColor, .6);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
    }

    .container {
        position: relative;
        z-index: 11;
    }

    .content-box {
        background: rgba($blackColor, .7);
        padding: 30px;
        @include flex();
        justify-content: center;
        flex-direction: column;
        max-width: 580px;
        min-height: 760px;
        color: $white;
        text-align: left;

        .title {
            @include font(42);
            font-weight: 600;
            color: $white;
            margin-bottom: 20px;
        }

        .short-text {
            @include font(18);
            font-weight: 400;
            color: $white;
            margin-bottom: 30px;

            p {
                line-height: 34px;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .button-wrap {
            button {
                background: $lightBlue;
                display: inline-block;
                vertical-align: middle;
                padding: 15px 20px;
                min-width: 165px;
                @include font(20);
                font-weight: 500;
                color: $white;
                border: 1px solid $lightBlue;
                @include radius(8px);
                cursor: pointer;
                @include transition();
            }
        }
    }
}

.athlete-faq-sec {
    padding: 60px 0;

    .sec-title {
        @include font(42);
        font-weight: 600;
        color: $blackColor;
        margin-bottom: 30px;
    }

    .faq-listing {
        counter-reset: faq;

        li {
            @include transition();
            margin-bottom: 15px;
            border-bottom: 1.5px solid rgba($gray, .50%);
            padding-bottom: 20px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .faq-title {
                @include font(18);
                color: $blackColor;
                position: relative;
                padding-right: 30px;
                line-height: 1.4;
                cursor: pointer;

                span {
                    display: inline-block;
                    vertical-align: middle;
                    color: $baseColor;
                    margin-right: 5px;
                }

                &:after {
                    content: '+';
                    font-size: 35px;
                    color: $blackColor;
                    display: block;
                    width: 28px;
                    height: 28px;
                    line-height: 28px;
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    text-align: center;
                    opacity: .15;
                }

                &.active {
                    color: $baseColor;

                    &:after {
                        content: "";
                        font-size: 0;
                    }

                    &:before {
                        content: "";
                        width: 18px;
                        position: absolute;
                        background: $baseColor;
                        height: 2.8px;
                        right: 0;
                        top: 7px;
                    }
                }
            }

            .faq-data {
                @include font(16);
                color: $black;
                display: none;
                padding-top: 15px;
                line-height: 1.6;
            }
        }
    }
}

.brand-lp-sec {
    background: #1C1A1A;
    padding: 40px 0;

    .brand-listing {
        @include flex();
        align-items: center;
        justify-content: center;

        .item {
            padding: 10px 20px;

            img {
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
            }
        }
    }
}

.success-stories-sec {
    background: #f9f9f9;
    padding: 60px 0;

    &.bg-white {
        background: $white;
    }

    .sec-title {
        @include font(42);
        font-weight: 600;
        color: $blackColor;
        margin-bottom: 50px;
        text-align: center;
    }

    .success-stories-list {
        max-width: 1000px;
        margin: 0 auto;

        .item {
            @include flex();
            align-items: flex-start;

            .pick-box {
                width: 200px;
                margin-right: 50px;

                .pick-img {
                    display: block;
                    max-width: 100%;
                    height: 200px;
                    object-fit: cover;
                    box-shadow: 0px 0px 15px #00000029;
                    border: 5px solid $white;
                    @include radius(15px);
                }

                .icon-box {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    margin: -35px auto 0 auto;

                    img {
                        display: block;
                        max-width: 100%;
                        box-shadow: 0px 0px 15px #00000029;
                        border: 4px solid #FFFFFF;
                        @include radius(159px);
                    }
                }
            }

            .detail-info-box {
                width: calc(100% - 250px);
                padding-left: 40px;

                .short-text {
                    @include font(18);
                    font-weight: 400;
                    color: $black;
                    line-height: 35px;
                    position: relative;

                    &::after {
                        content: '';
                        background: url(../img/quote-left-icon.svg) no-repeat;
                        background-position: center center;
                        position: absolute;
                        width: 91px;
                        height: 68px;
                        left: -40px;
                        top: 0;
                        z-index: 1;
                    }

                    h3 {
                        @include font(24);
                        font-weight: 600;
                        color: $black;
                        margin-bottom: 10px;
                    }

                    p {
                        margin-bottom: 15px;

                        &:last-child {
                            margin-top: 0;
                        }
                    }
                }

                .info-box {
                    padding: 15px 135px 0 0;
                    @include font(16);
                    font-weight: 500;
                    color: rgba($blackColor, .5);
                    position: relative;

                    &::after {
                        content: '';
                        background: url(../img/quote-right-icon.svg) no-repeat;
                        background-position: center center;
                        position: absolute;
                        width: 91px;
                        height: 68px;
                        left: -40px;
                        top: 0;
                        z-index: 1;
                    }

                    h4 {
                        @include font(18);
                        font-weight: 600;
                        color: $baseColor;
                        margin-bottom: 8px;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .owl-nav {
        display: block;
        margin: 0 auto;
        text-align: center;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;

        button {
            background: $white;
            width: 44px;
            height: 44px;
            outline: none;
            margin: 0 10px;
            @include radius(50px);
            box-shadow: 0px 0px 6px #CBCBCB;
            cursor: pointer !important;

            &.owl-next {
                span {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    right: 2px;
                    left: auto;
                }
            }

            span {
                content: "";
                display: inline-block;
                position: relative;
                width: 10px;
                height: 10px;
                font-size: 0;
                border-right: 2px solid #BBBBBB;
                border-bottom: 2px solid #BBBBBB;
                transform: rotate(-0deg);
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -ms-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                left: 2px;
                top: -6px;
                @include transition();
            }
        }
    }
}

.about-mission-sec {
    background: $white;
    padding: 60px 0;

    &.bg-gray {
        background: #f9f9f9;
    }

    .sec-title {
        @include font(42);
        font-weight: 600;
        color: $blackColor;
        margin-bottom: 15px;

        &.font36 {
            @include font(36);
        }
    }

    .postion-info {
        @include font(18);
        font-weight: 600;
        color: $baseColor;
        line-height: 1.5;
        margin-bottom: 12px;

        span {
            display: inline-block;
            text-transform: capitalize;
            font-weight: 500;
            color: rgba($blackColor, .5);
        }
    }

    .short-text {
        @include font(18);
        font-weight: 400;
        color: $black;
        line-height: 1.6;

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .flexbox {
        @include flex();
        justify-content: space-between;
        align-items: center;

        .content-box {
            width: 53%;
            margin-right: 5%;
        }

        .video-box-wrap {
            width: 42%;

            .video-img {
                position: relative;

                img {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    @include radius(6px);
                }

                .video-play-button {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                    cursor: pointer;

                    .video-play-inner {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        height: 100%;

                        .play-btn {
                            @include flex();
                            justify-content: center;
                            align-items: center;
                            background: $white;
                            width: 75px;
                            height: 75px;
                            @include radius(50px);
                            box-shadow: 0px 10px 30px #000000;
                            outline: none;
                            border: none;
                            cursor: pointer;

                            svg {
                                .a {
                                    fill: $baseColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.lp-team-sec {
    background: $white;
    padding: 60px 0;

    &.bg-gray {
        background: #f9f9f9
    }

    .top-block {
        @include font(18);
        font-weight: 400;
        color: $black;
        max-width: 1035px;
        margin: 0 auto 40px;
        text-align: center;

        .title {
            @include font(42);
            font-weight: 600;
            color: $blackColor;
            line-height: 1.4;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 0;
            line-height: 34px;
        }
    }

    .team-list {
        @include flex();
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -10px;

        .item {
            width: 20%;
            padding: 0 10px;
            margin: 60px 0 40px;

            .team-infobox {
                background: $white;
                padding: 15px;
                @include radius(10px);
                border: 1px solid #F1F1F1;
                box-shadow: 0px 0px 10px #DEDEDE4D;

                .pic-box {
                    width: 140px;
                    margin: -85px auto 15px;
                    position: relative;

                    img {
                        display: block;
                        width: 140px;
                        height: 140px;
                        box-shadow: 0px 0px 15px #00000029;
                        object-fit: cover;
                        border: 3px solid $white;
                        @include radius(100px);
                    }

                    .social-icon {
                        position: absolute;
                        width: 30px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        text-align: center;
                        margin: 0 auto;
                        z-index: 1;
                    }
                }

                .detail-info {
                    @include font(13);
                    font-weight: 400;
                    color: rgba($blackColor, .5);
                    text-align: center;

                    .name {
                        @include font(16);
                        font-weight: 500;
                        color: $blackColor;
                        margin-bottom: 30px;
                        position: relative;

                        .position {
                            display: block;
                            @include font(14);
                            font-weight: 500;
                            color: rgba($blackColor, .5);
                            padding-top: 5px;
                        }

                        .text {
                            display: block;
                            @include font(13);
                            font-weight: 400;
                            color: rgba($blackColor, .5);
                            line-height: 20px;
                            padding-top: 5px;
                        }

                        &::after {
                            content: '';
                            background: $lightBlue;
                            position: absolute;
                            width: 30px;
                            height: 3px;
                            left: 0;
                            right: 0;
                            bottom: -12px;
                            margin: 0 auto;
                            z-index: 1;
                        }

                        &.after-none {
                            margin-bottom: 8px;

                            &::after {
                                display: none;
                            }
                        }
                    }

                    .commited-text {
                        @include font(15);
                        font-weight: 600;
                        color: $baseColor;
                        text-transform: uppercase;
                        margin-bottom: 12px;
                    }

                    .university-logo {
                        margin: 0 auto 10px;
                    }

                    p {
                        line-height: 20px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .btn-wrap {
        text-align: center;

        .btn {
            padding: 10px 20px;
            min-width: 180px;
            @include font(18);
            font-weight: 500;
            cursor: pointer;

            &.lightblue {
                background: $lightBlue;
                box-shadow: 0px 5px 15px #00000026;
                @include radius(4px);
            }
        }
    }
}

.lp-map-sec {
    padding-top: 30px;

    .sec-title {
        @include font(42);
        font-weight: 600;
        color: $black;
        margin-bottom: 40px;
        text-align: center;
    }

    .map-box {
        img {
            display: block;
            max-width: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.staff-bio-sec {
    padding: 60px 0;
    margin-top: 60px;

    .flexbox {
        @include flex();
        align-items: flex-start;

        .info-box {
            width: 280px;
            margin-right: 40px;
            text-align: center;

            .pic-box {
                width: 280px;
                margin-bottom: 20px;

                img {
                    display: block;
                    max-width: 100%;
                    width: 280px;
                    height: 280px;
                    object-fit: cover;
                    box-shadow: 0px 0px 15px #AAAAAA99;
                    border: 5px solid #FFFFFF;
                    @include radius(100%);
                }
            }

            .name {
                @include font(24);
                font-weight: 600;
                color: $baseColor;
                margin-bottom: 8px;
            }

            .position {
                @include font(20);
                font-weight: 500;
                color: rgba($blackColor, .5);
                margin-bottom: 12px;
            }

            .social-links {
                @include flex();
                align-items: center;
                justify-content: center;

                li {
                    padding: 0 5px;
                }
            }
        }

        .detail-info {
            width: calc(100% - 320px);
            @include font(18);
            font-weight: 400;
            color: $black;
            line-height: 32px;

            p {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            h4 {
                @include font(24);
                font-weight: 600;
                color: $black;
                margin-bottom: 15px;
            }

            .brands-list {
                @include flex();
                flex-wrap: wrap;
                text-align: center;
                align-items: center;
                margin: 0 -10px;

                li {
                    @include flex();
                    align-items: center;
                    justify-content: center;
                    background: $white;
                    padding: 15px;
                    width: 100px;
                    height: 100px;
                    box-shadow: 0px 5px 8px #F9F9F9;
                    @include radius(8px);
                    margin: 0 10px;

                    img {
                        display: block;
                        max-width: 100%;
                        max-height: 70px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

.common-staff-sec {
    padding: 60px 0;

    &.bg-gray {
        background: #f9f9f9;
    }

    .staff-content-box {
        @include flex();
        align-items: baseline;

        .title {
            @include font(24);
            font-weight: 600;
            color: $black;
            width: 280px;
            margin-right: 40px;
            padding-bottom: 10px;
            margin-bottom: 15px;
            position: relative;

            &::after {
                content: '';
                background: $lightBlue;
                position: absolute;
                width: 30px;
                height: 3px;
                left: 0;
                bottom: 0;
                z-index: 1;
            }
        }

        .short-text {
            width: calc(100% - 320px);
            @include font(18);
            font-weight: 400;
            color: $black;
            line-height: 32px;

            p {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            ul {
                @include flex();
                align-items: flex-start;
                flex-wrap: wrap;
                list-style: none;
                padding-left: 0;

                li {
                    width: 48%;
                    position: relative;
                    margin-right: 4%;
                    padding-left: 40px;
                    margin-bottom: 15px;

                    &:nth-child(2n+2) {
                        margin-right: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &::after {
                        content: '';
                        background: url(../img/check-blue-icon.svg) no-repeat;
                        background-position: center center;
                        position: absolute;
                        width: 24px;
                        height: 22px;
                        left: 0;
                        top: 5px;
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.testimonial-main-sec {
    //background: url(../img/testimonial-bg.png) no-repeat;
    background-size: 100%;
    background-position: center top;
    padding: 80px 0 60px;
    margin-top: 60px;

    .content-box {
        max-width: 1024px;
        margin: 0 auto;

        .text-box {
            position: relative;
            padding: 0 160px;
            color: $white;
            text-align: center;
            margin-bottom: 50px;

            &::after {
                content: '';
                background: url(../img/testimonial-quote-icon.png) no-repeat;
                background-position: center center;
                background-size: 100%;
                position: absolute;
                width: 215px;
                height: 168px;
                left: 0;
                top: 0;
                z-index: 1;

            }

            .title {
                @include font(60);
                font-weight: 600;
                color: $lightBlue;
                margin-bottom: 10px;
            }

            .short-text {
                @include font(20);
                font-weight: 500;
                color: $white;
                line-height: 1.6;

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    .video-box-wrap {
        width: 100%;

        .video-img {
            position: relative;

            img {
                display: block;
                max-width: 100%;
                width: 100%;
            }

            .video-play-button {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;

                .video-play-inner {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    height: 100%;

                    .play-btn {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        background: $white;
                        width: 75px;
                        height: 75px;
                        @include radius(50px);
                        box-shadow: 0px 10px 30px #000000;
                        outline: none;
                        border: none;
                        cursor: pointer;

                        svg {
                            .a {
                                fill: $baseColor;
                            }
                        }
                    }
                }
            }
        }
    }
}

.common-testimonial-sec {
    background: $white;
    padding: 60px 0;

    &.bg-gray {
        background: #f9f9f9
    }

    .top-block {
        @include font(18);
        font-weight: 400;
        color: $black;
        max-width: 1035px;
        margin: 0 auto 40px;
        text-align: center;

        .title {
            @include font(42);
            font-weight: 600;
            color: $blackColor;
            line-height: 1.4;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 0;
            line-height: 34px;
        }
    }

    .videos-listing {
        @include flex();
        flex-wrap: wrap;
        margin: 0 -12px 10px;

        .video-box {
            width: 25%;
            padding: 0 12px;
            margin-bottom: 35px;
            cursor: pointer;
            @include transition();

            .img-box {
                position: relative;
                height: 215px;
                margin-bottom: 20px;

                video {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    box-shadow: 0px 8px 15px #0000002E;
                    @include radius(20px);
                }
            }

            .video-play-button {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;

                .video-play-inner {
                    @include flex();
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    background: #000000;

                    .play-btn {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        background: $white;
                        width: 75px;
                        height: 75px;
                        @include radius(50px);
                        box-shadow: 0px 10px 30px #000000;
                        outline: none;
                        border: none;
                        cursor: pointer;

                        svg {
                            .a {
                                fill: $baseColor;
                            }
                        }
                    }

                    button {
                        background: transparent;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        outline: none;
                        border: none;
                    }
                }
            }

            .video-times {
                padding: 5px 10px;
                min-width: 60px;
                background: rgba($blackColor, .7);
                @include font(15);
                font-weight: 500;
                color: $white;
                text-transform: uppercase;
                @include radius(4px);
                position: absolute;
                right: 15px;
                bottom: 18px;
                z-index: 1;
            }

            .video-detail-box {
                @include font(18);
                font-weight: 500;
                color: $black;
                line-height: 1.5;
                text-align: center;
                @include transition();
            }

            &:hover {
                .video-detail-box {
                    color: $lightBlue;
                }
            }
        }
    }

    .btn-wrap {
        text-align: center;

        .btn {
            padding: 10px 20px;
            min-width: 160px;
            @include font(18);
            font-weight: 500;
            color: $white;
            border: none;
            cursor: pointer;

            &.lightblue {
                background: $lightBlue;
                box-shadow: 0px 5px 15px #00000026;
                @include radius(4px);
            }
        }
    }
}

.average-helped-sec {
    background: #f9f9f9;
    padding: 60px 0;

    .flexbox {
        @include flex();
        align-items: flex-start;

        .title-box {
            width: 450px;
            margin-right: 30px;

            h4 {
                @include font(30);
                font-weight: 600;
                color: $blackColor;
                line-height: 1.5;

                span {
                    display: inline-block;
                    color: $baseColor;
                }
            }
        }

        .short-text {
            width: calc(100% - 480px);
            @include font(16);
            font-weight: 400;
            color: $black;
            line-height: 36px;

            p {
                margin-bottom: 0;
            }
        }
    }
}

.assessment-banner-sec {
    //background: url(../img/assessment-call-banner.png) no-repeat;
    background-size: 100%;
    background-position: center top;
    padding: 80px 0 60px;
    margin-top: 60px;

    .banner-content {
        color: $white;
        text-align: center;
        margin-bottom: 45px;

        .title {
            @include font(45);
            font-weight: 700;
            color: $white;
            margin-bottom: 15px;
        }

        .sub-title {
            @include font(24);
            font-weight: 600;
            color: $white;
            line-height: 1.4;
            margin-bottom: 15px;
        }

        .short-text {
            @include font(18);
            font-weight: 400;
            color: $white;
            line-height: 1.6;
            margin-bottom: 30px;

            p {
                margin-bottom: 0;
            }
        }

        .button-wrap {
            .btn {
                padding: 10px 20px;
                @include font(20);
                font-weight: 500;
                color: $white;
                @include radius(4px);
                cursor: pointer !important;

                &.lightblue {
                    background: $lightBlue;
                    min-width: 180px;
                }

                +p {
                    @include font(14);
                    font-weight: 400;
                    color: $white;
                    padding-top: 15px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .video-box-wrap {
        width: 100%;

        .video-img {
            position: relative;
            max-width: 750px;
            margin: 0 auto;

            img {
                display: block;
                max-width: 100%;
                width: 100%;
            }

            .video-play-button {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;

                .video-play-inner {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    height: 100%;

                    .play-btn {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        background: $white;
                        width: 75px;
                        height: 75px;
                        @include radius(50px);
                        box-shadow: 0px 10px 30px #000000;
                        outline: none;
                        border: none;
                        cursor: pointer;

                        svg {
                            .a {
                                fill: $baseColor;
                            }
                        }
                    }
                }
            }
        }
    }
}

.assessment-common-sec {
    padding: 60px 0;

    &.assessment-question {
        background: url(../img/assessment-question-bg.png) no-repeat;
        background-size: cover;
        background-position: center top;
    }

    .flexbox {
        @include flex();
        align-items: flex-start;

        .item {
            width: 400px;
            margin-right: 40px;

            &:last-child {
                width: calc(100% - 440px);
                margin-right: 0;
            }
        }
    }

    .tagline {
        display: inline-block;
        vertical-align: middle;
        @include font(16);
        font-weight: 600;
        color: $baseColor;
        text-transform: uppercase;
        margin-bottom: 15px;
        position: relative;
        padding-right: 80px;

        &::after {
            content: '';
            background: $baseColor;
            position: absolute;
            width: 60px;
            height: 2px;
            right: 0;
            top: 7px;
            z-index: 1;
        }
    }

    .sec-title {
        @include font(30);
        font-weight: 600;
        color: $blackColor;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    .short-text {
        @include font(16);
        font-weight: 400;
        color: $black;
        line-height: 35px;
        margin-bottom: 15px;

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .listing-box {
        @include flex();
        flex-wrap: wrap;
        margin: 0 -15px;

        .item {
            width: 50%;
            padding: 0 15px;
            margin-bottom: 30px;

            .sec-title {
                margin-bottom: 0;
            }

            .inner-box {
                @include flex();
                align-items: center;
                background: $white;
                padding: 15px;
                box-shadow: 0px 3px 6px #E2E2E2;
                @include radius(10px);
                margin-left: 20px;
                min-height: 90px;

                .icon-box {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    background: $lightBlue;
                    width: 55px;
                    height: 55px;
                    margin-right: 10px;
                    margin-left: -35px;
                    @include radius(15px);
                }

                .info-box {
                    @include font(18);
                    font-weight: 500;
                    color: $blackColor;
                    line-height: 30px;
                }
            }
        }
    }
}

.assessment-result-sec {
    background: $white;
    padding: 60px 0;

    .top-block {
        margin-bottom: 40px;
        text-align: center;

        .sec-title {
            @include font(36);
            font-weight: 700;
            color: $blackColor;
            margin-bottom: 0;
        }
    }

    .timeline {
        position: relative;
        padding: 30px 0;
        list-style-type: none;
        overflow: hidden;

        &::before {
            content: " ";
            position: absolute;
            background-color: $lightBlue;
            width: 1px;
            top: 0;
            bottom: 0;
            left: 50%;
            margin-left: .5px;
        }

        >li {
            margin-bottom: 20px;
            position: relative;
            clear: both;

            .timeline-data {
                float: left;
                width: 40%;
                position: relative;
                text-align: right;
                margin-bottom: 30px;

                .step-title {
                    @include font(18);
                    font-weight: 600;
                    color: $baseColor;
                    line-height: 1.33;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    position: relative;

                    &::after {
                        content: '';
                        background: url(../img/time-line.svg) no-repeat;
                        background-position: center center;
                        background-size: 100px 38px;
                        width: 100px;
                        height: 38px;
                        position: absolute;
                        right: -127px;
                        top: -8px;
                        z-index: 1;
                    }
                }

                .title {
                    @include font(18);
                    font-weight: 600;
                    color: $blackColor;
                    line-height: 1.33;
                    text-transform: capitalize;
                    margin-bottom: 10px;
                }

                p {
                    @include font(16);
                    font-weight: 400;
                    color: $black;
                    line-height: 32px;
                }
            }

            &:nth-child(even) {
                .timeline-data {
                    float: right;
                    text-align: left;

                    .step-title {
                        &::after {
                            transform: rotate(180deg);
                            left: -127px;
                        }
                    }
                }
            }

            &:last-child {
                .timeline-data {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.about-coaches-sec {
    background: #f9f9f9;
    padding: 65px 0;

    .sec-title {
        @include font(36);
        font-weight: 600;
        color: $black;
        line-height: 1.333;
        margin-bottom: 45px;
        text-align: center;
    }

    .coaches-list {
        .item {
            background: $white;
            padding: 25px 15px;
            border: 1px solid #DCDCDC;
            box-shadow: 0px 8px 15px #E2E2E2;
            @include radius(15px);
            text-align: center;

            .icon-box {
                width: 100px;
                margin: 0 auto 15px;

                img {
                    display: block;
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border: 4px solid $white;
                    box-shadow: 0px 0px 15px #00000029;
                    @include radius(100px);
                }
            }

            .short-text {
                @include font(16);
                font-weight: 400;
                color: $black;
                line-height: 35px;
                margin-bottom: 15px;
                position: relative;
                min-height: 210px;

                &::before {
                    content: '';
                    background: url(../img/quote-gray-icon.svg) no-repeat;
                    background-position: center left;
                    position: absolute;
                    width: 90px;
                    height: 68px;
                    left: 0;
                    top: 0;
                    z-index: 1;
                }

                &::after {
                    content: '';
                    background: url(../img/quote-gray-small-icon.svg) no-repeat;
                    background-position: center left;
                    position: absolute;
                    width: 30px;
                    height: 22px;
                    right: 0;
                    bottom: 8px;
                    z-index: 1;
                }

                p {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            h4 {
                @include font(18);
                font-weight: 500;
                color: $baseColor;
                margin-bottom: 10px;
            }

            h5 {
                @include font(16);
                font-weight: 500;
                color: rgba($blackColor, .5);
                margin-bottom: 0;
            }
        }
    }

    .owl-nav {
        display: block !important;
        margin: 40px auto 0;
        text-align: center;

        button {
            background: $white;
            width: 44px;
            height: 44px;
            outline: none;
            margin: 0 10px;
            @include radius(50px);
            box-shadow: 0px 0px 6px #CBCBCB;
            cursor: pointer !important;

            &.owl-next {
                span {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    right: 2px;
                    left: auto;
                }
            }

            span {
                content: "";
                display: inline-block;
                position: relative;
                width: 10px;
                height: 10px;
                font-size: 0;
                border-right: 2px solid #BBBBBB;
                border-bottom: 2px solid #BBBBBB;
                transform: rotate(-0deg);
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -ms-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                left: 2px;
                top: -6px;
                @include transition();
            }
        }
    }
}

.video-modal {
    .modal-content {
        border: none;
        -webkit-box-shadow: 0px 3px 20px #00000080;
        box-shadow: 0px 3px 20px #00000080;
    }

    .modal-body {
        padding: 15px;

        iframe {
            width: 100% !important;
            display: inline-block;
            vertical-align: middle;
            height: 255px !important;
            overflow: hidden;
        }

        video {
            width: 100% !important;
            display: inline-block;
            vertical-align: middle;
            object-fit: cover;
            height: 255px !important;
            overflow: hidden;
        }
    }

    .MuiDialog-paperWidthSm {
        width: 500px !important;
    }
}

.carrer-main-sec {
    padding: 50px 0 60px;
    margin-top: 60px;

    .top-block {
        margin-bottom: 45px;
        text-align: center;

        .title {
            @include font(42);
            font-weight: 600;
            color: $blackColor;
            line-height: 60px;
            text-transform: capitalize;
            margin-bottom: 20px;

            span {
                display: block;
                font-weight: 400;
                color: rgba($blackColor, .3);
            }
        }

        .sub-title {
            @include font(16);
            font-weight: 600;
            color: $baseColor;
            text-transform: uppercase;
        }
    }

    .flexbox-wrap {
        @include flex();
        justify-content: space-between;
        align-content: center;

        .banner-content {
            .btn {
                color: #fff;
            }

            width: 45%;
            margin-right: 10%;

            .short-text {
                @include font(20);
                color: $black;
                line-height: 30px;

                p {
                    margin-bottom: 20px;
                }

                ul {
                    @include font(18);
                    margin-bottom: 25px;

                    li {
                        position: relative;
                        padding-left: 40px;
                        margin-bottom: 12px;

                        &::after {
                            content: '';
                            background: url(../img/question-circle-icon.svg) no-repeat;
                            background-position: center center;
                            position: absolute;
                            width: 24px;
                            height: 24px;
                            left: 0;
                            top: 4px;
                            z-index: 1;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .video-box-wrap {
            width: 45%;

            .video-img {
                position: relative;

                img {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                }

                a {
                    background: #000000;
                    display: block;
                    max-width: 100%;
                    width: 100%;
                }

                video {
                    background: #000000;
                    display: block;
                    max-width: 100%;
                    width: 100%;
                }

                .video-play-button {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                    cursor: pointer;

                    .video-play-inner {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        height: 100%;

                        .play-btn {
                            @include flex();
                            justify-content: center;
                            align-items: center;
                            background: $white;
                            width: 75px;
                            height: 75px;
                            @include radius(50px);
                            box-shadow: 0px 10px 30px #000000;
                            outline: none;
                            border: none;
                            cursor: pointer;

                            svg {
                                .a {
                                    fill: $baseColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.carrer-advisors-sec {
    background: $white;
    padding: 60px 0;

    .top-block {
        text-align: center;
        margin-bottom: 50px;

        .title {
            @include font(16);
            font-weight: 600;
            color: $baseColor;
            text-transform: uppercase;
            padding-bottom: 10px;
            margin-bottom: 15px;
            position: relative;

            &::after {
                content: '';
                background: $baseColor;
                position: absolute;
                width: 100px;
                height: 2px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                z-index: 1;
            }
        }

        .sub-title {
            @include font(30);
            font-weight: 600;
            color: $blackColor;
            line-height: 50px;
        }
    }

    .timeline-items {
        @include flex();
        justify-content: center;
        align-items: flex-start;
        border-top: 1px solid rgba($gray, .5);

        .item {
            width: 20%;
            padding: 80px 15px 0;
            position: relative;

            &::after {
                content: '';
                background: $white;
                position: absolute;
                width: 22px;
                height: 22px;
                left: 0;
                right: 0;
                top: -11px;
                border: 1px solid rgba($gray, .5);
                @include radius(50px);
                margin: 0 auto;
                z-index: 1;
            }

            &::before {
                content: '';
                background: $lightBlue;
                position: absolute;
                width: 14px;
                height: 14px;
                left: 0;
                right: 0;
                top: -7px;
                @include radius(50px);
                margin: 0 auto;
                z-index: 11;
            }

            .timeline-data {
                @include font(16);
                font-weight: 400;
                color: $blackColor;
                line-height: 30px;
                text-align: center;
                position: relative;

                &::after {
                    content: '';
                    background: rgba($gray, .5);
                    position: absolute;
                    width: 1px;
                    height: 60px;
                    left: 0;
                    right: 0;
                    top: -80px;
                    margin: 0 auto;
                    z-index: 1;
                }

                .title {
                    @include font(20);
                    font-weight: 600;
                    color: $lightBlue;
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.carrer-preferred-sec {
    background: #232526;
    padding: 60px 0;

    .top-block {
        text-align: center;
        margin-bottom: 20px;

        .title {
            @include font(16);
            font-weight: 600;
            color: #E1E1E1;
            text-transform: uppercase;
            padding-bottom: 10px;
            margin-bottom: 15px;
            position: relative;

            &::after {
                content: '';
                background: #E1E1E1;
                position: absolute;
                width: 100px;
                height: 2px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                z-index: 1;
            }
        }

        .sub-title {
            @include font(36);
            font-weight: 600;
            color: #E1E1E1;
            line-height: 42px;
        }
    }

    .preferred-list {
        @include flex();
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 -20px;

        .item {
            width: 33.333%;
            padding: 0 20px;
            margin-top: 40px;

            .icon-box {
                min-height: 62px;
                margin-bottom: 20px;
            }

            .detail-box {
                @include font(18);
                font-weight: 400;
                color: #989898;

                h4 {
                    @include font(22);
                    font-weight: 600;
                    color: #E1E1E1;
                    margin-bottom: 10px;
                }

                p {
                    line-height: 30px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.carrer-compensation-sec {
    background: $white;
    padding: 60px 0;

    .title {
        @include font(42);
        font-weight: 700;
        color: $blackColor;
        text-align: center;
        margin-bottom: 40px;
    }

    .short-text {
        @include font(18);
        font-weight: 400;
        color: $black;
        line-height: 36px;
        margin-bottom: 20px;

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            @include flex();
            align-items: flex-start;
            flex-wrap: wrap;
            list-style: none;
            padding-left: 0;

            li {
                width: 48%;
                position: relative;
                margin-right: 4%;
                padding-left: 40px;
                margin-bottom: 15px;

                &:nth-child(2n+2) {
                    margin-right: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::after {
                    content: '';
                    background: url(../img/check-blue-icon.svg) no-repeat;
                    background-position: center center;
                    position: absolute;
                    width: 24px;
                    height: 22px;
                    left: 0;
                    top: 8px;
                    z-index: 1;
                }
            }
        }
    }

    .button-wrap {
        text-align: center;

        .btn {
            background: $lightBlue;
            @include font(16);
            font-weight: 500;
        }
    }
}

.carrer-social-sec {
    background: #F9F9F9;
    padding: 60px 0;
    text-align: center;

    .title {
        @include font(42);
        font-weight: 700;
        color: $blackColor;
        margin-bottom: 20px;
    }

    .short-text {
        @include font(18);
        font-weight: 400;
        color: $black;
        line-height: 40px;

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .social-links {
            @include flex();
            align-items: center;
            justify-content: center;

            li {
                margin: 5px 8px;

                a {
                    display: inline-block;
                    vertical-align: middle;
                    text-decoration: none;
                }
            }
        }
    }
}

.interview-survey-sec {
    background: $white;
    padding: 60px 0;
    margin-top: 60px;

    .top-block {
        margin-bottom: 40px;
        text-align: center;

        .brand-logo {
            margin-bottom: 15px;

            a {
                display: block;

                img {
                    max-width: 100%;
                }
            }
        }

        .sec-title {
            @include font(36);
            font-weight: 600;
            color: $blackColor;
        }
    }

    .survey-form-box {
        background: $white;
        padding: 25px 20px 25px;
        border: 1px solid rgba($black, .2);
        @include radius(10px);

        form {
            >div {
                margin-bottom: 20px;
            }

            .fieldBox {
                &.flexBox {
                    @include flex();
                    align-items: center;
                    justify-content: space-between;

                    .half {
                        width: calc(50% - 10px);
                    }
                }

                &.phone {
                    .flexBox {
                        @include flex();
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 10px;
                        border: 1px solid $gray;
                        @include radius(4px);

                        .countryFlag {
                            width: 35px;

                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }

                        select {
                            padding: 5px 15px 5px 5px;
                            background: none;
                            outline: none;
                            border: none;
                            color: $black;
                            font-size: 14px;
                            -webkit-appearance: button;
                            appearance: button;
                            width: 70px;
                        }

                        input {
                            width: calc(100% - 125px);
                            border: none;
                        }
                    }
                }

                label {
                    display: block;
                    @include font(15);
                    font-weight: 400;
                    color: $black;
                    line-height: 1.4;
                    margin-bottom: 10px;

                    sup {
                        display: inline-block;
                        vertical-align: -6px;
                        font-weight: 600;
                        color: $baseColor;
                        margin-left: 3px;
                    }
                }

                input {
                    padding: 10px 10px;
                    width: 100%;
                    @include font(15);
                    color: $black;
                    border: 1px solid rgba($black, .2);
                    outline: none;
                    box-shadow: none;
                    @include radius(4px);

                    &::placeholder {
                        color: rgba($black, .3);
                    }
                }

                select {
                    padding: 13px 45px 13px 10px;
                    background-image: url(../img/arrowdown.png);
                    background-repeat: no-repeat;
                    background-position: right 15px center;
                    background-size: 16px;
                    @include font(15);
                    color: $black;
                    width: 100%;
                    border: 1px solid rgba($black, .2);
                    -webkit-appearance: none;
                    appearance: none;
                    @include radius(4px);
                }

                textarea {
                    padding: 10px 10px;
                    width: 100%;
                    min-height: 90px;
                    @include font(15);
                    color: $black;
                    line-height: 1.5;
                    border: 1px solid rgba($black, .2);
                    outline: none;
                    box-shadow: none;
                    @include radius(4px);

                    &::placeholder {
                        color: rgba($black, .3);
                    }
                }

                .checkbox-list {
                    @include flex();
                    flex-wrap: wrap;
                    align-items: center;

                    li {
                        display: inline-flex;
                        align-items: center;
                        position: relative;
                        margin-right: 20px;

                        input[type="checkbox"] {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            opacity: 0;
                            z-index: 1;
                            @include radius(4px);
                            cursor: pointer;

                            &:checked {
                                +label {
                                    &::before {
                                        border-color: $lightBlue;
                                    }

                                    &::after {
                                        content: "";
                                        display: inline-block;
                                        background: url(../../../../assets/img/checkBlue.png) no-repeat;
                                        background-size: cover;
                                        position: absolute;
                                        width: 12px;
                                        height: 12px;
                                        left: 3px;
                                        top: 5px;
                                    }
                                }
                            }
                        }

                        label {
                            padding-left: 30px;
                            position: relative;
                            @include font(15);
                            font-weight: normal;
                            color: $black;
                            line-height: 18px;
                            cursor: pointer;

                            &::before {
                                content: "";
                                display: inline-block;
                                position: absolute;
                                width: 18px;
                                height: 18px;
                                left: 0;
                                top: 0;
                                border: 1px solid rgba($black, .2);
                                @include radius(4px);
                            }
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .btn {
                    padding: 10px 15px;
                    min-width: 300px;
                    @include font(18);
                    font-weight: 400;
                    @include radius(4px);
                    cursor: pointer;
                }
            }
        }
    }
}

.scholarship-banner-lp {
    background: url(../img/athletic-scholarships-banner.png) no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 80px 0;
    margin-top: 60px;
    margin-bottom: 80px;

    .banner-content {
        margin-bottom: 80px;

        .title {
            @include font(60);
            font-weight: 400;
            color: $white;
            text-align: center;
            margin-bottom: 20px;

            span {
                display: block;
                font-weight: 600;
                color: $lightBlue;
            }
        }

        .short-text {
            display: block;
            max-width: 760px;
            margin: 0 auto;
            @include font(18);
            font-weight: 400;
            color: $white;
            line-height: 36px;
            text-align: center;

            p {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .MuiOutlinedInput-notchedOutline legend {
        position: absolute;
        z-index: -1;
    }

    .form-box {
        padding-top: 60px;
        background: #131B1E;
        padding: 30px;
        @include radius(15px);
        margin-bottom: -150px;
        position: relative;

        .form-title {
            @include font(24);
            font-weight: 500;
            color: $white;
            margin-bottom: 15px;
            text-align: center;
        }

        .flex-box {
            @include flex();
            align-items: center;

            .item {
                width: 26.666%;
                margin-right: 2%;

                select {
                    display: block;
                    background: #131B1E;
                    padding: 10px 35px 10px 15px;
                    @include font(16);
                    color: $white;
                    width: 100%;
                    border: 1px solid #FFFFFF4F;
                    @include radius(4px);
                }

                .btn {
                    background: $lightBlue;
                    @include font(16);
                    font-weight: 500;
                    @include radius(4px);
                    cursor: pointer;
                }

                &:last-child {
                    width: 15%;
                    margin-right: 0;
                }
            }
        }
    }
}

.common-scholarship-sec {
    background: $white;
    margin-top: 80px;
    padding: 60px 0;

    &.bg-gray {
        background: #F9F9F9;
        margin-top: 0;
    }

    .sec-title {
        max-width: 600px;
        @include font(36);
        font-weight: 600;
        color: $blackColor;
        line-height: 48px;
        padding-bottom: 15px;
        margin: 0 auto 25px;
        position: relative;
        text-align: center;

        &::after {
            content: '';
            background: $baseColor;
            position: absolute;
            width: 100px;
            height: 2px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
            z-index: 1;
        }
    }

    .short-text {
        @include font(18);
        font-weight: 400;
        color: $black;
        line-height: 36px;

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .sport-listing {
        @include flex();
        align-items: center;
        flex-wrap: wrap;
        margin: 0 5px;

        li {
            width: 25%;
            padding: 5px;

            a {
                @include flex();
                align-items: center;
                text-decoration: none;
                background: $white;
                padding: 10px;
                @include font(14);
                font-weight: 400;
                color: $black;
                border: 2px solid #EFEFEF;
                box-shadow: 0px 5px 8px #3E3E3E0A;
                @include radius(15px);
                @include transition();
                cursor: pointer;

                .icon {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 8px;

                    svg {
                        display: inline-block;
                        vertical-align: middle;
                        width: 22px;
                        @include transition();
                    }
                }

                &:hover {
                    color: $baseColor;
                    border-color: $baseColor;

                    .icon {
                        svg {
                            path {
                                fill: $baseColor;
                            }
                        }
                    }
                }
            }
        }
    }
}

.athletic-dream-sec {
    background: url(../img/banner-athlete.png) no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 80px 0;

    .flex-box {
        @include flex();
        max-width: 900px;
        margin: 0 auto;

        .content-box {
            width: 50%;
            @include font(18);
            font-weight: 400;
            color: $white;

            .title {
                @include font(42);
                font-weight: 300;
                color: $white;
                line-height: 50px;
                margin-bottom: 15px;

                span {
                    display: block;
                    font-weight: 600;
                }
            }

            .short-text {
                margin-bottom: 0;
                line-height: 34px;

                p {
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .form-box {
            width: 45%;
            margin-left: 5%;
            padding-left: 5%;
            border-left: 1px solid #FFFFFF4F;

            .item {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                select {
                    display: block;
                    background: transparent;
                    padding: 10px 35px 10px 15px;
                    @include font(16);
                    color: $white;
                    width: 100%;
                    border: 1px solid #FFFFFF4F;
                    @include radius(4px);

                    option {
                        color: $black;
                    }
                }

                .btn {
                    display: block;
                    width: 100%;
                    background: $lightBlue;
                    @include font(16);
                    font-weight: 500;
                    @include radius(4px);
                    cursor: pointer;
                }
            }
        }
    }
}

.blog-lp-sec {
    background: #F9F9F9;
    padding: 70px 0;

    .sec-title {
        @include font(42);
        font-weight: 600;
        color: $blackColor;
        margin-bottom: 40px;
        text-align: center;
    }

    .blog-listing {
        @include flex();
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -25px;

        .item {
            width: 33.333%;
            padding: 0 25px;

            .thumbnail-box {
                display: block;
                margin-bottom: 20px;

                img {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    box-shadow: 0px 5px 15px #C8C8C8;
                    @include radius(5px);
                }
            }

            .detail-box {
                @include font(16);
                font-weight: 400;
                color: $black;
                line-height: 30px;

                .info-box {
                    @include flex();
                    justify-content: space-between;
                    align-items: center;
                    @include font(14);
                    font-weight: 400;
                    color: #A1A1A1;
                    margin-bottom: 12px;
                }

                .title {
                    @include font(18);
                    font-weight: 400;
                    color: $blackColor;
                    line-height: 30px;
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 10px;
                }

                .read-morelink {
                    display: inline-block;
                    vertical-align: middle;
                    text-decoration: underline;
                    @include font(18);
                    font-weight: 500;
                    color: $lightBlue;
                    @include transition();

                    &:hover {
                        color: $baseColor;
                    }
                }
            }
        }
    }
}

.division-lp-sec {
    background: $white;
    padding: 70px 0;

    &.bg-gray {
        background: #F9F9F9;
        margin-top: 60px;
    }

    .division-number-list {
        @include flex();
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -15px;
        position: relative;
        margin-top: -140px;
        margin-bottom: 30px;

        .item {
            width: 16.666%;
            padding: 0 15px;
            margin-bottom: 30px;

            .info-box {
                @include flex();
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background: $white;
                padding: 15px;
                @include font(18);
                font-weight: 500;
                color: $black;
                text-transform: uppercase;
                box-shadow: 0px 15px 30px #ACACAC42;
                @include radius(8px);

                h4 {
                    @include font(60);
                    font-weight: 700;
                    color: $lightBlue;
                    margin-bottom: 5px;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    .division-listing {
        @include flex();
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -25px;

        .item {
            width: 33.333%;
            padding: 0 25px;

            .title {
                @include font(24);
                font-weight: 600;
                color: $blackColor;
                line-height: 40px;
                margin-bottom: 10px;
                position: relative;

                &.second {
                    color: $black;
                    margin-top: 10px;
                    padding-bottom: 10px;

                    &::after {
                        content: '';
                        background: $lightBlue;
                        position: absolute;
                        width: 40px;
                        height: 4px;
                        left: 0;
                        bottom: 0;
                        z-index: 1;
                    }
                }
            }

            p {
                @include font(16);
                font-weight: 400;
                color: $black;
                line-height: 30px;
                margin-bottom: 20px;
            }

            .read-more {
                display: inline-block;
                vertical-align: middle;
                text-decoration: none;
                padding: 12px 20px;
                @include font(18);
                color: $lightBlue;
                min-width: 220px;
                box-shadow: 0px 5px 8px #3E3E3E0A;
                border: 1px solid #98989852;
                @include radius(4px);
                text-align: center;
                @include transition();

                &:hover {
                    background: $lightBlue;
                    color: $white;
                    border-color: $lightBlue;
                }
            }
        }
    }

    &.bg-black {
        background: #232526;

        .division-listing {
            .item {
                .title {
                    color: #E1E1E1;
                }

                p {
                    color: #989898;
                }
            }
        }
    }
}

//14/7/2022 price and contact lp scss
.place-order-sec {
    background: #ffffff;
    padding: 60px 0;
    margin-top: 60px;
}

.place-order-sec .top-block {
    margin-bottom: 40px;
    text-align: center;
}

.place-order-sec .top-block .sec-title {
    font-size: 3rem;
    font-weight: 700;
    color: #000;
}

.place-order-sec .order-form-box {
    background: #ffffff;
    padding: 25px 20px 30px;
    -webkit-box-shadow: 0px 5px 15px #E6E6E694;
    box-shadow: 0px 5px 15px #E6E6E694;
    border: 1px solid #E6E6E666;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
}

.place-order-sec .order-form-box form .fieldbox-row {
    margin-bottom: 20px;
}

.place-order-sec .order-form-box form .title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #3E3E3E;
    padding-bottom: 15px;
    margin-bottom: 18px;
    border-bottom: 1px solid #f2f2f2;
}

.place-order-sec .order-form-box form .fieldBox.flexBox {
    display: -webkit-box;
    //display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 -10px;
}

.place-order-sec .order-form-box form .fieldBox.flexBox .half {
    width: 50%;
    padding: 0 10px;
    margin-bottom: 20px;
}

.place-order-sec .order-form-box form .fieldBox.flexBox .half .fieldBox .half {
    margin-bottom: 0;
}

.place-order-sec .order-form-box form .fieldBox.flexBox .one-four {
    width: 25%;
    padding: 0 10px;
    margin-bottom: 20px;
}

.place-order-sec .order-form-box form .fieldBox.flexBox .one-four .fieldBox .half {
    margin-bottom: 0;
}

.place-order-sec .order-form-box form .fieldBox.phone .flexBox {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

.place-order-sec .order-form-box form .fieldBox.phone .flexBox .countryFlag {
    width: 35px;
}

.place-order-sec .order-form-box form .fieldBox.phone .flexBox .countryFlag img {
    max-width: 100%;
    height: auto;
}

.place-order-sec .order-form-box form .fieldBox.phone .flexBox select {
    padding: 5px 15px 5px 5px;
    background: none;
    outline: none;
    border: none;
    color: #3E3E3E;
    font-size: 14px;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    width: 70px;
}

.place-order-sec .order-form-box form .fieldBox.phone .flexBox input {
    width: calc(100% - 125px);
    border: none;
}

.place-order-sec .order-form-box form .fieldBox h4 {
    font-size: 0.875rem;
    font-weight: 600;
    color: #3E3E3E;
    line-height: 1.4;
    margin-bottom: 10px;
}

.place-order-sec .order-form-box form .fieldBox .package-info-box {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.place-order-sec .order-form-box form .fieldBox .package-info-box .item {
    width: calc(50% - 10px);
    margin-bottom: 12px;
}

.place-order-sec .order-form-box form .fieldBox .package-info-box h5 {
    font-size: 1rem;
    font-weight: 500;
    color: #3E3E3E;
    line-height: 1.4;
    margin-bottom: 10px;
}

.place-order-sec .order-form-box form .fieldBox .package-info-box p {
    font-size: 1.125rem;
    font-weight: 600;
    color: #00B9FF;
    margin-bottom: 0;
}

.place-order-sec .order-form-box form .fieldBox label {
    display: block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #3E3E3E;
    line-height: 1.4;
    margin-bottom: 10px;
}

.place-order-sec .order-form-box form .fieldBox label sup {
    display: inline-block;
    vertical-align: -6px;
    font-weight: 600;
    color: #C0321F;
    margin-left: 3px;
}

.place-order-sec .order-form-box form .fieldBox input {
    padding: 10px 10px;
    width: 100%;
    font-size: 0.9375rem;
    color: #3E3E3E;
    border: 1px solid rgba(62, 62, 62, 0.2);
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

.place-order-sec .order-form-box form .fieldBox input::-webkit-input-placeholder {
    color: rgba(62, 62, 62, 0.4);
}

.place-order-sec .order-form-box form .fieldBox input:-ms-input-placeholder {
    color: rgba(62, 62, 62, 0.4);
}

.place-order-sec .order-form-box form .fieldBox input::-ms-input-placeholder {
    color: rgba(62, 62, 62, 0.4);
}

.place-order-sec .order-form-box form .fieldBox input::placeholder {
    color: rgba(62, 62, 62, 0.4);
}

.place-order-sec .order-form-box form .fieldBox input.credit-cart-field {
    background: url(../img/credit-card-icon.svg) no-repeat;
    background-position: 15px center;
    padding-left: 45px;
}

.place-order-sec .order-form-box form .fieldBox select {
    padding: 13px 45px 13px 10px;
    background-image: url(../img/arrowdown.png);
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 16px;
    font-size: 0.9375rem;
    color: #3E3E3E;
    width: 100%;
    border: 1px solid rgba(62, 62, 62, 0.2);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

.place-order-sec .order-form-box form .fieldBox textarea {
    padding: 10px 10px;
    width: 100%;
    min-height: 90px;
    font-size: 0.9375rem;
    color: #3E3E3E;
    line-height: 1.5;
    border: 1px solid rgba(62, 62, 62, 0.2);
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

.place-order-sec .order-form-box form .fieldBox textarea::-webkit-input-placeholder {
    color: rgba(62, 62, 62, 0.4);
}

.place-order-sec .order-form-box form .fieldBox textarea:-ms-input-placeholder {
    color: rgba(62, 62, 62, 0.4);
}

.place-order-sec .order-form-box form .fieldBox textarea::-ms-input-placeholder {
    color: rgba(62, 62, 62, 0.4);
}

.place-order-sec .order-form-box form .fieldBox textarea::placeholder {
    color: rgba(62, 62, 62, 0.4);
}

.place-order-sec .order-form-box form .fieldBox .checkbox-list {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 10px;
}

.place-order-sec .order-form-box form .fieldBox .checkbox-list li {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-right: 25px;
}

.place-order-sec .order-form-box form .fieldBox .checkbox-list li input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    cursor: pointer;
}

.place-order-sec .order-form-box form .fieldBox .checkbox-list li input[type="checkbox"]:checked+label::before {
    border-color: #00B9FF;
}

.place-order-sec .order-form-box form .fieldBox .checkbox-list li input[type="checkbox"]:checked+label::after {
    content: "";
    display: inline-block;
    background: url(../../../../assets/img/checkBlue.png) no-repeat;
    background-size: cover;
    position: absolute;
    width: 12px;
    height: 12px;
    left: 3px;
    top: 5px;
}

.place-order-sec .order-form-box form .fieldBox .checkbox-list li label {
    padding-left: 30px;
    position: relative;
    font-size: 0.9375rem;
    font-weight: normal;
    color: #3E3E3E;
    line-height: 18px;
    cursor: pointer;
}

.place-order-sec .order-form-box form .fieldBox .checkbox-list li label a {
    text-decoration: none;
    font-weight: 500;
    color: #C0321F;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
}

.place-order-sec .order-form-box form .fieldBox .checkbox-list li label a:hover {
    text-decoration: underline;
}

.place-order-sec .order-form-box form .fieldBox .checkbox-list li label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    top: 0;
    border: 1px solid rgba(62, 62, 62, 0.2);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

.place-order-sec .order-form-box form .fieldBox .btn {
    padding: 10px 15px;
    min-width: 150px;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    cursor: pointer;
}

.place-order-sec .order-form-box form .fieldBox .btn.lightblue {
    background: #00B9FF;
}

.contact-banner-sec {
    background: url(../img/contact-banner.jpg) no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 60px 0;
    margin-top: 60px;
    height: 300px;

    .container {
        height: 100%;
    }

    .content-box {
        @include flex();
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        height: 100%;

        .title {
            @include font(42);
            font-weight: 600;
            color: rgba($white, .9);
            margin-bottom: 15px;
        }

        .short-text {
            max-width: 810px;
            margin: 0 auto;
            @include font(20);
            font-weight: 400;
            color: rgba($white, .9);
            line-height: 34px;

            p {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

    }
}

.contact-lp-sec {
    background: $white;
    padding: 60px 0;

    .contact-flexbox {
        @include flex();
        justify-content: space-between;
        align-items: flex-start;

        .contact-detail-block {
            width: 50%;
            margin-right: 5%;

            .item {
                @include font(16);
                font-weight: 400;
                color: $black;
                line-height: 30px;
                padding-bottom: 25px;
                margin-bottom: 25px;
                border-bottom: 2px solid #F3F3F3;

                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }

                .title {
                    @include font(24);
                    font-weight: 600;
                    color: $black;
                    line-height: 40px;
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 10px;
                }

                .contact-info-list {
                    li {
                        display: block;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            display: inline-block;
                            vertical-align: middle;
                            text-decoration: none;
                            position: relative;
                            padding-left: 30px;
                            color: $baseColor;
                            line-height: 1.1;
                            @include transition();

                            .icon {
                                display: inline-block;
                                vertical-align: middle;
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 1;
                            }

                            &:hover {
                                opacity: .8;
                            }
                        }
                    }
                }
            }
        }

        .contact-form-block {
            background: $white;
            padding: 35px 30px;
            box-shadow: 0px 0px 8px #F3F3F3;
            border: 1px solid #F8F8F8;
            @include radius(8px);
            width: 45%;

            .title {
                @include font(24);
                font-weight: 600;
                color: $black;
                text-align: center;
                margin-bottom: 20px;
            }

            .fieldBox {
                margin-bottom: 20px;

                &.flexBox {
                    @include flex();
                    align-items: center;
                    justify-content: space-between;

                    .half {
                        width: calc(50% - 10px);
                    }
                }

                label {
                    display: block;
                    @include font(15);
                    font-weight: 400;
                    color: $black;
                    line-height: 1.4;
                    margin-bottom: 10px;

                    sup {
                        display: inline-block;
                        vertical-align: -6px;
                        font-weight: 600;
                        color: $baseColor;
                        margin-left: 3px;
                    }
                }

                input {
                    padding: 10px 10px;
                    width: 100%;
                    @include font(15);
                    color: $black;
                    border: 1px solid rgba($black, .2);
                    outline: none;
                    box-shadow: none;
                    @include radius(4px);

                    &::placeholder {
                        color: rgba($black, .3);
                    }
                }

                select {
                    padding: 13px 45px 13px 10px;
                    background-image: url(../img/arrowdown.png);
                    background-repeat: no-repeat;
                    background-position: right 15px center;
                    background-size: 16px;
                    @include font(15);
                    color: $black;
                    width: 100%;
                    border: 1px solid rgba($black, .2);
                    -webkit-appearance: none;
                    appearance: none;
                    @include radius(4px);
                }

                textarea {
                    padding: 10px 10px;
                    width: 100%;
                    min-height: 90px;
                    @include font(15);
                    color: $black;
                    line-height: 1.5;
                    border: 1px solid rgba($black, .2);
                    outline: none;
                    box-shadow: none;
                    @include radius(4px);

                    &::placeholder {
                        color: rgba($black, .3);
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .btn {
                    display: block;
                    padding: 10px 15px;
                    width: 100%;
                    @include font(16);
                    font-weight: 400;
                    @include radius(4px);
                    cursor: pointer;
                }

                p {
                    @include font(16);
                    font-weight: 400;
                    color: $black;
                    line-height: 30px;
                    text-align: center;

                    a {
                        display: inline-block;
                        vertical-align: middle;
                        text-decoration: none;
                        color: $baseColor;
                        @include transition();

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

.athlete-team-sec-outer {
    background: $white;
    padding-top: 45px;

    .sec-title {
        @include font(36);
        font-weight: 600;
        color: $black;
        text-align: center;
        margin-bottom: 30px;
    }
}

.sports-rol-sec {
    background: #F9F9F9;
    padding: 45px 0;
    text-align: center;

    .sec-title {
        @include font(30);
        font-weight: 600;
        color: $black;
        margin-bottom: 30px;
    }

    .button-wrap {
        @include flex();
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        .btn {
            background: $lightBlue;
            padding: 10px 15px;
            @include font(20);
            font-weight: 400;
            color: $white;
            min-width: 240px;
            margin: 8px 10px;
        }
    }
}

.testimonial-sec-athlete {
    background: $white;
    padding: 60px 0;
    text-align: center;

    &.bg-gray {
        background: #f9f9f9
    }

    .top-block {
        @include font(16);
        font-weight: 400;
        color: $black;
        max-width: 635px;
        margin: 0 auto 40px;
        text-align: center;

        .title {
            @include font(30);
            font-weight: 600;
            color: $black;
            line-height: 1.3;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 0;
            line-height: 26px;
        }
    }

    .slide-item {
        padding: 20px 25px;
        background: $white;
        border: 1px solid #FFFFFF;
        @include radius(5px);

        .testimonial-img {
            @include flex();
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            @include radius(50%);
            margin: 0 auto 10px;

            img {
                display: block;
                width: 100px;
                height: 100px;
                object-fit: cover;
                @include radius(50%);
            }
        }

        .testimonial-detail {
            @include font(14);
            font-weight: 300;
            color: $black;
            letter-spacing: 0.21px;
            line-height: 1.5;

            .short-text {
                position: relative;
                margin-bottom: 20px;
                min-height: 85px;

                &:after {
                    content: "";
                    background: url(../img/quote-iocn-left.png) no-repeat;
                    position: absolute;
                    width: 80px;
                    height: 65px;
                    left: 0;
                    top: -15px;
                    z-index: 1;
                }

                &:before {
                    content: "";
                    background: url(../img/quote-iocn-right.png) no-repeat;
                    background-size: 31px 21px;
                    position: absolute;
                    width: 31px;
                    height: 21px;
                    right: 0;
                    bottom: -10px;
                    z-index: 1;
                }

                p {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .name {
                @include font(15);
                font-weight: 500;
                letter-spacing: 0.23px;
                margin-bottom: 5px;
            }

            .designation {
                @include font(15);
                font-weight: 500;
                color: $baseColor;
                letter-spacing: 0.18px;
            }
        }
    }

    .testimonial-slider {
        .owl-dots {
            display: block;
            text-align: center;
            margin-top: 20px;

            button {
                display: inline-block;
                vertical-align: middle;
                border: none;
                outline: none;

                span {
                    display: block;
                    background: $white;
                    width: 12px;
                    height: 12px;
                    margin: 3px 5px;
                    border: 1px solid #707070;
                    border-radius: 50%;
                    opacity: 0.16;
                    transition: opacity .2s ease;
                }

                &.active {
                    span {
                        background: #D1D1D1;
                        opacity: 0.25;
                    }
                }
            }
        }
    }
}

.athlete-recruiting-sec {
    background: $white url(../img/athlete-recruiting-img.jpg) no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 60px 0 0;

    .flexbox {
        @include flex();
        justify-content: space-between;
        align-items: center;

        .imgbox {
            width: 380px;
            margin-right: 20px;

            img {
                display: block;
                max-width: 100%;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
            }
        }

        .content-box {
            width: calc(100% - 400px);
            @include font(16);
            font-weight: 400;
            color: $black;

            .title {
                @include font(25);
                font-weight: 600;
                color: $black;
                margin-bottom: 25px;
            }

            .number-type-row {
                @include flex();
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;

                .number-type-col {
                    width: calc(50% - 10px);
                }
            }

            .number-type-list {
                li {
                    @include flex();
                    align-items: center;
                    min-height: 45px;
                    padding-left: 60px;
                    position: relative;
                    line-height: 30px;
                    margin-bottom: 18px;

                    .number-count {
                        background: $lightBlue;
                        position: absolute;
                        @include font(20);
                        color: $white;
                        width: 40px;
                        height: 40px;
                        left: 0;
                        top: 5px;
                        line-height: 40px;
                        text-align: center;
                        @include radius(50%);
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.athlete-roadmap-sec {
    padding-top: 60px;
    background: $white;

    .sec-title {
        @include font(42);
        font-weight: 600;
        color: $black;
        margin-bottom: 30px;
        text-align: center;
    }

    .map-img {
        position: relative;

        img {
            display: block;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;

            &.mobile {
                display: none;
            }
        }

        .brand-listing {
            @include flex();
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            max-width: 300px;
            position: absolute;
            top: 15px;
            right: 45px;
            z-index: 1;

            .item {
                img {
                    display: block;
                    max-width: 100%;
                    padding: 2px;
                }
            }
        }
    }
}

.athlete-coachinfo-sec {
    background: #f9f9f9;
    padding: 60px 0;

    .top-block {
        @include font(16);
        font-weight: 400;
        color: $black;
        max-width: 960px;
        margin: 0 auto 20px;
        text-align: center;

        .title {
            @include font(36);
            font-weight: 600;
            color: $black;
            line-height: 1.4;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 0;
            line-height: 26px;
        }
    }

    .coachinfo-list {
        @include flex();
        flex-wrap: wrap;
        margin: 0 -8px;

        .item {
            width: 33.333%;
            padding: 0 8px;
            margin-top: 20px;
        }

        .coach-cartbox {
            background: $white;
            padding: 15px;
            height: 100%;
            @include font(16);
            font-weight: 400;
            color: #757575;
            box-shadow: 0px 5px 8px #8D8D8D33;
            @include radius(8px);

            .iconbox {
                background: $lightBlue;
                @include flex();
                justify-content: center;
                align-items: center;
                width: 80px;
                height: 70px;
                padding: 10px;
                margin-right: -15px;
                @include radius(50px 0 0 50px);
            }

            .title-info {
                @include flex();
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
            }

            .title {
                @include font(18);
                font-weight: 500;
                color: $black;
                line-height: 1.4;
                width: calc(100% - 100px);
            }

            p {
                line-height: 28px;
            }
        }
    }
}

.platform-partner-sec {
    background: #F9F9F9;
    padding: 60px 0;

    .sec-title {
        @include font(36);
        font-weight: 600;
        color: $black;
        line-height: 1.4;
        margin-bottom: 25px;
        text-align: center;
    }

    .partner-list {
        @include flex();
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -20px;

        .item {
            width: 14.285%;
            padding: 0 20px;
            margin-top: 15px;

            img {
                display: block;
                max-width: 100%;
            }
        }
    }
}

.program-gameinfo-sec {
    background: #232526;
    padding: 60px 0;

    .top-block {
        @include font(16);
        font-weight: 400;
        color: $white;
        margin: 0 auto 45px;
        text-align: center;

        .title {
            @include font(36);
            font-weight: 600;
            color: $white;
            line-height: 1.4;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 0;
            line-height: 26px;
        }
    }

    .program-gameinfo-img {
        text-align: center;

        img {
            display: block;
            max-width: 100%;

            &.mobile {
                display: none;
            }
        }
    }
}

.meetCoach-newsletter {
    background: #262626;
    padding: 30px 0 0;

    .flexBox {
        @include flex();
        justify-content: space-between;
        align-items: center;

        .infoflexBox {
            width: calc(100% - 420px);
            padding-right: 30px;
        }

        .newsletterImg {
            width: 420px;

            img {
                display: block;
                max-width: 100%;
            }
        }

        .title {
            @include font(36);
            font-weight: 600;
            color: $white;
            margin-bottom: 20px;
        }

        .meetCoach-form {
            @include flex();
            justify-content: space-between;
            align-items: center;
            max-width: 520px;

            .item {
                // width:calc(100% - 195px);
                margin-bottom: 30px;

                // &:last-child {
                //     width: 180px;
                //     // margin-left:15px;
                // }
                input {
                    padding: 10px 10px;
                    width: 100%;
                    @include font(16);
                    color: $black;
                    border: 1px solid $gray;
                    outline: none;
                    box-shadow: none;
                    height: 48px;
                    @include radius(4px);

                    &::placeholder {
                        color: rgba($black, .5);
                    }
                }

                .btn {
                    display: block;
                    width: 100%;
                    @include radius(4px);
                }
            }
        }
    }
}

@media screen and (max-width: 1439px) {
    .recruiting-guide-sec {
        .block-info-box {
            .tagline {
                @include font(26);
            }

            .title {
                @include font(48);
            }
        }

        .data-box {

            h2,
            h3,
            h4 {
                @include font(26);
            }
        }
    }

    .about-coaches-sec {
        .sec-title {
            @include font(32);
        }
    }

    .athlete-team-sec-outer {
        .sec-title {
            @include font(32);
        }
    }

    .sports-rol-sec {
        .sec-title {
            @include font(26);
        }

        .button-wrap {
            .btn {
                @include font(16);
                min-width: 180px;
            }
        }
    }

    .banner-lp-sec {
        .banner-flexbox {
            .banner-content {
                .title {
                    @include font(38);
                }
            }
        }

        .college-banner-content {
            .title {
                @include font(42);
            }
        }

        .commit-banner-content {
            .title {
                @include font(45);
            }

            .sub-title {
                @include font(60);
            }
        }
    }

    .collage-coache-sec {
        .top-block {
            .title {
                @include font(36);
            }
        }
    }

    .get-started-sec {
        .sec-title {
            @include font(36);
        }
    }

    .athlete-team-sec {
        background-size: 100% 100%;

        .content-box {
            min-height: 515px;

            .title {
                @include font(36);
            }
        }
    }

    .athlete-faq-sec {
        .sec-title {
            @include font(36);
        }
    }

    .video-feature-sec {
        .top-block {
            .title {
                @include font(36);
            }
        }
    }

    .testimonial-main-sec {
        padding: 60px 0 0;

        .content-box {
            .text-box {
                .title {
                    @include font(52);
                }
            }
        }
    }

    .common-testimonial-sec {
        .top-block {
            .title {
                @include font(36);
            }
        }
    }

    .carrer-main-sec {
        .top-block {
            .title {
                @include font(36);
                line-height: 50px;
            }
        }

        .flexbox-wrap {
            .banner-content {
                .short-text {
                    @include font(18);
                }
            }
        }
    }

    .interview-survey-sec {
        .top-block {
            .sec-title {
                @include font(36);
            }
        }
    }

    .lp-map-sec {
        .sec-title {
            @include font(36);
        }
    }

    .athlete-roadmap-sec {
        .sec-title {
            @include font(36);
        }
    }

    .athlete-coachinfo-sec {
        .top-block {
            .title {
                @include font(36);
            }
        }
    }

    .program-gameinfo-sec {
        .top-block {
            .title {
                @include font(36);
            }
        }
    }

    .meetCoach-newsletter {
        .flexBox {
            .title {
                @include font(32);
            }
        }
    }
}

@media screen and (max-width: 1365px) {
    .recruiting-guide-sec {
        .block-info-box {
            padding: 180px 50px 80px;

            .tagline {
                @include font(24);
            }

            .title {
                @include font(45);
            }
        }

        .data-box {

            h2,
            h3,
            h4 {
                @include font(24);
            }
        }
    }
}

@media screen and (max-width: 1279px) {
    .ads-recruiting-sec {
        .block-info-box {
            padding-left: 40px;
            padding-right: 40px;

            .sec-title {
                @include font(32);
            }

            .service-item {
                .item {
                    .title {
                        @include font(36);
                    }
                }
            }
        }

        .form-block-box {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}

@media screen and (max-width: 1199px) {
    .recruiting-guide-sec {
        .block-info-box {
            padding: 180px 50px 80px;

            .brand-logo {
                margin-bottom: 35px
            }

            .tagline {
                @include font(22);
            }

            .title {
                @include font(36);
            }

            p {
                @include font(15);
                line-height: 30px;
            }
        }

        .data-box {
            padding: 45px 35px;

            h2,
            h3,
            h4 {
                @include font(22);
                margin-bottom: 15px;
            }

            p {
                @include font(15);
                line-height: 30px;
            }
        }
    }

    .ads-recruiting-sec {
        .block-info-box {
            .sec-title {
                @include font(30);
            }

            .service-item {
                .item {
                    .title {
                        @include font(32);
                    }
                }
            }
        }
    }

    .banner-lp-sec {
        .banner-flexbox {
            .banner-content {
                .title {
                    @include font(32);
                }

                .short-text {
                    @include font(17);
                }
            }
        }

        .college-banner-content {
            .tagline {
                @include font(20);
            }

            .title {
                @include font(32);
            }
        }

        .commit-banner-content {
            .title {
                @include font(36);
            }

            .sub-title {
                @include font(48);
            }
        }
    }

    .collage-coache-sec {
        .top-block {
            .title {
                @include font(30);
            }
        }

        .collage-coach-list {
            .item {
                width: 33.333%;
            }
        }
    }

    .athlete-common-sec {
        .top-block {
            .title {
                @include font(30);
            }
        }

        .common-listing {
            .item-row {
                .content-box {
                    .top-block {
                        .title {
                            @include font(24);
                        }
                    }
                }
            }
        }
    }

    .get-started-sec {
        .sec-title {
            @include font(30);
        }
    }

    .athlete-team-sec {
        .content-box {
            min-height: 480px;

            .title {
                @include font(30);
            }
        }
    }

    .athlete-faq-sec {
        .sec-title {
            @include font(30);
        }
    }

    .about-mission-sec {
        .sec-title {
            @include font(32);

            &.font36 {
                @include font(32);
            }
        }
    }

    .lp-team-sec {
        padding: 50px 0;

        .top-block {
            .title {
                @include font(32);
            }
        }
    }

    .success-stories-sec {
        .sec-title {
            @include font(32);
        }
    }

    .lp-map-sec {
        .sec-title {
            @include font(32);
        }
    }

    .video-feature-sec {
        padding: 45px 0;

        .top-block {
            .title {
                @include font(32);
            }
        }

        .feature-list {
            .item {
                h3 {
                    @include font(18);
                }
            }
        }
    }

    .hightlight-video-sec {
        .top-block {
            .title {
                @include font(24);
            }
        }

        .videos-listing {
            .video-box {
                width: 33.333%;
            }
        }
    }

    .testimonial-main-sec {
        .content-box {
            .text-box {
                .title {
                    @include font(42);
                }
            }
        }
    }

    .common-testimonial-sec {
        .top-block {
            .title {
                @include font(32);
            }
        }
    }

    .testimonial-sec-athlete {
        .slide-item {
            .testimonial-detail {
                .short-text {
                    min-height: 110px;
                }
            }
        }
    }

    .carrer-main-sec {
        .top-block {
            .title {
                @include font(32);
                line-height: 42px;
            }
        }

        .flexbox-wrap {
            .banner-content {
                .short-text {
                    p {
                        @include font(16);
                    }

                    ul {
                        @include font(16);

                        li {
                            padding-left: 35px;

                            &::after {
                                background-size: 18px 18px;
                                width: 18px;
                                height: 18px;
                                top: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    .carrer-preferred-sec {
        .top-block {
            .sub-title {
                @include font(32);
            }
        }
    }

    .carrer-advisors-sec {
        .top-block {
            .sub-title {
                @include font(24);
                line-height: 36px;
            }
        }
    }

    .carrer-compensation-sec {
        .title {
            @include font(32);
        }
    }

    .carrer-social-sec {
        .title {
            @include font(32);
        }
    }

    .interview-survey-sec {
        .top-block {
            .sec-title {
                @include font(32);
            }
        }
    }

    .about-coaches-sec {
        .coaches-list {
            .item {
                h4 {
                    @include font(15);
                }

                .short-text {
                    line-height: 30px;
                }
            }
        }
    }

    .athlete-recruiting-sec {
        .flexbox {
            .content-box {
                .number-type-list {
                    li {
                        padding-left: 55px;
                        line-height: 25px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    .athlete-roadmap-sec {
        .sec-title {
            @include font(32);
        }

        .map-img {
            .brand-listing {
                right: 15px;
            }
        }
    }

    .athlete-coachinfo-sec {
        .top-block {
            .title {
                @include font(32);
            }
        }
    }

    .platform-partner-sec {
        .sec-title {
            @include font(32);
        }
    }

    .program-gameinfo-sec {
        .top-block {
            .title {
                @include font(32);
            }
        }
    }

    .meetCoach-newsletter {
        .flexBox {
            .title {
                @include font(30);
            }

            .infoflexBox {
                width: calc(100% - 300px);
                padding-right: 15px;
            }

            .newsletterImg {
                width: 300px;
            }

            .meetCoach-form {
                max-width: 600px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .recruiting-guide-sec {
        display: block;

        .block-info-box {
            padding: 45px 30px 30px;
            background-position: center center;
            width: 100%;

            .tagline {
                @include font(22);
            }

            .title {
                @include font(36);
            }

            p {
                @include font(15);
                line-height: 30px;
            }
        }

        .data-box {
            padding: 45px 30px;
            width: 100%;

            h2,
            h3,
            h4 {
                @include font(22);
                margin-bottom: 15px;
            }

            p {
                @include font(15);
                line-height: 30px;
            }
        }
    }

    .ads-recruiting-sec {
        display: block;

        .block-info-box {
            width: 100%;
            padding: 45px 40px;
        }

        .form-block-box {
            width: 100%;
            padding: 45px 40px;
        }
    }

    .banner-lp-sec {
        padding: 50px 0;

        .banner-flexbox {
            align-items: flex-start;

            .banner-content {
                width: 50%;
                margin-right: 5%;

                .title {
                    @include font(26);
                    margin-bottom: 15px;
                }

                .short-text {
                    @include font(16);

                    p {
                        line-height: 32px;
                    }
                }

                .button-wrap {
                    .btn {
                        min-width: 180px;
                        @include font(16);
                    }
                }
            }
        }

        .college-banner-content {
            .title {
                @include font(26);
            }
        }

        .commit-banner-content {
            .title {
                @include font(30);
            }

            .sub-title {
                @include font(36);
            }
        }
    }

    .collage-coache-sec {
        padding: 45px 0 30px;

        .top-block {
            .title {
                @include font(26);
            }

            p {
                line-height: 30px;
            }
        }

        .collage-coach-list {
            .item {
                width: 50%;
            }
        }
    }

    .get-started-sec {
        padding: 45px 0 50px;

        .sec-title {
            @include font(26);
            margin-bottom: 15px;
        }

        .short-text {
            margin-bottom: 25px;
        }

        .get-started-flexbox {
            min-height: 150px;
        }
    }

    .athlete-common-sec {
        padding: 50px 0;

        .top-block {
            @include font(16);
            margin-bottom: 30px;

            .title {
                @include font(26);
            }

            p {
                line-height: 30px;
            }
        }

        .common-listing {
            .item-row {
                .content-box {
                    .top-block {
                        margin-bottom: 15px;

                        .title {
                            @include font(20);
                        }
                    }

                    .detail-text {
                        line-height: 28px;

                        .button-wrap {
                            .btn {
                                min-width: 180px;
                                @include font(16);
                            }
                        }
                    }
                }
            }
        }

        &.athlete-home-ls {
            .common-listing {
                .common-listing-item {
                    padding: 50px 0;
                }
            }
        }
    }

    .athlete-team-sec {
        .content-box {
            .title {
                @include font(26);
            }

            .short-text {
                @include font(16);

                p {
                    line-height: 30px;
                }
            }

            .button-wrap {
                button {
                    @include font(16);
                }
            }
        }
    }

    .athlete-faq-sec {
        padding: 45px 0;

        .sec-title {
            @include font(26);
        }
    }

    .about-mission-sec {
        padding: 45px 0;

        .sec-title {
            @include font(26);

            &.font36 {
                @include font(26);
            }
        }

        .short-text {
            @include font(16);
            line-height: 1.7;
        }
    }

    .lp-team-sec {
        padding: 45px 0;

        .top-block {
            @include font(16);

            .title {
                @include font(26);
            }

            p {
                line-height: 30px;

                br {
                    display: none;
                }
            }
        }

        .team-list {
            .item {
                width: 33.333%;
            }
        }
    }

    .success-stories-sec {
        padding: 45px 0;

        .sec-title {
            @include font(26);
        }

        .success-stories-list {
            .item {
                .detail-info-box {
                    .short-text {
                        @include font(16);
                    }
                }
            }
        }
    }

    .lp-map-sec {
        .sec-title {
            @include font(26);
        }
    }

    .video-feature-sec {
        .top-block {
            .title {
                @include font(26);
            }
        }
    }

    .hightlight-video-sec {
        padding: 40px 0;

        .top-block {
            .title {
                @include font(22);
            }
        }
    }

    .staff-bio-sec {
        padding: 45px 0;

        .flexbox {
            .info-box {
                width: 215px;

                .pic-box {
                    width: 200px;
                    margin: 0 auto 20px;

                    img {
                        width: 200px;
                        height: 200px;
                    }
                }

                .name {
                    @include font(20);
                }

                .position {
                    @include font(18);
                }
            }

            .detail-info {
                width: calc(100% - 240px);
                @include font(16);

                h4 {
                    @include font(20);
                }
            }
        }
    }

    .common-staff-sec {
        padding: 45px 0;

        .staff-content-box {
            display: block;

            .title {
                width: 100%;
                margin-right: 0;
                margin-bottom: 30px;
                text-align: center;

                &::after {
                    left: 0;
                    right: 0;
                    bottom: -6px;
                    margin: 0 auto;
                }
            }

            .short-text {
                width: 100%;
                @include font(16);
            }
        }
    }

    .get-started-sec {
        .get-started-bio-content {
            margin-left: 0;
            text-align: center;
        }
    }

    .testimonial-main-sec {
        padding: 50px 0;
        background-size: cover;

        .content-box {
            .text-box {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 30px;

                .title {
                    @include font(32);
                }
            }
        }
    }

    .common-testimonial-sec {
        padding: 45px 0;

        .top-block {
            @include font(16);

            .title {
                @include font(26);
            }

            p {
                line-height: 30px;
            }
        }

        .videos-listing {
            .video-box {
                width: 33.333%;

                .video-detail-box {
                    @include font(16);
                }
            }
        }
    }

    .carrer-main-sec {
        padding: 45px 0 45px;

        .top-block {
            .title {
                @include font(26);
                line-height: 36px;
            }
        }

        .flexbox-wrap {
            .banner-content {
                .short-text {
                    line-height: 26px;

                    p {
                        margin-bottom: 15px;
                    }

                    ul {
                        li {
                            &::after {
                                top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .carrer-preferred-sec {
        padding: 45px 0;

        .top-block {
            .sub-title {
                @include font(26);
                line-height: 36px;
            }
        }

        .preferred-list {
            .item {
                width: 50%;
                text-align: center;
            }
        }
    }

    .carrer-advisors-sec {
        padding: 45px 0;

        .top-block {
            .sub-title {
                @include font(22);
                line-height: 32px;
            }
        }

        .timeline-items {
            border-top: none;
            flex-wrap: wrap;

            .item {
                padding-top: 0;
                width: 50%;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }

                &::after,
                &::before {
                    display: none;
                }

                .timeline-data {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .carrer-compensation-sec {
        padding: 45px 0;

        .title {
            @include font(26);
        }
    }

    .carrer-social-sec {
        padding: 45px 0;

        .title {
            @include font(26);
        }
    }

    .interview-survey-sec {
        padding: 45px 0;

        .top-block {
            .brand-logo {
                max-width: 160px;
                margin-left: auto;
                margin-right: auto;
            }

            .sec-title {
                @include font(26);
            }
        }
    }

    .about-coaches-sec {
        padding: 50px 0;

        .sec-title {
            @include font(26);
            margin-bottom: 35px;
        }

        .coaches-list {
            .item {
                .short-text {
                    min-height: 180px;
                }
            }
        }
    }

    .athlete-team-sec-outer {
        .sec-title {
            @include font(26);
        }
    }

    .sports-rol-sec {
        .sec-title {
            margin-bottom: 20px;
        }

        .button-wrap {
            .btn {
                @include font(15);
                min-width: 160px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }

    .average-helped-sec {
        padding: 45px 0;

        .flexbox {
            display: block;
            text-align: center;

            .title-box {
                width: 100%;
                margin-right: 0;
                margin-bottom: 15px;

                h4 {
                    @include font(26);
                    line-height: 1.333;
                }
            }

            .short-text {
                width: 100%;
                line-height: 30px;
            }
        }
    }

    .testimonial-sec-athlete {
        padding: 45px 0;

        .top-block {
            .title {
                @include font(26);
            }
        }

        .slide-item {
            .testimonial-detail {
                .short-text {
                    min-height: 100px;
                }
            }
        }
    }

    .athlete-team-sec {
        .content-box {
            min-height: 430px;
        }
    }

    .athlete-recruiting-sec {
        padding: 45px 0 30px;

        .title {
            text-align: center;
        }

        .flexbox {
            display: block;

            .imgbox {
                width: 100%;
                margin-right: 0;
                text-align: center;
                margin-bottom: 30px;

                img {
                    display: block;
                    margin: 0 auto;
                }
            }

            .content-box {
                width: 100%;
            }
        }
    }

    .athlete-roadmap-sec {
        padding-top: 45px;

        .sec-title {
            @include font(26);
        }

        .map-img {
            .brand-listing {
                max-width: 200px;

                .item {
                    width: 50%;

                    img {
                        object-fit: contain;
                        height: 50px;
                    }
                }
            }
        }
    }

    .athlete-coachinfo-sec {
        padding: 45px 0;

        .top-block {
            .title {
                @include font(26);
            }
        }

        .coachinfo-list {
            .item {
                width: 50%;
            }
        }
    }

    .platform-partner-sec {
        padding: 45px 0;

        .sec-title {
            @include font(26);
        }
    }

    .program-gameinfo-sec {
        padding: 45px 0;

        .top-block {
            .title {
                @include font(26);
            }
        }
    }

    .meetCoach-newsletter {
        .flexBox {
            .title {
                @include font(26);
            }

            .infoflexBox {
                width: calc(100% - 220px);
                padding-right: 15px;
            }

            .newsletterImg {
                width: 220px;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .recruiting-guide-sec {
        .block-info-box {
            padding: 35px 15px 20px;

            .brand-logo {
                max-width: 280px;
                margin-bottom: 20px;
            }

            .tagline {
                @include font(18);
            }

            .title {
                @include font(22);
                margin-bottom: 20px;
            }

            p {
                line-height: 26px;
            }
        }

        .data-box {
            padding: 35px 15px;

            h2,
            h3,
            h4 {
                @include font(20);
            }

            p {
                line-height: 26px;
            }

            .button-wrap {
                .btn {
                    @include font(16);
                }
            }
        }
    }

    .banner-lp-sec {
        .banner-flexbox {
            display: block;

            .banner-content {
                width: 100%;
                margin-right: 0;
                margin-bottom: 35px;
                text-align: center;

                .title {
                    br {
                        display: none;
                    }
                }

                .short-text {
                    margin-bottom: 20px;
                }
            }

            .video-box-wrap {
                width: 100%;
            }
        }

        .commit-banner-content {
            .title {
                @include font(26);
            }

            .sub-title {
                @include font(28);
            }
        }
    }

    .collage-coache-sec {
        .top-block {
            .title {
                br {
                    display: none;
                }
            }
        }

        .collage-coach-list {
            display: block;
            margin-left: 0;
            margin-right: 0;

            .item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 45px;

                &:last-child {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .get-started-sec {
        padding: 40px 0 45px;
    }

    .athlete-common-sec {
        padding: 40px 0 45px;

        .common-listing {
            .item-row {
                display: block;
                margin-bottom: 30px;

                .content-box {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 20px;
                }

                .img-box {
                    width: 100%;
                }
            }
        }

        &.athlete-home-ls {
            .common-listing {
                .common-listing-item {
                    padding: 40px 0;
                }
            }
        }
    }

    .athlete-team-sec {
        .content-box {
            padding: 45px 30px;
            min-height: 1px;
        }
    }

    .about-mission-sec {
        .flexbox {
            display: block;

            .content-box {
                width: 100%;
                margin-right: 0;
                margin-bottom: 35px;
            }

            .video-box-wrap {
                width: 100%;
            }
        }
    }

    .lp-team-sec {
        .team-list {
            .item {
                width: 50%;
            }
        }
    }

    .success-stories-sec {
        .sec-title {
            margin-bottom: 35px;
        }

        .success-stories-list {
            .item {
                display: block;

                .pick-box {
                    margin: 0 auto 30px;
                }

                .detail-info-box {
                    width: 100%;
                }
            }
        }
    }

    .video-feature-sec {
        .top-block {
            margin-bottom: 0;
        }

        .feature-list {
            justify-content: space-between;

            .item {
                padding: 20px 15px !important;
                width: calc(50% - 10px);
                border: 1px solid rgba($gray, .4) !important;
                margin-top: 30px;
                min-height: 250px;

                p {
                    line-height: 26px;
                }
            }
        }
    }

    .hightlight-video-sec {
        .top-block {
            .title {
                @include font(20);
            }
        }

        .videos-listing {
            .video-box {
                width: 50%;

                .video-detail-box {
                    .title-info {
                        h4 {
                            @include font(16);
                        }
                    }
                }
            }
        }
    }

    .staff-bio-sec {
        .flexbox {
            display: block;

            .info-box {
                margin: 0 auto 30px;
            }

            .detail-info {
                width: 100%;
                text-align: center;

                .brands-list {
                    justify-content: center;
                }
            }
        }
    }

    .common-testimonial-sec {
        .videos-listing {
            .video-box {
                width: 50%;
            }
        }
    }

    .carrer-main-sec {
        .flexbox-wrap {
            display: block;

            .banner-content {
                width: 100%;
                margin-right: 0;
                margin-bottom: 35px;
            }

            .video-box-wrap {
                width: 100%;
            }
        }
    }

    .about-coaches-sec {
        padding: 40px 0;

        .coaches-list {
            .item {
                .short-text {
                    min-height: 1px;
                }
            }
        }
    }

    .athlete-team-sec-outer {
        padding-top: 40px;
    }

    .sports-rol-sec {
        padding: 40px 0;
    }

    .testimonial-sec-athlete {
        padding: 40px 0;

        .top-block {
            margin-bottom: 30px;
        }

        .slide-item {
            .testimonial-detail {
                .short-text {
                    min-height: 1px;
                }
            }
        }
    }

    .average-helped-sec {
        padding: 40px 0;
    }

    .athlete-recruiting-sec {
        padding: 40px 0 25px;
    }

    .platform-partner-sec {
        padding: 40px 0;

        .partner-list {
            margin: 0 -5px;

            .item {
                padding: 0 10px;
            }
        }
    }

    .program-gameinfo-sec {
        padding: 40px 0;
    }

    .meetCoach-newsletter {
        .flexBox {
            align-items: flex-end;

            .title {
                @include font(20);
            }

            .infoflexBox {
                width: calc(100% - 160px);
                // padding-right: 0;
            }

            .newsletterImg {
                width: 160px;
                // display: none;
            }

            .meetCoach-form {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width:639px) {
    .ads-recruiting-sec {
        .block-info-box {
            padding: 35px 15px;

            .brand-logo {
                max-width: 280px;
                margin-bottom: 15px;
            }

            .sec-title {
                @include font(22);
            }

            .service-item {
                &::before {
                    background-size: 30px;
                    width: 50px;
                    height: 50px;
                    left: -72px;
                }

                .item {
                    .title {
                        @include font(22);

                        &::after {
                            top: 15px;
                        }

                        br {
                            display: none;
                        }
                    }

                    .short-text {
                        @include font(15);
                        margin-bottom: 20px;
                    }

                    .profile-bio-box {
                        .info-box {
                            h4 {
                                @include font(16);
                            }
                        }
                    }
                }
            }
        }

        .form-block-box {
            padding: 35px 15px;

            .title {
                @include font(20);
            }

            .form-box {
                padding-bottom: 30px;

                .field-item {
                    .btn {
                        @include font(16);
                    }
                }
            }
        }
    }

    .banner-lp-sec {
        padding: 35px 0 40px;

        .banner-flexbox {
            .banner-content {
                .brand-logo {
                    max-width: 280px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .title {
                    @include font(22);
                    line-height: 32px;
                }

                .short-text {
                    @include font(16);

                    p {
                        line-height: 30px;
                    }
                }

                .button-wrap {
                    .btn {
                        @include font(16);
                    }
                }
            }

            .video-box-wrap {
                .video-img {
                    .video-play-button {
                        .video-play-inner {
                            .play-btn {
                                width: 50px;
                                height: 50px;

                                svg {
                                    width: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.banner-hightlight {
            .banner-flexbox {
                .banner-content {
                    .short-text {
                        @include font(16);

                        p {
                            line-height: 30px;
                        }
                    }
                }
            }
        }

        .college-banner-content {
            .tagline {
                @include font(18);
            }

            .title {
                @include font(22);
            }

            .short-text {
                @include font(16);

                p {
                    line-height: 30px;
                }
            }

            .button-wrap {
                .btn {
                    @include font(16);
                }
            }
        }

        .commit-banner-content {
            .title {
                @include font(22);
                line-height: 1.4;
            }

            .sub-title {
                @include font(24);
            }

            .short-text {
                @include font(16);
            }

            .button-wrap {
                .btn {
                    @include font(16);
                }
            }
        }
    }

    .collage-coache-sec {
        padding: 35px 0 20px;

        .top-block {
            @include font(16);

            .title {
                @include font(20);
                margin-bottom: 12px;
            }

            p {
                br {
                    display: none;
                }
            }
        }
    }

    .get-started-sec {
        padding: 35px 0 40px;

        .get-started-flexbox {
            min-height: 100px;
        }

        .sec-title {
            @include font(22);
            line-height: 1.333;
            margin-bottom: 10px;

            +.button-wrap {
                margin-top: 25px;
            }
        }

        .button-wrap {
            .btn {
                @include font(16);
            }
        }
    }

    .athlete-common-sec {
        .top-block {
            .title {
                @include font(22);
            }
        }

        .common-listing {
            .item-row {
                .content-box {
                    .top-block {
                        .title {
                            @include font(18);
                        }
                    }

                    .detail-text {
                        @include font(15);
                        line-height: 25px;

                        .success-info-box {
                            .detail-info-box {
                                @include font(14);

                                .name {
                                    @include font(15);
                                }

                                p {
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .athlete-team-sec {
        background-size: cover;

        .content-box {
            .title {
                @include font(22);
                margin-bottom: 15px;
            }
        }
    }

    .athlete-faq-sec {
        padding: 35px 0;

        .sec-title {
            @include font(22);
        }

        .faq-listing {
            li {
                .faq-title {
                    @include font(17);
                }
            }
        }
    }

    .about-mission-sec {
        padding: 35px 0;

        .sec-title {
            @include font(22);

            &.font36 {
                @include font(22);
            }
        }

        .postion-info {
            @include font(16);
        }

        .flexbox {
            .video-box-wrap {
                .video-img {
                    .video-play-button {
                        .video-play-inner {
                            .play-btn {
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }

    .lp-team-sec {
        padding: 35px 0;

        .top-block {
            .title {
                @include font(22);
            }
        }
    }

    .success-stories-sec {
        padding: 35px 0;

        .sec-title {
            @include font(22);
            margin-bottom: 30px;
        }

        .success-stories-list {
            .item {
                .pick-box {
                    margin-bottom: 20px;

                    .icon-box {
                        width: 60px;
                    }
                }

                .detail-info-box {
                    padding-left: 0;

                    .short-text {
                        &::after {
                            left: 0;
                        }
                    }

                    .info-box {
                        &::after {
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .lp-map-sec {
        padding-top: 15px;

        .sec-title {
            @include font(22);
            line-height: 1.33;
            margin-bottom: 30px;
        }
    }

    .brand-lp-sec {
        padding: 30px 0;

        .brand-listing {
            .item {
                padding: 0 10px;
            }
        }
    }

    .video-feature-sec {
        padding: 35px 0;

        .top-block {
            .title {
                @include font(22);
            }
        }
    }

    .staff-bio-sec {
        padding: 35px 0;
    }

    .common-staff-sec {
        padding: 35px 0;

        .staff-content-box {
            .title {
                @include font(22);
                line-height: 30px;
            }

            .short-text {
                line-height: 26px;

                ul {
                    display: block;

                    li {
                        width: 100%;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .testimonial-main-sec {
        padding: 40px 0 45px;

        .content-box {
            .text-box {
                &::after {
                    width: 100px;
                    height: 90px;
                }

                .title {
                    @include font(22);
                }

                .short-text {
                    @include font(16);
                }
            }
        }
    }

    .common-testimonial-sec {
        padding: 35px 0;

        .top-block {
            margin-bottom: 30px;

            .title {
                @include font(22);
            }
        }

        .videos-listing {
            display: block;
            margin-left: 0;
            margin-right: 0;

            .video-box {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                .video-play-button {
                    .video-play-inner {
                        button {
                            svg {
                                width: 42px;
                                height: 42px;
                            }
                        }
                    }
                }
            }
        }
    }

    .carrer-main-sec {
        padding: 35px 0 40px;

        .top-block {
            margin-bottom: 25px;

            .title {
                @include font(22);
                line-height: 30px;
                margin-bottom: 12px;
            }

            .sub-title {
                @include font(15);
                line-height: 26px;
            }
        }
    }

    .carrer-preferred-sec {
        padding: 35px 0;

        .top-block {
            margin-bottom: 0;

            .sub-title {
                @include font(22);
                line-height: 32px;
            }
        }

        .preferred-list {
            .item {
                .detail-box {
                    h4 {
                        @include font(16);
                    }
                }
            }
        }
    }

    .carrer-advisors-sec {
        padding: 35px 0;

        .top-block {
            .sub-title {
                @include font(18);
                line-height: 26px;
            }
        }

        .timeline-items {
            .item {
                .timeline-data {
                    .title {
                        @include font(18);
                    }
                }
            }
        }
    }

    .carrer-compensation-sec {
        padding: 35px 0;

        .title {
            @include font(22);
        }
    }

    .carrer-social-sec {
        padding: 35px 0;

        .title {
            @include font(22);
        }

        .short-text {
            .social-links {
                li {
                    margin-left: 5px;
                    margin-right: 5px;

                    a {
                        svg {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }
        }
    }

    .interview-survey-sec {
        padding: 35px 0;

        .top-block {
            margin-bottom: 20px;

            .brand-logo {
                max-width: 140px;
                margin-left: auto;
                margin-right: auto;
            }

            .sec-title {
                @include font(22);
            }
        }

        .survey-form-box {
            padding: 20px 15px;

            form {
                >div {
                    margin-bottom: 15px;
                }

                .fieldBox {
                    &.flexBox {
                        display: block;

                        .half {
                            width: 100%;
                            margin-bottom: 15px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .video-modal {
        .modal-body {

            iframe,
            video {
                object-fit: cover;
                height: 160px !important;
            }
        }
    }

    .about-coaches-sec {
        padding: 35px 0;

        .sec-title {
            @include font(22);
            margin-bottom: 30px;
        }

        .coaches-list {
            .item {
                .short-text {
                    min-height: 1px;
                }
            }
        }
    }

    .athlete-team-sec-outer {
        padding-top: 35px;

        .sec-title {
            @include font(22);
        }
    }

    .sports-rol-sec {
        padding: 35px 0;

        .sec-title {
            @include font(22);
            margin-bottom: 15px;
        }

        .button-wrap {
            .btn {
                @include font(14);
                min-width: 156px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }

    .testimonial-sec-athlete {
        padding: 35px 0;

        .top-block {
            .title {
                @include font(22);
            }
        }
    }

    .average-helped-sec {
        padding: 35px 0;

        .flexbox {
            .title-box {
                h4 {
                    @include font(22);
                }
            }
        }
    }

    .athlete-recruiting-sec {
        padding: 35px 0 20px;

        .flexbox {
            .imgbox {
                img {
                    max-width: 260px;
                }
            }

            .content-box {
                .title {
                    @include font(22);
                }
            }
        }
    }

    .athlete-roadmap-sec {
        padding-top: 35px;

        .sec-title {
            @include font(22);
        }

        .map-img {
            .brand-listing {
                max-width: 150px;
            }
        }
    }

    .athlete-coachinfo-sec {
        padding: 35px 0;

        .top-block {
            .title {
                @include font(22);
            }
        }
    }

    .platform-partner-sec {
        padding: 35px 0;

        .sec-title {
            @include font(22);
            margin-bottom: 20;
        }
    }

    .program-gameinfo-sec {
        padding: 35px 0;

        .top-block {
            margin-bottom: 30px;

            .title {
                @include font(22);
            }
        }
    }
}

@media screen and (max-width:567px) {
    .video-feature-sec {
        .feature-list {
            display: block;

            .item {
                width: 100%;
                min-height: 1px;
                margin-top: 25px;
            }
        }
    }

    .hightlight-video-sec {
        .top-block {
            display: block;

            .title {
                margin-bottom: 20px;
            }

            .filter-box {
                width: 100%;
                margin-left: 0;

                label {
                    @include font(16);
                }
            }
        }

        .videos-listing {
            display: block;

            .video-box {
                width: 100%;

                .video-play-button {
                    .video-play-inner {
                        button {
                            svg {
                                width: 42px;
                                height: 42px;
                            }
                        }
                    }
                }
            }
        }
    }

    .carrer-advisors-sec {
        .top-block {
            margin-bottom: 25px;
        }

        .timeline-items {
            display: block;
            margin-left: 0;
            margin-right: 0;

            .item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .carrer-preferred-sec {
        .preferred-list {
            display: block;
            margin-left: 0;
            margin-right: 0;

            .item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-top: 30px;

                .icon-box {
                    min-height: 1px;
                }
            }
        }
    }

    .athlete-recruiting-sec {
        .flexbox {
            .imgbox img {
                max-width: 180px;
            }

            .content-box {
                .number-type-row {
                    display: block;

                    .number-type-col {
                        width: 100%;
                    }
                }
            }
        }
    }

    .athlete-roadmap-sec {
        padding-bottom: 35px;

        .sec-title {
            margin-bottom: 25px;
        }

        .map-img {
            img {
                display: none;

                &.mobile {
                    display: block;
                    width: 100%;
                }
            }

            .brand-listing {
                display: none;
            }
        }
    }

    .athlete-coachinfo-sec {
        .coachinfo-list {
            display: block;

            .item {
                width: 100%;
            }
        }
    }

    .platform-partner-sec {
        .partner-list {
            .item {
                width: 20%;
            }
        }
    }

    .program-gameinfo-sec {
        background: #1e2021;

        .program-gameinfo-img {
            img {
                display: none;

                &.mobile {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    .meetCoach-newsletter {
        .flexBox {
            .meetCoach-form {
                .item {

                    // width: calc(100% - 160px); 
                    // &:last-child {
                    //     width: 150px;
                    //     margin-left: 10px;
                    // }
                    .btn {
                        padding-left: 15px;
                        padding-right: 15px;
                        @include font(15);
                        min-height: 48px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:479px) {
    .lp-team-sec {
        .team-list {
            display: block;
            margin: 0;

            .item {
                width: 100%;
                padding: 0;
                margin: 80px 0 35px;

                .team-infobox {
                    .pic-box {
                        width: 120px;
                        margin-top: -75px;

                        img {
                            width: 120px;
                            height: 120px;
                        }

                        .social-icon {
                            bottom: -6px;
                        }
                    }
                }
            }
        }
    }

    .meetCoach-newsletter {
        padding-bottom: 30px;

        .flexBox {
            .title {
                @include font(20);
            }

            .infoflexBox {
                width: calc(100% - 140px);
            }

            .newsletterImg {
                width: 140px;
            }

            .meetCoach-form {
                display: block;

                .item {
                    margin-bottom: 15px;
                    width: 100%;

                    &:last-child {
                        width: 100%;
                        margin-left: 0;
                        margin-bottom: 0;
                    }

                    .btn {
                        padding-left: 10px;
                        padding-right: 10px;
                        @include font(15);
                        min-height: 48px;
                    }
                }
            }
        }
    }
}